import React, { useEffect, useRef } from 'react';
import AppLayout from '../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';
import {
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  message,
  Tooltip,
  Spin,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import FullCalendar, { EventApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import useFetchList from '../../hooks/useFetchList';
import {
  FetchAllScheduleResponse,
  ScheduleDeliveryProperties,
  ScheduleProps,
} from '../../types/schedule.type';
import { LocationProps } from '../../types/location.type';
import { initialMenu, MenuProps } from '../../types/menu.type';
import { getErrorMessage } from '../../helpers/errorHandler';
import { theme } from '../../assets/theme';
import { COLORS_B } from '../../helpers/colors';
import AppButton from '../../components/AppButton';
import { generateQueryString } from '../../helpers/generateQueryString';
import {
  BaseResponsePaginationProps,
  BaseResponseProps,
} from '../../types/config.type';
import _ from 'lodash';
import { RangePickerProps } from 'antd/lib/date-picker';

// const BG_COLORS = ['#FFC555', '#56C288', '#FF819A', '#2666CF', '#DA1212'];

export interface FetchAllScheduleDeliveryResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: ScheduleDeliveryProperties[];
  };
}

export interface FetchAllSchedulePickupResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: ScheduleProps[];
  };
}

export interface ScheduleGroupLocationInterface {
  isDelivery: boolean;
  locationId: string;
  location?: LocationProps;
  title?: string;
  menus: MenuProps[];
  scheduleAt: string;
  start?: string;
  end?: string;
}

const Schedule = () => {
  const history = useHistory();
  const currentDate = moment().startOf('day');
  const fullCalendar: any = useRef();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [copyDate, setCopyDate] = React.useState<string>();
  const [choosenDate, setClickedDate] = React.useState<any>({});
  const [shownDates, setShownDates] = React.useState<Array<string>>(
    Array.from([0, 1, 2, 3, 4, 5, 6], (i) => {
      let addDay = new Date().getHours() > 12 ? 2 : 1; //;
      const date = moment(currentDate)
        .add(i + addDay, 'days')
        .format('YYYY-MM-DD');
      return date;
    })
  );
  const [isLoadingSchedule, setLoadingSchedule] =
    React.useState<boolean>(false);
  const [dataScheduleDelivery, setSchedulesDelivery] = React.useState<
    ScheduleDeliveryProperties[]
  >([]);
  const [data, setData] = React.useState<ScheduleProps[]>([]);
  const [startAtCalendar, setStartAtCalendar] = React.useState<string>();
  const [endAtCalendar, setEndAtCalendar] = React.useState<string>();

  // for copy schedule
  const [dataCopySchedule, setDataCopySchedule] = React.useState<
    ScheduleProps[]
  >([]);
  const [dataCopyScheduleDelivery, setCopySchedulesDelivery] = React.useState<
    ScheduleDeliveryProperties[]
  >([]);
  const [startAtCalendarCopySchedule, setStartAtCalendarCopySchedule] =
    React.useState<string>();
  const [endAtCalendarCopySchedule, setEndAtCalendarCopySchedule] =
    React.useState<string>();
  // const [newDataGroupByLocation, setNewDataGroupByLocation] = React.useState<
  //   ScheduleGroupLocationInterface[]
  // >([]);

  // const { isLoading, data, setQuery, fetchList } = useFetchList<ScheduleProps>({
  //   lazyQuery: true,
  //   endpoint: "schedules",
  //   limit: 99999,
  //   initialQuery: {
  //     dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
  //     dateUntil: moment().endOf("month").format("YYYY-MM-DD"),
  //   },
  // });

  // const {
  //   isLoading: isLoadingScheduleDelivery,
  //   data: dataScheduleDelivery,
  //   setQuery: setQueryScheduleDelivery,
  //   fetchList: fetchListScheduleDelivery,
  // } = useFetchList<ScheduleDeliveryProperties>({
  //   lazyQuery: true,
  //   endpoint: "schedule-deliveries",
  //   limit: 99999,
  //   initialQuery: {
  //     deliveryRangeStartAt: moment().startOf("month").format("YYYY-MM-DD"),
  //     deliveryRangeEndAt: moment().endOf("month").format("YYYY-MM-DD"),
  //   },
  // });

  React.useEffect(() => {
    adjustWeeklyEventBackground();
  }, [data]);

  const adjustWeeklyEventBackground = () => {
    if (window) {
      const elementTable = window.document.getElementsByClassName(
        'fc-scrollgrid-sync-table'
      )[0].children;
      const tBody = elementTable[1].children;
      for (let i = 0; i < tBody.length; i++) {
        const tRow = tBody[i].children;
        for (let j = 0; j < tRow.length; j++) {
          if (shownDates.includes((tRow[j] as any).dataset.date)) {
            tRow[j].children[0].classList.add('bg-white');
          }
        }
      }
    }
  };

  const handleClickDate = (clickedDate: any) => {
    setClickedDate(clickedDate);
    setIsModalVisible(true);

    // const found = dataGroupByLocation.find(
    //   (item) => item.scheduleAt === clickedDate.dateStr
    // );

    // if (found) {
    //   if (moment(clickedDate.dateStr).isBefore(moment().format('YYYY-MM-DD'))) {
    //     history.push('/schedule/' + clickedDate.dateStr + '/detail');
    //   } else {
    //     history.push('/schedule/' + clickedDate.dateStr + '/edit');
    //   }
    // } else if (
    //   moment(clickedDate.dateStr).isSameOrAfter(moment().format('YYYY-MM-DD'))
    // ) {
    //   setClickedDate(clickedDate);
    //   setIsModalVisible(true);
    // } else {
    //   history.push('/schedule/' + clickedDate.dateStr + '/detail');
    // }
  };

  const handleClickEvent = async (event: EventApi) => {
    if (
      moment(event.startStr.substring(0, 10)).isBefore(
        moment().format('YYYY-MM-DD')
      )
    ) {
      history.push('/schedule/' + event.startStr.substring(0, 10) + '/detail');
    } else {
      history.push('/schedule/' + event.startStr.substring(0, 10) + '/edit');
    }
  };

  const fetchSchedulePickup = async (query: {
    dateFrom: string;
    dateUntil: string;
  }) => {
    setLoadingSchedule(true);
    try {
      const res = await httpRequest.get<FetchAllSchedulePickupResponse>(
        'schedules' + generateQueryString(query)
      );
      if (res && res.data.payload) {
        setData(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed get schedule detail ', error);
    } finally {
      setLoadingSchedule(false);
    }
  };

  const fetchCopySchedulePickup = async (query: {
    dateFrom: string;
    dateUntil: string;
  }) => {
    try {
      const res = await httpRequest.get<FetchAllSchedulePickupResponse>(
        'schedules' + generateQueryString(query)
      );
      if (res && res.data.payload) {
        setDataCopySchedule(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed get schedule detail ', error);
    }
  };

  const fetchScheduleDelivery = async (query: {
    scheduleFrom: string;
    scheduleUntil: string;
  }) => {
    setLoadingSchedule(true);
    try {
      const res = await httpRequest.get<FetchAllScheduleDeliveryResponse>(
        'schedule-deliveries' + generateQueryString(query)
      );
      if (res && res.data.payload) {
        setSchedulesDelivery(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed get schedule detail ', error);
    } finally {
      setLoadingSchedule(false);
    }
  };

  const fetchCopyScheduleDelivery = async (query: {
    scheduleFrom: string;
    scheduleUntil: string;
  }) => {
    try {
      const res = await httpRequest.get<FetchAllScheduleDeliveryResponse>(
        'schedule-deliveries' + generateQueryString(query)
      );
      if (res && res.data.payload) {
        setCopySchedulesDelivery(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed get schedule detail ', error);
    }
  };

  const onChangeDateModal = (date: any) => {
    setCopyDate(moment(date).format('YYYY-MM-DD').toString());
  };

  const submitCopyDate = async () => {
    setIsLoadingAction(true);
    try {
      await httpRequest.post('/schedules/copy', {
        copyFromDate: copyDate,
        copyToDate: choosenDate.dateStr,
      });

      const res = await httpRequest.get<
        BaseResponsePaginationProps<ScheduleDeliveryProperties>
      >('schedule-deliveries' + generateQueryString({ scheduleAt: copyDate }));
      if (
        res &&
        res.data &&
        res.data.payload &&
        res.data.payload.results.length > 0
      ) {
        const currData = res.data.payload.results[0];
        const rangeStart = moment(currData.deliveryRangeStartAt);
        const rangeEnd = moment(currData.deliveryRangeEndAt);
        const diff = rangeEnd.diff(rangeStart, 'days');
        const data = {
          scheduleAt: choosenDate.dateStr,
          deliveryRangeStartAt: choosenDate.dateStr,
          deliveryRangeEndAt:
            diff > 0
              ? moment(choosenDate.dateStr)
                  .add(diff, 'days')
                  .format('YYYY-MM-DD')
              : moment(choosenDate.dateStr).format('YYYY-MM-DD'),
          deliveryCoverageId: currData.deliveryCoverageId,
          scheduleDeliveryId: currData.scheduleDeliveryId,
        };
        await httpRequest.post<any>('schedule-deliveries/copy', { ...data });

        if (startAtCalendar && endAtCalendar) {
          await fetchSchedulePickup({
            dateFrom: startAtCalendar,
            dateUntil: endAtCalendar,
          });
          await fetchScheduleDelivery({
            scheduleFrom: startAtCalendar,
            scheduleUntil: endAtCalendar,
          });
        }
      }
      // fetchSchedulePickup();

      setIsLoadingAction(false);
      setIsModalVisible(false);

      message.success(
        'Success copy from ' + copyDate + ' to ' + choosenDate.dateStr
      );
    } catch (err) {
      message.error('Failed to copy schedule. ' + getErrorMessage(err));
      setIsLoadingAction(false);
    }
  };

  const getLocationName = (locationName: string | undefined) => {
    if (locationName) {
      if (locationName.length > 12) {
        return locationName.substring(0, 12) + '...';
      } else {
        return locationName;
      }
    }
  };

  const locationIds: string[] = [];
  let dataGroupByLocation = data.reduce((acc, curr: ScheduleProps) => {
    const findIndex = acc.findIndex(
      (item) =>
        item.locationId === curr.locationId &&
        item.scheduleAt === curr.scheduleAt
    );
    if (findIndex > -1) {
      acc[findIndex]?.menus.push(curr.menu || { ...initialMenu });
    } else {
      acc.push({
        scheduleAt: curr.scheduleAt,
        locationId: curr.locationId,
        location: curr.location,
        menus: [curr.menu || { ...initialMenu }],
        isDelivery: false,
      });
    }

    if (!locationIds.includes(curr.locationId)) {
      locationIds.push(curr.locationId);
    }

    return acc;
  }, [] as Array<ScheduleGroupLocationInterface>);

  let dataCopyScheduleGroupByLocation = dataCopySchedule.reduce(
    (acc, curr: ScheduleProps) => {
      const findIndex = acc.findIndex(
        (item) =>
          item.locationId === curr.locationId &&
          item.scheduleAt === curr.scheduleAt
      );
      if (findIndex > -1) {
        acc[findIndex]?.menus.push(curr.menu || { ...initialMenu });
      } else {
        acc.push({
          scheduleAt: curr.scheduleAt,
          locationId: curr.locationId,
          location: curr.location,
          menus: [curr.menu || { ...initialMenu }],
          isDelivery: false,
        });
      }

      if (!locationIds.includes(curr.locationId)) {
        locationIds.push(curr.locationId);
      }

      return acc;
    },
    [] as Array<ScheduleGroupLocationInterface>
  );

  let dataEvents = () => {
    let resultSchedules: any[] = [];
    let resultScheduleDeliveries: any[] = [];
    if (
      dataScheduleDelivery.length > 0 ||
      (dataGroupByLocation && dataGroupByLocation.length > 0)
    ) {
      resultSchedules = [...dataGroupByLocation].map((item, idx) => {
        return {
          title: getLocationName(item.location?.locationName),
          start: `${item.scheduleAt}T05:00:00`,
          order: idx + 1,
          backgroundColor:
            COLORS_B[locationIds.findIndex((id) => id === item.locationId)],
          isDelivery: false,
          totalMenu: item.menus.length,
        };
      });

      resultScheduleDeliveries = dataScheduleDelivery.map((item) => ({
        title: `Delivery ${
          item.schedule_delivery_menus
            ? '(' + item.schedule_delivery_menus.length + ' Menu)'
            : 0
        }`,
        start: moment(item.deliveryRangeStartAt).format('YYYY-MM-DD'),
        end: moment(item.deliveryRangeEndAt)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        order: 0,
        color: '#56C288',
        isDelivery: true,
      }));
    }

    return [...resultSchedules, ...resultScheduleDeliveries];
  };

  let dataEventsCopySchedule = () => {
    let resultSchedules: any[] = [];
    let resultScheduleDeliveries: any[] = [];
    if (
      dataCopyScheduleDelivery.length > 0 ||
      (dataCopyScheduleGroupByLocation &&
        dataCopyScheduleGroupByLocation.length > 0)
    ) {
      resultSchedules = [...dataCopyScheduleGroupByLocation].map(
        (item, idx) => {
          return {
            title: getLocationName(item.location?.locationName),
            start: `${item.scheduleAt}T05:00:00`,
            order: idx + 1,
            backgroundColor:
              COLORS_B[locationIds.findIndex((id) => id === item.locationId)],
            isDelivery: false,
            totalMenu: item.menus.length,
          };
        }
      );

      resultScheduleDeliveries = dataCopyScheduleDelivery.map((item) => ({
        title: `Delivery ${
          item.schedule_delivery_menus
            ? '(' + item.schedule_delivery_menus.length + ' Menu)'
            : 0
        }`,
        start: moment(item.deliveryRangeStartAt).format('YYYY-MM-DD'),
        end: moment(item.deliveryRangeEndAt)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        order: 0,
        color: '#56C288',
        isDelivery: true,
      }));
    }

    return [...resultSchedules, ...resultScheduleDeliveries];
  };

  // dataGroupByLocation.sort((a, b) => {
  //   const res = (a.location?.locationType || '').localeCompare(
  //     b.location?.locationType || ''
  //   );
  //   return res;
  // })
  // const grouping = dataGroupByLocation.reduce((acc, curr) => {
  //   if (acc[curr.scheduleAt]) {
  //     acc[curr.scheduleAt].push(curr);
  //   } else {
  //     acc[curr.scheduleAt] = [curr];
  //   }
  //   return acc;
  // }, {} as any);
  // Object.keys(grouping).forEach(key => {
  //   grouping[key].sort((a: any, b: any) => {
  //     const res = (b.location?.locationType || '').localeCompare(
  //       a.location?.locationType || ''
  //     );
  //     return res;
  //   });
  // })

  // dataGroupByLocation = []
  // Object.keys(grouping).forEach(key => {
  //   dataGroupByLocation = [...dataGroupByLocation, ...grouping[key]]
  // })

  console.log('dataEvents', dataEvents());

  const handleNext = () => {
    const calendarApi = fullCalendar.current.getApi();
    calendarApi.next();
    setStartAtCalendar(
      moment(calendarApi.view.currentStart).format('YYYY-MM-DD')
    );
    setEndAtCalendar(moment(calendarApi.view.currentEnd).format('YYYY-MM-DD'));
  };

  const handlePrev = () => {
    const calendarApi = fullCalendar.current.getApi();
    calendarApi.prev();
    setStartAtCalendar(
      moment(calendarApi.view.currentStart).format('YYYY-MM-DD')
    );
    setEndAtCalendar(moment(calendarApi.view.currentEnd).format('YYYY-MM-DD'));
  };

  const uniqueDate = () => {
    return _.uniqBy(
      dataEventsCopySchedule().map((item) => ({
        ...item,
        start: moment(item.start).format('YYYY-MM-DD'),
      })),
      'start'
    ).map((item) => item.start);
  };
  console.log('uniqueDate', uniqueDate);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today

    return (
      uniqueDate().findIndex(
        (date) => date === moment(current).format('YYYY-MM-DD')
      ) === -1
    );
  };

  useEffect(() => {
    if (startAtCalendar && endAtCalendar) {
      fetchSchedulePickup({
        dateFrom: startAtCalendar,
        dateUntil: endAtCalendar,
      });
      fetchScheduleDelivery({
        scheduleFrom: startAtCalendar,
        scheduleUntil: endAtCalendar,
      });
    }
  }, [startAtCalendar, endAtCalendar]);

  useEffect(() => {
    if (startAtCalendarCopySchedule && endAtCalendarCopySchedule) {
      fetchCopySchedulePickup({
        dateFrom: startAtCalendarCopySchedule,
        dateUntil: endAtCalendarCopySchedule,
      });
      fetchCopyScheduleDelivery({
        scheduleFrom: startAtCalendarCopySchedule,
        scheduleUntil: endAtCalendarCopySchedule,
      });
    }
  }, [startAtCalendarCopySchedule, endAtCalendarCopySchedule]);

  useEffect(() => {
    if (fullCalendar) {
      const calendarApi = fullCalendar.current.getApi();
      setStartAtCalendar(
        moment(calendarApi.view.currentStart).format('YYYY-MM-DD')
      );
      setEndAtCalendar(
        moment(calendarApi.view.currentEnd).format('YYYY-MM-DD')
      );
      setStartAtCalendarCopySchedule(
        moment(calendarApi.view.currentStart).format('YYYY-MM-DD')
      );
      setEndAtCalendarCopySchedule(
        moment(calendarApi.view.currentEnd).format('YYYY-MM-DD')
      );
    }
  }, [fullCalendar]);

  return (
    <AppLayout>
      <HeaderSection
        icon={<CalendarOutlined />}
        title='Food Schedule'
        subtitle='Manage your Schedules'
      />

      <div
        style={{
          background: 'white',
          padding: 15,
          border: '1px solid #D5DCE1',
        }}
      >
        <Spin tip='Loading...' spinning={isLoadingSchedule || isLoadingAction}>
          <FullCalendar
            ref={fullCalendar}
            plugins={[dayGridPlugin, interactionPlugin]}
            dateClick={handleClickDate}
            // datesSet={(dateInfo) => {
            //   setQuery({
            //     dateFrom: moment(dateInfo.startStr).format("YYYY-MM-DD"),
            //     dateUntil: moment(dateInfo.endStr).format("YYYY-MM-DD"),
            //   });
            // }}
            eventOrder={['order']}
            dayMaxEventRows={6}
            // viewHeight={100}
            eventShortHeight={100}
            eventContent={({ event }) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: !event.extendedProps.isDelivery ? '0px 5px' : 0,
                }}
                onClick={() => {
                  handleClickEvent(event);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 5,
                      background: event.backgroundColor,
                      marginRight: !event.extendedProps.isDelivery ? 7 : 0,
                    }}
                  />
                  <div>{event.title}</div>
                </div>
                {event.extendedProps.totalMenu && (
                  <div>({event.extendedProps.totalMenu})</div>
                )}
              </div>
            )}
            eventColor='green'
            events={dataEvents()}
            customButtons={{
              nextBtn: {
                text: 'Next',
                click: () => handleNext(),
              },
              prevBtn: {
                text: 'Prev',
                click: () => handlePrev(),
              },
            }}
            headerToolbar={{
              left: 'title',
              // center: "title",
              right: 'today prevBtn nextBtn',
            }}
          />
        </Spin>
      </div>

      <div style={{ height: 50 }} />

      <Modal
        title={`Menu For ${moment(choosenDate.dateStr)
          .format('MMMM Do YYYY')
          .toString()}`}
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        width={624}
      >
        <Row gutter={[8, 8]}>
          <Col
            span={12}
            style={{
              textAlign: 'center',
            }}
          >
            <Row justify='center'>
              <img
                alt='image-schedule-add'
                src='/images/icon_schedule_create.png'
              />
            </Row>
            <Row
              justify='center'
              style={{ paddingBottom: '5%', paddingTop: '5%' }}
            >
              <Text style={{ fontSize: 16 }} strong>
                Add New Schedule
              </Text>
            </Row>
            <Row justify='center'>
              <AppButton
                type='primary'
                onClick={() => {
                  history.push({
                    pathname: `/schedule/${moment(choosenDate.date)
                      .format('YYYY-MM-DD')
                      .toString()}/edit`,
                    state: {
                      scheduleAt: choosenDate.dateStr,
                    },
                  });
                }}
              >
                Create New Schedule
              </AppButton>
            </Row>
          </Col>

          <Col span={12}>
            <Row justify='center'>
              <img
                alt='image-schedule-add'
                src='/images/icon_schedule_copy.png'
              />
            </Row>
            <Row
              justify='center'
              style={{ paddingBottom: '5%', paddingTop: '5%' }}
            >
              <Text style={{ fontSize: 16 }} strong>
                Copy Another Schedule
              </Text>
            </Row>
            <Row justify='center'>
              <Col>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={onChangeDateModal}
                  onPanelChange={(a) => {
                    setStartAtCalendarCopySchedule(
                      moment(a).startOf('month').format('YYYY-MM-DD hh:mm')
                    );
                    setEndAtCalendarCopySchedule(
                      moment(a).endOf('month').format('YYYY-MM-DD hh:mm')
                    );
                  }}
                />
              </Col>
              <Col offset={1}>
                <AppButton
                  type='primary'
                  loading={isLoadingAction}
                  onClick={submitCopyDate}
                >
                  Confirm
                </AppButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </AppLayout>
  );
};

export default Schedule;
