import { useEffect } from 'react';
import { getToken, removeToken, saveToken } from '../helpers/auth';
import { createGlobalState } from 'react-hooks-global-state';
import { IUser, initialUser } from '../types/user.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import axios from 'axios';

type IAuthData = {
  isLoading?: boolean,
  token: string;
  user: IUser;
  lastFetched?: Date | null;
};

const initialState: IAuthData = { 
  isLoading: true,
  token: '', 
  user: { ...initialUser },
  lastFetched: null,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useAuth() {
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [token, setToken] = useGlobalState('token');
  const [user, setUser] = useGlobalState('user');

  useEffect(() => {
    init();
  }, [])
  
  const init = async () => {
    const tokenString = await getToken()
    if (tokenString) {
      setToken(tokenString)
    }
    setIsLoading(false)
  };

  const getProfile = async () => {
    try {
      setIsLoading(true);
      const resultUser = await axios.get<IHttpResponse<IUser>>('/admins/me', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      setUser(resultUser.data.payload);
      setIsLoading(false);
    } catch(err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const saveProfile = async (data: IUser) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', data.name)
    formData.append('email', data.email)
    formData.append('phoneNumber', data.phoneNumber)
    formData.append('role', data.role)
    formData.append('status', data.status)
    
    if (data.image) {
      formData.append('image', data.image);
    }

    try {
      const resultUpdate = await axios.patch<IHttpResponse<IUser>>(
        '/admins/' + user.adminId,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUser(resultUpdate.data.payload);
      setIsLoading(false);
      message.success('Update profile successfully')
    } catch(err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const login = async (data: { email: string; password: string }) => {
    try {
      const resultAuthLogin = await axios.post<IHttpResponse<{
        token: string;
      }>>('/auth/signin', data);

      const resultUser = await axios.get<IHttpResponse<IUser>>(
        '/admins/me', {
          headers: {
            Authorization: 'Bearer ' + resultAuthLogin.data.payload.token
          }
        }
      );

      handleAfterLogin({
        token: resultAuthLogin.data.payload.token,
        user: resultUser.data.payload,
      })

      message.success('Login successfully.');
      // message.success(`Hi, ${resultUser.data.payload.name}.`);
      setIsLoading(false);
    } catch (err) {
      message.error('Login failed. ' + getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const handleAfterLogin = (data: IAuthData) => {
    saveToken(data.token);
    setToken(data.token);

    setUser(data.user);
  };

  const logout = () => {
    removeToken();
    setToken('');
    setUser({...initialUser});
  };

  return {
    isLoading,
    isLoggedIn: token ? true : false,
    token,
    user,
    handleAfterLogin,
    login,
    logout,
    getProfile,
    saveProfile,
  };
}