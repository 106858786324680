import AppLayout from '../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Input,
  Select,
  Row,
  Col,
  Tag,
  DatePicker,
  Space,
  Button,
  Modal,
  Alert,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { PAGE_SIZE_OPTIONS, formatDateDefault } from '../../helpers/constant';
import { BaseResponseProps } from '../../types/config.type';
import { ContainerFilter } from '../admin';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
import {
  EAmountType,
  EShipmentMethod,
  ETransactionStatus,
  FetchAllTransactionResponse,
  TransactionItemProps,
  TransactionProps,
} from '../../types/transaction.type';
import { ReactComponent as OrderLogo } from '../../assets/icons/order-icon.svg';
import { httpRequest } from '../../helpers/api';
import useAuth from '../../hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import AppButton from '../../components/AppButton';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import MyDocument from './pdf';
import { saveAs } from 'file-saver';
import Text from 'antd/lib/typography/Text';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { generateQueryString } from '../../helpers/generateQueryString';
import { capitalizeFirstLetter } from '../../helpers/text';

interface ResponseProps extends BaseResponseProps {
  payload: Omit<TransactionProps, 'createdAt' | 'updatedAt'>;
}

interface IDataMapped {
  buyerName: string;
  orderType: string;
  menus: {
    qty: number;
    name: string;
    group: string;
  }[];
}

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const Transaction = () => {
  const history = useHistory();
  const { token } = useAuth();

  const labelStyle = {
    width: '150px',
    color: 'grey',
  };

  const waitingPaymentMethod = [
    ETransactionStatus.WAITING_PAYMENT,
    ETransactionStatus.CREATED,
  ];
  const paidMethod = [
    ETransactionStatus.COMPLETED,
    ETransactionStatus.PAID,
    ETransactionStatus.COD,
    ETransactionStatus.CREDITUR_ACCOUNT,
    ETransactionStatus.PROCESS,
    ETransactionStatus.PACKAGING,
    ETransactionStatus.PROCESS_DONE,
    ETransactionStatus.READY_FOR_SHIPMENT,
  ];

  const styleStatus = [
    {
      status: ETransactionStatus.WAITING_PAYMENT,
      style: {
        borderRadius: '20px',
        color: '#E89635',
        backgroundColor: '#FFF9EF',
        borderColor: '#E89635',
        padding: '.2rem',
        width: 'auto',
      },
    },
    {
      status: ETransactionStatus.PAID,
      style: {
        borderRadius: '20px',
        color: '#39AC6E',
        backgroundColor: '#F5FFF9',
        borderColor: '#39AC6E',
        padding: '.2rem',
      },
    },
    {
      status: ETransactionStatus.COMPLETED,
      style: {
        borderRadius: '20px',
        color: '#748494',
        backgroundColor: '#F1F3F5',
        borderColor: '#748494',
        padding: '.2rem',
        width: 'auto',
      },
    },
  ];

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    setIsLoading,
  } = useFetchList<TransactionProps>({
    endpoint: 'transactions',
    initialQuery: {
      // scheduledAtFrom: moment().utc().startOf('day').toISOString(),
      // scheduledAtUntil: moment().utc().endOf('day').toISOString(),
      transactionStatus: Object.values(ETransactionStatus),
      sortBy: 'scheduledAt',
      sortOrder: 'DESC',
    },
  });

  const [dateRange, setDateRange] = useState<any[]>([undefined, undefined]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(true);
  const [isCredit, setIsCredit] = useState<boolean>(true);
  const [isCOD, setIsCOD] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [lengthData, setLengthData] = useState<number>(0);
  const [dataForExport, setDataForExport] = useState<TransactionProps[]>([]);
  const [exportOrder, setExportOrder] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [loadingDataExport, setLoadingDataExport] = useState<boolean>(false);

  const handleClickDetail = (e: TransactionProps) => {
    history.push(`/order/${e.transactionId}`);
  };

  const { Option } = Select;

  function getOrderScheduleDay(date: string): string {
    const scheduleAt = new Date(date).getDay();
    let day = '';
    switch (scheduleAt) {
      case 0:
        day = 'Sun';
        break;
      case 1:
        day = 'Mon';
        break;
      case 2:
        day = 'Tue';
        break;
      case 3:
        day = 'Wed';
        break;
      case 4:
        day = 'Thu';
        break;
      case 5:
        day = 'Fri';
        break;
      case 6:
        day = 'Sat';
        break;
      default:
        day = 'Day is not existing.';
    }
    return day;
  }

  const handleOrderTypeChange = (orderType: 'PICKUP' | 'DELIVERY' | string) => {
    if (orderType === 'PICKUP') {
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: 'PICKUP' }));
    } else if (orderType === 'DELIVERY') {
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: 'DELIVERY' }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: null }));
    }
  };

  const handleOrderStatusChange = (status: ETransactionStatus) => {
    if (status === ETransactionStatus.PROCESS) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [
          ETransactionStatus.PAID,
          ETransactionStatus.PROCESS,
        ],
      }));
    } else if (status === ETransactionStatus.PROCESS_DONE) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [ETransactionStatus.PROCESS_DONE],
      }));
    } else if (status === ETransactionStatus.READY_FOR_SHIPMENT) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [ETransactionStatus.READY_FOR_SHIPMENT],
      }));
    } else if (status === ETransactionStatus.COMPLETED) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [ETransactionStatus.COMPLETED],
      }));
    } else if (status === ETransactionStatus.WAITING_PAYMENT) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [
          ETransactionStatus.WAITING_PAYMENT,
          ETransactionStatus.CREATED,
        ],
      }));
    } else if (status === ETransactionStatus.PAID) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: [
          ETransactionStatus.COMPLETED,
          ETransactionStatus.PAID,
          ETransactionStatus.PROCESS,
          ETransactionStatus.PROCESS_DONE,
          ETransactionStatus.READY_FOR_SHIPMENT,
        ],
      }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, transactionStatus: null }));
    }
  };

  const handleClickDate = (startDate: any, endDate: any) => {
    setQuery((oldVal) => ({
      ...oldVal,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleClickDateModal = (startDate: any, endDate: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const formattedDatePdf = () => {
    const date = formatDateDefault(new Date());
    const invoiceCodes = dataForExport
      .map((item) => item.invoiceCode)
      .join('-');
    return `SFK-${date}-${invoiceCodes}.pdf`;
  };

  const handleClickModal = () => {
    setIsModalVisible(true);
  };

  const fetchData = async () => {
    try {
      setLoadingDataExport(true);
      let query = {};
      let transactionStatus: string = '';
      if (isPaid) {
        transactionStatus += 'PAID,';
      }
      if (isCredit) {
        transactionStatus += 'CREDITUR_ACCOUNT';
      }
      if (transactionStatus.length > 1) {
        query = {
          ...query,
          transactionStatus: transactionStatus,
        };
      }
      if (startDate) {
        query = {
          ...query,
          startDate,
        };
      }
      if (endDate) {
        query = {
          ...query,
          endDate,
        };
      }
      const res = await httpRequest.get<FetchAllTransactionResponse>(
        `/transactions${generateQueryString(query)}`
      );
      setLengthData(res.data.payload.results.length);
      const dataSorted = res.data.payload.results.sort((a, b) => {
        let invA = parseInt(a.invoiceCode.slice(3));
        let invB = parseInt(b.invoiceCode.slice(3));

        return invA - invB;
      });
      console.log(dataSorted.map((item) => item.invoiceCode));
      setDataForExport(dataSorted);
      if (res.data.payload.results.length === 0) {
        setExportOrder(false);
      } else {
        setExportOrder(true);
      }
    } catch (error) {
      console.error('fetch for export', error);
    } finally {
      setLoadingDataExport(false);
    }
  };

  const handleFilter = (filter: 'paid' | 'credit' | 'cod', value: boolean) => {
    const allOptions = [isPaid, isCredit, isCOD].filter((item) => item);

    if (allOptions.length === 1 && value === false) {
      setIsAllowed(false);
    } else {
      setIsAllowed(true);
      if (filter === 'paid') {
        setIsPaid(value);
      } else if (filter === 'credit') {
        setIsCredit(value);
      } else if (filter === 'cod') {
        setIsCOD(value);
      }
    }
  };

  const isBtnExportDisable = useMemo(() => {
    if (!isPaid && !isCredit && !isCOD) {
      return true;
    }

    if (!startDate || !endDate) {
      return true;
    }

    if (!dataForExport.length) {
      return true;
    }

    return false;
  }, [isPaid, isCredit, isCOD, startDate, endDate, dataForExport]);

  useEffect(() => {
    fetchData();
  }, [isPaid, isCredit, startDate, endDate, isModalVisible]);

  const handleClickPDF = async (data: TransactionProps[]) => {
    try {
      setLoadingExport(true);
      const blob = await pdf(<MyDocument data={data} />).toBlob();

      saveAs(blob, formattedDatePdf());
    } catch (error) {
      console.error('Failed export', error);
    } finally {
      setLoadingExport(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setIsPaid(true);
    setIsCredit(false);
    setIsAllowed(true);
  };

  const handleTransactionStatusChange = async (
    record: TransactionProps,
    newStatus: string
  ) => {
    setIsLoading(true);
    const res = await httpRequest.patch<ResponseProps>(
      '/transactions/' + record.transactionId + '/transaction-status',
      {
        transactionStatus: newStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setQuery((oldVal) => ({ ...oldVal }));
    setIsLoading(false);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const dataMapped = data.map((item) => {
        return {
          orderType: item.shipment.method,
          orderId: item.transactionCode,
          buyerName: item.metaBuyer?.fullName,
          menus: item.items.reduce(
            (
              acc: { name: string; qty: number; group: string }[],
              curr: TransactionItemProps
            ) => {
              let menuItemMap: any = [];
              if (curr.metaProduct.menuItems) {
                for (const curr2 of curr.metaProduct.menuItems) {
                  menuItemMap.push({
                    name: (curr2 as any).menuName,
                    qty: (curr2 as any).qty,
                    group: curr.metaProduct.name,
                  });
                }
              } else {
                acc.push({
                  name: curr.metaProduct.name,
                  qty: curr.quantity,
                  group:
                    curr.metaProduct.variant?.label || curr.metaProduct.name,
                });
              }
              return _.orderBy([...acc, ...menuItemMap], ['group', 'asc']);
            },
            [] as { name: string; qty: number; group: string }[]
          ),
        };
      });
    }
  }, [data]);

  function truncateText(text: string) {
    if (!text) {
      return;
    } else if (text.length > 20) {
      return text.substring(0, 20) + '...';
    } else {
      return text;
    }
  }

  const columns = [
    {
      // width: 150,
      title: 'Order Schedule',
      dataIndex: 'scheduledAt',
      key: 'scheduledAt',
      // sorter: (a: TransactionProps, b: TransactionProps) =>
      //   a.shipment.scheduledAt.localeCompare(b.shipment.scheduledAt),
      render: (text: string, record: TransactionProps) => {
        return (
          <div>{`${getOrderScheduleDay(record.shipment.scheduledAt)}, ${
            record.shipment.scheduledAt
          }`}</div>
        );
      },
    },
    {
      // width: 150,
      title: 'Order ID',
      key: 'transactionCode',
      dataIndex: 'transactionCode',
      // sorter: (a: TransactionProps, b: TransactionProps) =>
      //   a.transactionCode.localeCompare(b.transactionCode),
      render: (text: string, record: TransactionProps) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      width: 320,
      title: 'Customer',
      key: 'Customer',
      dataIndex: 'metaBuyer.fullName',
      render: (text: string, record: TransactionProps) => {
        return (
          <Col>
            <div>
              {record.metaBuyer?.fullName
                ? record.metaBuyer?.fullName
                : 'Linda Arden'}
            </div>
            {record.metaDiscount && record.metaDiscount.discountName && (
              <Tag color='black' style={{ borderRadius: '50px' }}>
                {record.metaDiscount?.discountName}
              </Tag>
            )}

            {record.metaBuyer &&
              record.metaBuyer.customerReferral &&
              record.metaBuyer.customerReferral.length > 0 &&
              record.metaBuyer.checkoutType && (
                <div style={{ marginTop: 10, fontSize: 12 }}>
                  <div>
                    <span style={{ opacity: 0.5 }}>Checkout type: </span>
                    {capitalizeFirstLetter(
                      record.metaBuyer.checkoutType === 'regular'
                        ? 'customer'
                        : record.metaBuyer.checkoutType
                    )}
                  </div>
                  {record.metaBuyer.customerReferral[0].reseller && (
                    <div>
                      <span style={{ opacity: 0.5 }}>Reseller group: </span>
                      {truncateText(
                        record.metaBuyer.customerReferral[0].reseller
                          .resellerName
                      )}
                    </div>
                  )}
                </div>
              )}
          </Col>
        );
      },
    },
    {
      width: 110,
      title: 'Order Type',
      key: 'method',
      dataIndex: 'shipment.method',
      render: (text: string, record: TransactionProps) => {
        return (
          <div style={{ textAlign: 'left' }}>{record.shipment.method}</div>
        );
      },
    },
    {
      // width: 0,
      title: 'Order Menu',
      key: 'items',
      dataIndex: 'items',
      ellipsis: true,
      render: (text: string, record: TransactionProps) => {
        return (
          <Col>
            <div>{`${record.items[0].metaProduct.name}`}</div>
            {record.items.length > 1 ? (
              <div>{`+ ${record.items.length - 1} more menu`}</div>
            ) : (
              false
            )}
          </Col>
        );
      },
    },
    {
      width: 120,
      title: 'Total Price',
      key: 'finalPrice',
      dataIndex: 'finalPrice',
      render: (price: number, record: TransactionProps) => {
        return (
          <Col>
            {record.metaDiscount &&
            record.metaDiscount.discountNominal !== 0 ? (
              <div
                style={{
                  fontSize: '12px',
                  color: 'grey',
                  textDecoration: 'line-through',
                }}
              >{`$${(price + record.metaDiscount.discountNominal).toFixed(2)} ${
                record.metaDiscount.discountType === EAmountType.PERCENTAGE
                  ? `(${Math.round(
                      (record.metaDiscount.discountNominal /
                        record.finalPrice) *
                        100
                    )})%`
                  : ''
              }`}</div>
            ) : (
              ''
            )}
            <div>{`$${price.toFixed(2)}`}</div>
          </Col>
        );
      },
    },
    {
      // width: 180,
      title: 'Payment Status',
      key: 'Payment Status',
      dataIndex: 'transactionStatus',
      ellipsis: true,
      render: (status: ETransactionStatus, record: TransactionProps) => {
        return (
          <select
            name='paymentStatus'
            id='paymentStatus'
            value={record.transactionStatus}
            style={
              paidMethod.includes(record.transactionStatus)
                ? styleStatus[1].style
                : waitingPaymentMethod.includes(record.transactionStatus)
                ? styleStatus[0].style
                : styleStatus[2].style
            }
            onChange={function (selectObject) {
              const value = selectObject.target.value;
              handleTransactionStatusChange(record, value);
            }}
          >
            <option value={ETransactionStatus.WAITING_PAYMENT}>
              Waiting payment
            </option>
            <option value={ETransactionStatus.PAID}>Paid</option>
            <option value={ETransactionStatus.COD}>COD</option>
            <option value={ETransactionStatus.CREDITUR_ACCOUNT}>
              Credit Account
            </option>
            <option value={ETransactionStatus.CANCELED}>Canceled</option>
            <option hidden value={ETransactionStatus.COMPLETED}>
              Paid
            </option>
            <option hidden value={ETransactionStatus.PROCESS}>
              Paid
            </option>
            <option hidden value={ETransactionStatus.PACKAGING}>
              Paid
            </option>
            <option hidden value={ETransactionStatus.READY_FOR_SHIPMENT}>
              Paid
            </option>
          </select>
        );
      },
    },
    {
      title: 'Order Status',
      key: 'Order Status',
      dataIndex: 'orderStatus',
      ellipsis: true,
      render: (status: ETransactionStatus, record: TransactionProps) => {
        return (
          <select
            name='orderStatus'
            id='orderStatus'
            value={
              record.transactionStatus === ETransactionStatus.WAITING_PAYMENT ||
              record.transactionStatus === ETransactionStatus.CREATED
                ? 'not set'
                : record.transactionStatus === ETransactionStatus.CANCELED
                ? 'canceled'
                : record.transactionStatus === ETransactionStatus.PAID ||
                  record.transactionStatus === ETransactionStatus.COD ||
                  record.transactionStatus ===
                    ETransactionStatus.CREDITUR_ACCOUNT
                ? ETransactionStatus.PROCESS
                : record.transactionStatus
            }
            style={styleStatus[2].style}
            onChange={function (selectObject) {
              const value = selectObject.target.value;
              handleTransactionStatusChange(record, value);
            }}
          >
            <option value={ETransactionStatus.COMPLETED}>Done</option>
            <option value={ETransactionStatus.PROCESS}>Preparing</option>
            <option value={ETransactionStatus.PACKAGING}>Packaging</option>
            <option value={ETransactionStatus.READY_FOR_SHIPMENT}>
              Packaging Done
            </option>
            <option hidden value={'not set'}>
              Waiting Payment
            </option>
            <option hidden value={'canceled'}>
              Canceled
            </option>
          </select>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && data.length > 0) {
      const dataMapped = data.map((item) => {
        return {
          orderType: item.shipment.method,
          orderId: item.transactionCode,
          buyerName: item.metaBuyer?.fullName,
          menus: item.items.reduce(
            (
              acc: { name: string; qty: number; group: string }[],
              curr: TransactionItemProps
            ) => {
              let menuItemMap: any = [];
              if (curr.metaProduct.menuItems) {
                for (const curr2 of curr.metaProduct.menuItems) {
                  menuItemMap.push({
                    name: (curr2 as any).menuName,
                    qty: (curr2 as any).qty,
                    group: curr.metaProduct.name,
                  });
                }
              } else {
                acc.push({
                  name: curr.metaProduct.name,
                  qty: curr.quantity,
                  group:
                    curr.metaProduct.variant?.label || curr.metaProduct.name,
                });
              }
              return _.orderBy([...acc, ...menuItemMap], ['group', 'asc']);
            },
            [] as { name: string; qty: number; group: string }[]
          ),
        };
      });
    }
  }, [data]);

  return (
    <AppLayout>
      <HeaderSection
        icon={<OrderLogo />}
        title='Orders'
        subtitle='Manage your transactions data'
        rightAction={
          <Space>
            <Button
              icon={<DownloadOutlined />}
              style={{
                backgroundColor: '#FF4A50',
                fontSize: '14px',
                color: 'white',
                border: 'unset',
              }}
              type='primary'
              onClick={() => {
                handleClickModal();
              }}
            >
              Export Orders
            </Button>
          </Space>
        }
      />

      <ContainerFilter>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 36 }}>
          <Col>
            <h4 style={labelStyle}>Order Type</h4>
            <Select
              size='large'
              allowClear
              style={{ width: 150 }}
              onChange={handleOrderTypeChange}
              placeholder='Order Type'
            >
              <Option value='all'>All</Option>
              <Option value={EShipmentMethod.DELIVERY}>Delivery</Option>
              <Option value={EShipmentMethod.PICKUP}>Pickup</Option>
            </Select>
          </Col>
          <Col>
            <h4 style={labelStyle}>Payment Status</h4>
            <Select
              size='large'
              allowClear
              style={{ width: 170 }}
              onChange={handleOrderStatusChange}
              placeholder='Payment Status'
            >
              <Option value='all'>All</Option>
              <Option value={ETransactionStatus.WAITING_PAYMENT}>
                Waiting Payment
              </Option>
              <Option value={ETransactionStatus.PAID}>Paid</Option>
              <Option value={ETransactionStatus.COD}>COD</Option>
              <Option value={ETransactionStatus.CREDITUR_ACCOUNT}>
                Credit Account
              </Option>
              <Option value={ETransactionStatus.CANCELED}>Canceled</Option>
            </Select>
          </Col>
          <Col>
            <h4 style={labelStyle}>Order Status</h4>
            <Select
              size='large'
              allowClear
              style={{ width: 150 }}
              onChange={handleOrderStatusChange}
              placeholder='Order Status'
            >
              <Option value='all'>All</Option>
              <Option value={ETransactionStatus.PROCESS}>Preparing</Option>
              <Option value={ETransactionStatus.PACKAGING}>Packaging</Option>
              <Option value={ETransactionStatus.READY_FOR_SHIPMENT}>
                Packaging Done
              </Option>
              <Option value={ETransactionStatus.COMPLETED}>Done</Option>
            </Select>
          </Col>
          <Col>
            <h4 style={labelStyle}>Search</h4>
            <Input
              size='large'
              placeholder='Search by customer name, order id'
              style={{ width: 400 }}
              suffix={<SearchOutlined />}
              allowClear
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col>
            <h4 style={labelStyle}>Order Schedule Date</h4>
            <RangePicker
              size='large'
              picker='date'
              style={{ width: '100%' }}
              onChange={(_, formatString: [string, string]) =>
                handleClickDate(formatString[0], formatString[1])
              }
              format={dateFormat}
            />
          </Col>
        </Row>
      </ContainerFilter>
      <Modal
        title={<Text strong>Export Orders</Text>}
        visible={isModalVisible}
        footer={null}
        onCancel={() => handleCloseModal()}
      >
        <Col>
          <div>
            <Text style={{ fontSize: 16, color: '#556575' }}>
              Choose order status
            </Text>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <Checkbox
                checked={isPaid}
                onChange={() => handleFilter('paid', !isPaid)}
              >
                <Text style={{ color: 'black' }}>Paid</Text>
              </Checkbox>
              <Checkbox
                checked={isCredit}
                onChange={() => handleFilter('credit', !isCredit)}
              >
                <Text style={{ color: 'black' }}>Credit Account</Text>
              </Checkbox>
              <Checkbox
                checked={isCOD}
                onChange={() => handleFilter('cod', !isCOD)}
              >
                <Text style={{ color: 'black' }}>COD</Text>
              </Checkbox>
            </div>
          </div>
          <div style={{ display: isAllowed ? 'none' : 'block' }}>
            <Text style={{ color: 'red' }}>
              Choose at least one filter to proceed.
            </Text>
          </div>
          <div style={{ marginTop: 20 }}>
            <Text style={{ fontSize: 16, color: '#556575' }}>
              Order Schedule Date
            </Text>
            <RangePicker
              size='large'
              picker='date'
              style={{ width: '100%', marginTop: 8 }}
              onChange={(_, formatString: [string, string]) =>
                handleClickDateModal(formatString[0], formatString[1])
              }
              format={dateFormat}
            />
          </div>
          {startDate && endDate && isAllowed && (
            <Alert
              style={{ marginTop: 20 }}
              type='info'
              showIcon
              message={`Total orders: ${lengthData}`}
            />
          )}
          <div style={{ display: 'flex', marginTop: 20 }}>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <Button
                style={{ marginRight: 15 }}
                onClick={() => handleCloseModal()}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: isBtnExportDisable ? '#ddd' : '#FF4A50',
                  fontSize: '14px',
                  color: 'white',
                  border: 'unset',
                }}
                color='primary'
                type='primary'
                loading={loadingExport || loadingDataExport}
                onClick={() => handleClickPDF(dataForExport)}
                disabled={isBtnExportDisable || loadingDataExport}
              >
                Export Order
              </Button>
            </div>
          </div>
        </Col>
      </Modal>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 1500 }}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </AppLayout>
  );
};

export default Transaction;
