import {
  Checkbox,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import useFetchList from '../../hooks/useFetchList';
import { generateFormRules } from '../../helpers/formRules';
import {
  DeliveryCoverageProperties,
  initialDeliveryCoverage,
  PostcodeProperties,
  StateProperties,
} from '../../types/delivery-coverage.type';
import { generateQueryString } from '../../helpers/generateQueryString';
import _ from 'lodash';
import styled from 'styled-components';
import NumericInput from '../../components/NumericInput';

interface IParams {
  deliveryCoverageId: string;
}

interface ResponseDeliveryCoverageProps extends BaseResponseProps {
  payload: DeliveryCoverageProperties;
}

interface ResponseAllState extends BaseResponseProps {
  payload: StateProperties[];
}

interface ResponseAllPostcode extends BaseResponseProps {
  payload: PostcodeProperties[];
}

interface TmpPostcode {
  value: string;
  label: string;
  checked: boolean;
  deliveryFee: string;
}

const DeliveryCoverageEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { deliveryCoverageId } = useParams<IParams>();
  const formRef =
    React.useRef<
      FormInstance<Omit<DeliveryCoverageProperties, 'createdAt' | 'updatedAt'>>
    >(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [deliveryCoverage, setDeliveryCoverage] =
    React.useState<DeliveryCoverageProperties>({
      ...initialDeliveryCoverage,
    });
  const [notAllowSave, setNotAllowSave] = React.useState<boolean>(false);
  const [isModalAddPostcode, setIsModalAddPostcode] =
    React.useState<boolean>(false);

  const [isLoadingAllState, setIsLoadingAllState] = useState<boolean>(false);
  const [allStates, setAllStates] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedState, setSelectedState] = useState<StateProperties>();

  const [isLoadingPostcode, setIsLoadingPostcode] = useState<boolean>(false);
  const [allPostcodesRaw, setAllPostcodesRaw] = useState<PostcodeProperties[]>(
    []
  );
  const [selectedPostcodes, setSelectedPostcodes] = useState<
    PostcodeProperties[]
  >([]);
  const [allPostcodesTmp, setAllPostcodesTmp] = useState<TmpPostcode[]>([]);
  const [selectedPostcodesTmp, setSelectedPostcodesTmp] = useState<string>('');
  const [deliFee, setDeliFee] = useState<number | string>(0);
  const [selectAllSelectedPostcode, setSelectAllSelectedPostcode] =
    useState<boolean>(false);
  const [sameDeliveryFee, setSameDeliveryFee] = useState<boolean>(false);
  const [globalDeliveryFee, setGlobalDeliveryFee] = useState<string>('');

  const createDeliveryCoverage = async (
    props: Omit<DeliveryCoverageProperties, 'createdAt' | 'updatedAt' | 'id'>
  ) => {
    setIsLoadingAction(true);
    try {
      // const postalCode: Record<string, any> = {};
      // const code = selectedPostcodes[0].postcode;
      // postalCode[code] = selectedPostcodes[0].fee;

      const dataPostcode = selectedPostcodes.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr['postcode']]: curr['fee'],
        };
      }, {});

      await httpRequest.post<ResponseDeliveryCoverageProps>(
        '/delivery-coverages',
        {
          coverageName: props.coverageName,
          postalCode: dataPostcode,
          isPublished: props.isPublished,
        }
      );

      message.success('Success create ' + props.coverageName);

      history.push('/delivery-coverage');
    } catch (error) {
      console.error('failed create delivery coverage ', error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const updateDeliveryCoverage = async (
    props: Omit<DeliveryCoverageProperties, 'createdAt' | 'updatedAt'>
  ) => {
    try {
      setIsLoadingAction(true);
      const dataPostcode = selectedPostcodes.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr['postcode']]: curr['fee'],
        };
      }, {});

      await httpRequest.patch('/delivery-coverages/' + deliveryCoverageId, {
        coverageName: props.coverageName,
        postalCode: dataPostcode,
        isPublished: props.isPublished,
      });

      message.success('Success update ' + props.coverageName + ' data');

      history.push('/delivery-coverage');
    } catch (error) {
      console.error('failed update delivery coverage', error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<DeliveryCoverageProperties, 'createdAt' | 'updatedAt'>
  ) => {
    if (deliveryCoverageId) {
      updateDeliveryCoverage(values);
    } else {
      createDeliveryCoverage(values);
    }
  };

  React.useEffect(() => {
    if (deliveryCoverageId) {
      const fetchDeliveryCoverage = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseDeliveryCoverageProps>(
            '/delivery-coverages/' + deliveryCoverageId
          );
          setDeliveryCoverage(res.data.payload);

          const activePostcodes = Object.keys(res.data.payload.postalCode).map(
            (key) => ({
              postcode: key,
              state: '',
              suburbName: '',
              fee: res.data.payload.postalCode[key],
            })
          );

          setSelectedPostcodes(activePostcodes);

          const bcDetails = [
            {
              field: 'discountId',
              value: deliveryCoverageId,
              label: res.data.payload.coverageName,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          console.error('failed get data ');
        } finally {
          setIsLoading(false);
        }
      };

      fetchDeliveryCoverage();
    }
  }, [deliveryCoverageId]);

  async function handleOpenModalAddPostcode() {
    setIsModalAddPostcode(true);
    setDeliFee(0);
    // setSelectedState(undefined);
    await getAllState();
  }

  function handleConfirmAddPostcode() {
    setIsModalAddPostcode(false);
    // setSelectedPostcodesTmp("");
    // setAllPostcodesTmp([]);
    // setAllStates([]);
    // setSelectedState(undefined);
    // setGlobalDeliveryFee("");
    // setSelectAllSelectedPostcode(false);
    setSameDeliveryFee(false);

    const newSelectedPostcode = [];

    const found = allPostcodesRaw.find(
      (itemRaw) => itemRaw.postcode === selectedPostcodesTmp
    );

    if (found) {
      newSelectedPostcode.push({
        ...found,
        fee: deliFee,
      });
    }

    // setSelectedPostcodes([...selectedPostcodes, ...newSelectedPostcode]);
    // setSelectedPostcodes([...newSelectedPostcode]);
    // setDeliFee(0);
    setSelectedPostcodes([
      ...allPostcodesTmp
        .filter((item) => item.checked)
        .map((item) => ({
          postcode: item.value,
          state: '',
          suburbName: '',
          fee: item.deliveryFee,
        })),
    ]);
  }

  function removeSinglePostcode(postcode: string) {
    const postcodeFiltered = selectedPostcodes.filter(
      (item) => item.postcode !== postcode
    );
    setSelectedPostcodes(postcodeFiltered);
    // setSelectedPostcodesTmp(postcodeFiltered.map((item) => item.postcode));
  }

  async function handleSelectState(stateId: string) {
    const found = allStates.find((item) => item.value === stateId);
    setSelectedPostcodesTmp('');
    setAllPostcodesTmp([]);
    if (found) {
      setSelectedState({
        stateId: found.value,
        stateName: found.label,
      });

      await getPostcodes(found.value);
    }
  }

  async function getAllState() {
    setIsLoadingAllState(true);
    try {
      const res = await httpRequest.get<ResponseAllState>(
        'postcodes/get-all-state'
      );
      if (res && res.data.payload) {
        const newAllState = res.data.payload.map((item) => ({
          value: item.stateId,
          label: item.stateName,
        }));
        setAllStates(newAllState);
      }
    } catch (error) {
      console.error('failed get all state ', error);
    } finally {
      setIsLoadingAllState(false);
    }
  }

  async function getPostcodes(stateId: string) {
    setIsLoadingPostcode(true);
    try {
      const res = await httpRequest.get<ResponseAllPostcode>(
        'postcodes' + generateQueryString({ stateId })
      );
      if (res && res.data.payload) {
        setAllPostcodesRaw(res.data.payload);
        const newPostcodes = res.data.payload
          .filter((item) => {
            if (selectedPostcodes.length > 0) {
              const found = selectedPostcodes.find(
                (i) => i.postcode === item.postcode
              );

              if (!found) {
                return item;
              }
            } else {
              return item;
            }
          })
          .map((item) => ({
            value: item.postcode,
            label: item.postcode,
            checked: false,
            deliveryFee: '',
          }));

        setAllPostcodesTmp(_.uniqBy(newPostcodes, 'value'));
      }
    } catch (error) {
      console.error('failed get postcodes ', error);
    } finally {
      setIsLoadingPostcode(false);
    }
  }

  const handleCheckPostcode = (checked: boolean, data: TmpPostcode) => {
    const newPostcodes = allPostcodesTmp
      .map((item) =>
        item.value === data.value
          ? {
              ...item,
              checked,
            }
          : { ...item }
      )
      .sort((a, b) => Number(b.checked) - Number(a.checked));
    setAllPostcodesTmp(newPostcodes);
  };

  const handleChangeDeliveryFee = (value: string, data: TmpPostcode) => {
    const newPostcodes = allPostcodesTmp.map((item) =>
      item.value === data.value ? { ...item, deliveryFee: value } : { ...item }
    );
    setAllPostcodesTmp(newPostcodes);
  };

  const handleSelectAllSelectedPostcode = (checked: boolean) => {
    setAllPostcodesTmp(allPostcodesTmp.map((item) => ({ ...item, checked })));
    setSelectAllSelectedPostcode(checked);
  };

  const handleSameDeliveryFee = (checked: boolean) => {
    setSameDeliveryFee(checked);
  };

  const handleChangeGlobalDeliveryFee = (e: string) => {
    setGlobalDeliveryFee(e);
    setAllPostcodesTmp(
      allPostcodesTmp
        .filter((item) =>
          item.checked ? { ...item, deliveryFee: e } : { ...item }
        )
        .map((item) => item)
    );
  };

  useEffect(() => {
    if (sameDeliveryFee) {
      const newPostcodes = allPostcodesTmp.map((item) =>
        item.checked ? { ...item, deliveryFee: globalDeliveryFee } : { ...item }
      );
      setAllPostcodesTmp(newPostcodes);
    }
  }, [sameDeliveryFee, globalDeliveryFee]);

  const checkDisabledSubmit = () => {
    const found = allPostcodesTmp.find(
      (item) => item.checked && !item.deliveryFee
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(deliveryCoverageId ? 'Edit' : 'Add') + ' Delivery Coverage'}
        subtitle='Manage your delivery coverage'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              disabled={notAllowSave}
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='deliveryCoverageForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={
            deliveryCoverageId ? deliveryCoverage : initialDeliveryCoverage
          }
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Delivery Coverage Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'Status In-Active will make this discount unable to be used by customers',
              },
            ]}
          >
            <Form.Item
              label='Name'
              name='coverageName'
              rules={generateFormRules('coverageName', ['required'])}
            >
              <Input placeholder='Enter coverage name' />
            </Form.Item>
            <Form.Item label='Postcode'>
              <div>
                {selectedPostcodes.length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom: '1px solid #ddd',
                      padding: '15px 0',
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ textAlign: 'center', opacity: 0.5 }}>
                      Postcode empty
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      borderBottom: '1px solid #ddd',
                      padding: '15px 0',
                      marginBottom: 10,
                    }}
                  >
                    {/* <Tag
                      closable
                      key={selectedPostcodes[0].postcode}
                      onClose={() =>
                        removeSinglePostcode(selectedPostcodes[0].postcode)
                      }
                    >
                      {`${selectedPostcodes[0].postcode}:${selectedPostcodes[0].fee}`}
                    </Tag> */}
                    {selectedPostcodes.map((item, idx) => (
                      <Tag
                        style={{ marginBottom: 10 }}
                        closable
                        key={idx}
                        onClose={() => removeSinglePostcode(item.postcode)}
                      >
                        {`${item.postcode} $(${item.fee})`}
                      </Tag>
                    ))}
                  </div>
                )}

                <AppButton
                  // disabled={!categoryInput}
                  style={{ marginTop: 15 }}
                  type='primary'
                  onClick={handleOpenModalAddPostcode}
                >
                  {selectedPostcodes.length > 0 ? 'Edit' : 'Add'} Postcode
                </AppButton>
              </div>
            </Form.Item>
            <Form.Item
              label='Status'
              name='isPublished'
              rules={[
                {
                  required: true,
                  message: 'The Status is required.',
                },
              ]}
            >
              <Radio.Group value={deliveryCoverage.isPublished}>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>

      {isModalAddPostcode && (
        <Modal
          title='Add Postcode'
          visible={isModalAddPostcode}
          onOk={handleConfirmAddPostcode}
          onCancel={() => {
            setIsModalAddPostcode(false);
          }}
          okText={`Add Postcode`}
          okButtonProps={{ type: 'primary', disabled: checkDisabledSubmit() }}
          width={800}
        >
          <Form layout='vertical'>
            <Form.Item label='State'>
              <Select
                loading={isLoadingAllState}
                defaultValue={selectedState ? selectedState.stateId : ''}
                style={{ width: '100%' }}
                onChange={(e) => handleSelectState(e)}
                placeholder='Choose state'
                options={allStates}
              />
            </Form.Item>
            <Form.Item label='Postcode'>
              {/* <Select
                loading={isLoadingPostcode}
                disabled={allPostcodesTmp.length === 0}
                // mode="multiple"
                style={{ width: "100%" }}
                value={selectedPostcodesTmp}
                options={allPostcodesTmp}
                placeholder='Choose postcode'
                maxTagCount='responsive'
                onChange={(newValue: string) => {
                  setSelectedPostcodesTmp(newValue);
                }}
              /> */}
              <ContainerSelect>
                {allPostcodesTmp.length > 0 ? (
                  <>
                    <PostcodeItem>
                      <Checkbox
                        checked={selectAllSelectedPostcode}
                        onChange={(e) =>
                          handleSelectAllSelectedPostcode(e.target.checked)
                        }
                      />
                      <div style={{ opacity: 0.5, fontSize: 12 }}>
                        Select All
                      </div>
                      <Checkbox
                        checked={sameDeliveryFee}
                        onChange={(e) =>
                          handleSameDeliveryFee(e.target.checked)
                        }
                      />
                      <div style={{ opacity: 0.5, fontSize: 12 }}>
                        Set All Delivery Fee same with
                      </div>
                      <NumericInput
                        prefix='$'
                        placeholder='Delivery fee'
                        value={globalDeliveryFee}
                        onChange={(e) => handleChangeGlobalDeliveryFee(e)}
                        style={{ width: '50%' }}
                      />
                    </PostcodeItem>
                    {allPostcodesTmp.map((item, idx) => (
                      <PostcodeItem key={idx}>
                        <Checkbox
                          checked={item.checked}
                          onChange={(e) =>
                            handleCheckPostcode(e.target.checked, item)
                          }
                        />
                        <div>{item.value}</div>
                        {item.checked && (
                          <NumericInput
                            prefix='$'
                            placeholder='Delivery fee'
                            value={item.deliveryFee}
                            onChange={(e) => handleChangeDeliveryFee(e, item)}
                            style={{ width: '100%' }}
                          />
                        )}
                      </PostcodeItem>
                    ))}
                  </>
                ) : (
                  <DataEmpty>Data Empty, Please select state first</DataEmpty>
                )}
              </ContainerSelect>
            </Form.Item>
            {/* <Form.Item
              label='Delivery Fee'
              name='deliFee'
              rules={[
                {
                  required: true,
                },
                {
                  type: "number",
                  min: 0,
                  max: 100,
                },
              ]}
              style={{ width: "100%" }}
            >
              <InputNumber
                value={deliFee || 0}
                defaultValue={deliFee || 0}
                style={{ width: "100%" }}
                formatter={(value) => `$${value}`}
                parser={(value) => value!.replace("$", "")}
                onChange={(value: number | string) => {
                  setDeliFee(value);
                }}
                // disabled={selectedPostcodesTmp ? false : true}
              />
            </Form.Item> */}
          </Form>
        </Modal>
      )}
    </AppLayout>
  );
};

export default DeliveryCoverageEdit;

const ContainerSelect = styled.div`
  height: 400px;
  overflow-y: auto;
`;

const PostcodeItem = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DataEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0.5;
`;
