import React from "react";
import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import { MoreOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Pagination,
  Table,
  Switch,
  Modal,
  message,
  Input,
  Select,
  Dropdown,
  Menu,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import styled from "styled-components";
import { CustomerProps, initialCustomer } from "../../types/customer.type";
import useFetchList from "../../hooks/useFetchList";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CustomerProps, "createdAt" | "updatedAt">;
}

const Customer = () => {
  const history = useHistory();

  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<CustomerProps>(initialCustomer);
  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<CustomerProps>({
    endpoint: "customers",
  });

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdate(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.customerId === tmpData.customerId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });

      const newStatus = tmpData.status;

      const res = await httpRequest.patch<ResponseProps>(
        "/customers/" + tmpData.customerId,
        {
          customerId: tmpData.customerId,
          status: !newStatus,
        }
      );

      newData = data.map((item) => {
        if (item.customerId === res.data.payload.customerId) {
          return {
            ...item,
            status: res.data.payload.status,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.fullName + " status.");
      setIsModalVisible(false);
      setTmpData(initialCustomer);
      setIsLoadingUpdate(false);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialCustomer);
      setIsLoadingUpdate(false);
    }
  };

  const handleClickDetail = (e: CustomerProps) => {
    history.push(`/customer/${e.customerId}`);
  };

  const { Option } = Select;

  const handleChangeStatus = (status: string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, status: "" }));
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: string, record: CustomerProps) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: any, record: CustomerProps) => (
        <>
          <Switch
            loading={record.statusLoading}
            checked={status}
            onChange={() => {
              setIsModalVisible(true);
              setTmpData(record);
            }}
          />
        </>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: CustomerProps) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const handleClick = (key: string, record: CustomerProps) => {
    if (key === "edit") {
      history.push({
        pathname: `/customer/${record.customerId}/edit`,
      });
    } else if (key === "change-password") {
      history.push({
        pathname: `/customer/${record.customerId}/change-password`,
      });
    } else if (key === "detail") {
      history.push(`/customer/${record.customerId}`);
    }
  };

  const menu = (record: CustomerProps) => (
    <Menu onClick={(e) => handleClick(e.key, record)}>
      <Menu.Item key='edit'>Edit Customer</Menu.Item>
      <Menu.Item key='detail'>Detail Customer</Menu.Item>
      <Menu.Item key='change-password'>Change Password</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<UserOutlined />}
        title='Customers'
        subtitle='Manage your customers'
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Name, Phone number or Email'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='1'>Active</Option>
          <Option value='0'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title='Update status confirmation'
        visible={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialCustomer);
        }}
        okText='Yes'
        confirmLoading={isLoadingUpdate}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.fullName}"</b> status to{" "}
          <b>"{tmpData.status ? "Inactive" : "Active"}"</b>?.{" "}
          {tmpData.status
            ? 'If this user status is changed to "Inactive", then this user cannot login to the Spoonful Kitchen CMS'
            : 'If this user status is changed to "Active", then this user can Sign In to Spoonful Kitchen'}
        </p>
      </Modal>
    </AppLayout>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default Customer;
