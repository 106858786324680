import React, { useState } from "react";
import AppLayout from "../../layout/AppLayout";
import { useHistory } from "react-router-dom";
import { MoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Space, Table, Switch, Dropdown, Menu, Image, Modal } from "antd";
import AppButton from "../../../components/AppButton";
import HeaderSection from "../../../components/HeaderSection";
import { httpRequest } from "../../../helpers/api";
import { formatDate } from "../../../helpers/constant";
import { generateQueryString } from "../../../helpers/generateQueryString";
import { BaseResponseProps, DefaultQuery } from "../../../types/config.type";
import { useDebounce } from "use-debounce";
import {
  FetchAllBannerResponse,
  BannerProps,
} from "../../../types/banner.type";
import { ColumnsType } from "antd/lib/table";

interface PaginationProps {
  total: number;
  perPage: number;
  page: number;
  prev: string | undefined;
  next: string | undefined;
}

interface QueryProps extends DefaultQuery {}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<BannerProps, "createdAt" | "updatedAt">;
}

const Banner = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<BannerProps>>([]);
  const [search, setSearch] = React.useState<string>();
  const [pagination, setPagination] = React.useState<PaginationProps>({
    total: 0,
    perPage: 25,
    page: 1,
    next: "",
    prev: "",
  });
  const [query, setQuery] = React.useState<QueryProps>({
    offset: 0,
    limit: 25,
  });

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [willBeDeleted, setWillBeDeleted] = useState<BannerProps>();
  const [tmpDataUpdateActiveBanner, setTmpDataUpdateActiveBanner] =
    useState<BannerProps>();

  const [value] = useDebounce(search, 500);

  React.useEffect(() => {
    setQuery((e: QueryProps) => {
      return { ...e, search: value as string };
    });
  }, [value]);

  const fetchList = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<FetchAllBannerResponse>(
        `/banners${generateQueryString(query)}`
      );

      const newRes = res.data.payload.results.map((howItWork) => {
        return {
          ...howItWork,
          key: howItWork.bannerId,
          createdAt: formatDate(howItWork.createdAt),
        };
      });

      setPagination((oldVal) => {
        return {
          ...oldVal,
          prev: res.data.payload.prev,
          next: res.data.payload.next,
          total: res.data.payload.count,
        };
      });

      setData(newRes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [query]);

  const handleCreateUser = () => {
    history.push("/banner/add");
  };

  const handleClickDetail = (e: BannerProps) => {
    history.push(`/banner/${e.bannerId}`);
  };

  const handleClickEdit = (e: BannerProps) => {
    history.push(`/banner/${e.bannerId}/edit`);
  };

  const handleClickUpdateActiveBanner = async () => {
    if (tmpDataUpdateActiveBanner) {
      setIsLoadingAction(true);
      await httpRequest.patch(
        "/banners/" + tmpDataUpdateActiveBanner.bannerId,
        {
          isActive: tmpDataUpdateActiveBanner.isActive ? "0" : "1",
        }
      );
      setTmpDataUpdateActiveBanner(undefined);
      setIsLoadingAction(false);

      fetchList();
    }
  };

  const handleClickDelete = async () => {
    if (willBeDeleted) {
      setIsLoadingAction(true);
      await httpRequest.delete("/banners/" + willBeDeleted.bannerId);
      setWillBeDeleted(undefined);
      setIsLoadingAction(false);

      fetchList();
    }
  };

  const columns: ColumnsType<BannerProps> = [
    {
      title: "Image",
      dataIndex: "bannerImg",
      key: "bannerImg",
      width: 240,
      render: (url: string, record: BannerProps) => {
        return (
          <Image
            width={240}
            height={90}
            src={url}
            style={{ objectFit: "contain" }}
            placeholder={
              <Image
                preview={false}
                src='/images/blur-image.jpeg'
                width={120}
                height={100}
              />
            }
          />
        );
      },
    },
    // {
    //   title: "Title",
    //   key: "title",
    //   dataIndex: "title",
    //   width: 200,
    //   render: (text: string, record: BannerProps) => {
    //     return (
    //       <div className='table-link' onClick={() => handleClickDetail(record)}>
    //         {text}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Subtitle",
    //   key: "subtitle",
    //   dataIndex: "subtitle",
    //   render: (text: string, record: BannerProps) => {
    //     return <div>{text}</div>;
    //   },
    // },
    {
      title: "Active Banner",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive: any, record: BannerProps) => (
        <>
          <Switch
            loading={isLoadingAction}
            checked={isActive}
            onChange={() => {
              setTmpDataUpdateActiveBanner(record);
            }}
          />
        </>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (_: any, record: BannerProps) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: BannerProps) => (
    <Menu
      onClick={({ key }) => {
        if (key === "edit") {
          handleClickEdit(record);
        } else if (key === "delete") {
          setWillBeDeleted(record);
        }
      }}
    >
      <Menu.Item key='edit'>Edit</Menu.Item>
      <Menu.Item key='delete'>Delete</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<SettingOutlined />}
        title='Header Banner'
        subtitle='Manage your Header Banner data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleCreateUser}>
              Add Banner
            </AppButton>
          </Space>
        }
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        rowKey='index'
      />

      <Modal
        title='Confirmation'
        visible={!!tmpDataUpdateActiveBanner}
        onOk={handleClickUpdateActiveBanner}
        onCancel={() => {
          setTmpDataUpdateActiveBanner(undefined);
        }}
        okText='Yes'
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change banner status to{" "}
          <b>{tmpDataUpdateActiveBanner?.isActive ? "INACTIVE" : "ACTIVE"}</b>?
        </p>
      </Modal>

      <Modal
        title='Confirmation'
        visible={!!willBeDeleted}
        onOk={handleClickDelete}
        onCancel={() => {
          setWillBeDeleted(undefined);
        }}
        okText='Yes'
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: "primary" }}
      >
        <p>Are you sure want to delete this data?</p>
      </Modal>
    </AppLayout>
  );
};

export default Banner;
