import { Breadcrumb } from 'antd';
import React from "react";
import { useHistory } from "react-router-dom";
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import useDetailBreadcrumbs from '../hooks/useDetailBreadcrumbs';

const Breadcrumbs: React.FC = () => {
  const {breadcrumbDetails} = useDetailBreadcrumbs()
  const breadcrumbs = useReactRouterBreadcrumbs();
  const history = useHistory()

  const renderLabel = (label: any) => {
    const labels = label.props.children.split('-')
    if (labels.length > 0) {
      const findInBreadcrumbDetails = breadcrumbDetails.find(item => item.value === label.props.children)

      if (findInBreadcrumbDetails) {
        return findInBreadcrumbDetails.label
      } else {
        const newLabel = labels.join(' ').toString()
        return newLabel.length > 20 ? newLabel.substr(0, 20) + '...' : newLabel
      }
    } else {
      return label.props.children
    }
  }

  const handleBreadcrumb = (e: any, link: string, locationState: any) => {
    e.preventDefault()

    history.push({
      pathname: link,
      state: {
        ...locationState
      }
    })
  }

  return (
    <Breadcrumb separator={<img src="/images/breadcrumb-separator.svg" alt="breadcrumb-separator" width="5" />}>
      {breadcrumbs.map(({ breadcrumb, match, location }, index) => (
        <Breadcrumb.Item key={match.url}>
          <div key={index} onClick={(e) => handleBreadcrumb(e, match.url, location.state)}>{renderLabel(breadcrumb)}</div>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
