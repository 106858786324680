import { Route } from 'react-router';

export interface IProps {
  exact?: boolean;
  path: string;
  component?: React.ComponentType<any>;
  restricted?: boolean;
};

const PublicRoute: React.FC<IProps> = ({ children, restricted, ...rest }) => {
  return (
    // <Route
    //   {...rest}
    //   render={() => (isLoggedIn && restricted ? <Redirect to="/dashboard" /> : children)}
    // />
    <Route
      {...rest}
      render={() => children}
    />
  )
};

export default PublicRoute;