import { UserOutlined } from '@ant-design/icons'
import AppButton from '../../components/AppButton'
import HeaderSection from '../../components/HeaderSection'
import AppLayout from '../layout/AppLayout'
import { useHistory } from 'react-router-dom';
import { Divider, Form, FormInstance, Input, Radio, Select, Space } from 'antd';
import React from 'react';
import AppCard from '../../components/AppCard';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { IUser } from '../../types/user.type';
import useAuth from '../../hooks/useAuth';
import { showRoleName } from '../../helpers/auth';
import FormUploadImage from '../../components/FormUploadImage';
import { capitalizeFirstLetter } from '../../helpers/text';

const EditProfile: React.FC = () => {
  const history = useHistory()
  const formRef =
    React.useRef<FormInstance<Omit<IUser, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const { isLoading, user, saveProfile } = useAuth()
  const [image, setImage] = React.useState<File>()
  const [imageUrl, setImageUrl] = React.useState(user.imageUrl);

  const handleSave = async (values: IUser) => {
    await saveProfile({ ...values, image })
    history.push('/profile')
  }
  const handleCancel = () => {
    history.push('/profile')
  }

  return (
    <AppLayout>
      <HeaderSection
        icon={<UserOutlined />}
        title="Edit Profile"
        subtitle="Manage your profile"
        rightAction={
          <Space>
            <AppButton onClick={handleCancel}>Cancel</AppButton>
            <AppButton loading={isLoading} type="primary" onClick={() => formRef?.current?.submit()}>Save</AppButton>
          </Space>
        }
      />

      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="profileForm"
          layout="vertical"
          onFinish={handleSave}
          initialValues={{
            name: user.name,
            email: user.email,
            phoneNumber: user.phoneNumber,
            role: user.role,
            status: user.status,
          }}
          autoComplete="off"
        >

          <SectionContent
            groupTitle="Profil Data"
            helpers={[
              {
                title: '',
                content: '',
              }
            ]}
          >
            <FormUploadImage
              imageUrl={imageUrl || ''}
              onChange={(file) => setImage(file)}
              onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
            />

            <Form.Item
              label="Name"
              name="name"
              rules={generateFormRules('Name', ['required', 'letter-and-space'])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={generateFormRules('Email', ['required', 'email'])}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phoneNumber"
              rules={generateFormRules('Phone number', ['required', 'phoneNumber'])}
            >
              <Input />
            </Form.Item>
          </SectionContent>

          <Divider />

          {
            user.role === 'admin' &&
            <SectionContent
              groupTitle="Profile Setting"
              helpers={[
                {
                  title: 'Information',
                  content: 'Role limit the user access to certain areas; Status Disable will make the user unable to logging in',
                }
              ]}
            >
              <Form.Item
                label="Role"
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'The Role is required.',
                  },
                ]}
              >
                {showRoleName(user.role)}
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
              >
                {capitalizeFirstLetter(user.status)}
              </Form.Item>
            </SectionContent>
          }
        </Form>
      </AppCard>
    </AppLayout>
  )
}

export default EditProfile