import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import React from "react";
import { GOOGLE_MAP_API_KEY, MAP_DEFAULT_CENTER } from "../helpers/map";
// import useGeolocation from 'react-hook-geolocation';

type Props = {
  isMain?: boolean;
  width?: number | string;
  height?: number;
  defaultZoom?: number;
  center?: { lat: number; lng: number };
  onChange: (coords: { lat: number; lng: number }) => void;
};
const CustomMap = withScriptjs(
  withGoogleMap((props: Props) => {
    let refMap = React.useRef<any>();

    // const geolocation = useGeolocation({
    //     enableHighAccuracy: true,
    //     maximumAge: 15000,
    //     timeout: 12000
    // })

    const [center, setCenter] = React.useState<
      { lat: number; lng: number; isOldLocation?: boolean } | undefined
    >(
      props.center && props.center.lat && props.center.lng
        ? { ...props.center, isOldLocation: true }
        : undefined
    );

    const handleBoundsChanged = () => {
      const mapCenter = refMap?.current?.getCenter(); //get map center
      setCenter(mapCenter);
      console.info("mapCenter", mapCenter.lat());
      props.onChange({
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      });
    };

    return (
      <GoogleMap
        ref={refMap}
        defaultZoom={props.defaultZoom || 17}
        defaultCenter={center ? { ...center } : { ...MAP_DEFAULT_CENTER }}
        onBoundsChanged={handleBoundsChanged}
      >
        {[center].map((item, idx) => (
          <Marker
            key={idx}
            position={item}
            icon={{
              url: item?.isOldLocation
                ? "/images/marker/marker-grey.png"
                : props.isMain
                ? "/images/marker/main-kitchen.png"
                : "/images/marker/default.png",
              scaledSize: item?.isOldLocation
                ? new (window as any).google.maps.Size(30, 48)
                : new (window as any).google.maps.Size(42, 65),
            }}
          />
        ))}
      </GoogleMap>
    );
  })
);

export default function AppMapPickLocation(props: Props) {
  const height = props.height || 300;
  const width = props.width || "100%";

  return (
    <CustomMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height, width }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  );
}
