import { BaseResponseProps } from "./config.type";

export interface AvailabilityProps {
  availabilitypickupId?: string;
  locationId?: string;
  startAt: string;
  endAt: string;
  updatedAt?: string;
  createdAt?: string;
}

// export interface GoogleMapJsonResponseProps {
//   address_components?: any[];
//   formatted_address?: string;
//   geometry?: {
//     bounds?: {
//       northeast: { lat: string; lng: string };
//       southwest: { lat: string; lng: string };
//     };
//     location?: {
//       lat: string;
//       lng: string;
//     };
//     location_type?: string;
//     viewport?: any;
//   };
//   place_id?: string;
//   types?: any[];
//   plus_code?: { global_code: string };
//   status: string;
// }

export interface LocationProps {
  locationId: string;
  locationGeoloc?: Geoloc;
  locationName: string;
  locationCode: string;
  locationAddress: string;
  locationContact: string;
  isPickup: boolean;
  locationType: ELocationType;
  isPublished: boolean;
  availabilityPickups: AvailabilityProps[];
  timeframe: string;
  suburb: string;
  city: string;
  state: string;
  postalCode: string;
  updatedAt?: string;
  createdAt?: string;
  statusLoading?: boolean;
}

export type Geoloc = {
  lat: number;
  lng: number;
};

export type ELocationType = "main_kitchen" | "outlet";

export interface FetchAllLocationResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: LocationProps[];
  };
}

export const initialAvailability: AvailabilityProps = {
  startAt: "00:00",
  endAt: "00:00",
};

export const initialLocation: LocationProps = {
  locationId: "",
  locationCode: "",
  locationGeoloc: {
    lat: 0,
    lng: 0,
  },
  locationName: "",
  locationAddress: "",
  locationContact: "",
  isPickup: true,
  isPublished: true,
  locationType: "outlet",
  availabilityPickups: [initialAvailability],
  timeframe: "",
  suburb: "",
  city: "",
  state: "",
  postalCode: "",
  updatedAt: "",
  createdAt: "",
};

// export const initialGoogleMapJsonResponse: GoogleMapJsonResponseProps = {
//   place_id: "",
//   types: [],
//   geometry: {
//     bounds: {
//       northeast: { lat: "", lng: "" },
//       southwest: { lat: "", lng: "" },
//     },
//     location: { lat: "", lng: "" },
//     location_type: "",
//     viewport: {},
//   },
//   status: "ok",
//   plus_code: { global_code: "" },
//   formatted_address: "",
//   address_components: [
//     { long_name: "", short_name: "" },
//     { long_name: "", short_name: "" },
//     { long_name: "", short_name: "" },
//     { long_name: "", short_name: "" },
//   ],
// };
