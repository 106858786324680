export type EAdminRole = 
  | 'admin' 
  | 'admin_manager'
  | 'admin_employee'
  | 'admin_chef'

export type EAdminStatus = 
  | 'active' 
  | 'inactive'

export interface IUser {
  adminId?: string;
  name: string;
  email: string;
  phoneNumber: string;
  role: EAdminRole;
  status: EAdminStatus;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  
  image?: File;
  imageUrl?: string;
}


export const initialUser: IUser = {
  adminId: '',
  name: '',
  email: '',
  phoneNumber: '',
  role: 'admin',
  status: 'active',
};

export type IChangePassword = {
  oldPassword: '',
  newPassword: '',
  retypePassword: ''
}