import {
  Col,
  Row,
  Image,
  Avatar,
  Divider,
  Typography,
  Dropdown,
  Menu,
  Tag,
  Modal,
  Space,
  Button,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import {
  EAmountType,
  EShipmentMethod,
  ETransactionStatus,
  initialTransactions,
  MetaShipment,
  TransactionItemProps,
  TransactionProps,
} from '../../types/transaction.type';
import moment from 'moment';
import Item from 'antd/lib/list/Item';
import styled from 'styled-components';
import { generateInitialFromName } from '../../helpers/generator';
import { DownOutlined } from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';
import DetailItem from '../../components/DetailItem';
import { formatDate, formatDate2 } from '../../helpers/constant';
import AppButton from '../../components/AppButton';
import { pdf } from '@react-pdf/renderer';
import MyDocument from './pdf';
import { saveAs } from 'file-saver';

interface IParams {
  transactionId: string;
}
interface ResponseProps extends BaseResponseProps {
  payload: Omit<TransactionProps, 'updatedAt'>;
}

const TransactionDetail: React.FC = () => {
  const { transactionId } = useParams<IParams>();
  const { token } = useAuth();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transaction, setTransaction] =
    React.useState<TransactionProps>(initialTransactions);
  const [selectedTransactionStatus, setSelectedTransactionStatus] =
    React.useState(transaction.transactionStatus);

  React.useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get<ResponseProps>(
          '/transactions/' + transactionId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedTransactionStatus(res.data.payload.transactionStatus);
        setTransaction(res.data.payload);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchOrderDetail();
  }, [transactionId, selectedTransactionStatus]);

  const getTotalPrice = (items: TransactionItemProps[]) => {
    let totalPrice = 0;
    items.forEach(
      (item) =>
        (totalPrice =
          totalPrice +
          item.productPrice *
            (item.metaProduct.menuItems && item.metaProduct.menuItems.length > 0
              ? 1
              : item.quantity))
    );
    return totalPrice;
  };

  const { Text } = Typography;

  const styleStatus = [
    {
      status: ETransactionStatus.WAITING_PAYMENT,
      style: {
        borderRadius: '20px',
        color: '#E89635',
        backgroundColor: '#FFF9EF',
        borderColor: '#E89635',
        padding: '.2rem',
        width: 'auto',
      },
    },
    {
      status: ETransactionStatus.PAID,
      style: {
        borderRadius: '20px',
        color: '#39AC6E',
        backgroundColor: '#F5FFF9',
        borderColor: '#39AC6E',
        padding: '.2rem',
      },
    },
    {
      status: ETransactionStatus.COMPLETED,
      style: {
        borderRadius: '20px',
        color: '#748494',
        backgroundColor: '#F1F3F5',
        borderColor: '#748494',
        padding: '.2rem',
        width: 'auto',
      },
    },
  ];

  const styleOrder = [
    {
      status: EShipmentMethod.PICKUP,
      style: {
        borderRadius: '20px',
        color: '#2196F3',
        backgroundColor: '#F0FFFF',
        borderColor: '#2196F3',
        padding: '.2rem',
        width: 'auto',
      },
    },
    {
      status: EShipmentMethod.DELIVERY,
      style: {
        borderRadius: '20px',
        color: '#2196F3',
        backgroundColor: '#F0FFFF',
        borderColor: '#2196F3',
        padding: '.2rem',
        width: 'auto',
      },
    },
  ];

  const handleOrderTypeChange = async (
    record: TransactionProps,
    newOrder: string
  ) => {
    setIsLoading(true);
    const res = await httpRequest.patch<ResponseProps>(
      '/transactions/' + record.transactionId + '/order-type',
      {
        method: newOrder,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setIsLoading(false);
  };

  const formattedDatePdf = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `SFK-${year}-${formattedMonth}-${formattedDay}-${
      transaction.invoiceCode || 'N/A'
    }.pdf`;
  };

  const handleClickPDF = async (data: TransactionProps[]) => {
    const blob = await pdf(<MyDocument data={data} />).toBlob();
    saveAs(blob, formattedDatePdf());
  };

  const handleTransactionStatusChange = async (
    record: TransactionProps,
    newStatus: string
  ) => {
    setIsLoading(true);
    const res = await httpRequest.patch<ResponseProps>(
      '/transactions/' + record.transactionId + '/transaction-status',
      {
        transactionStatus: newStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const newRes = await httpRequest.get<ResponseProps>(
      '/transactions/' + transactionId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSelectedTransactionStatus(newRes.data.payload.transactionStatus);
    setTransaction(newRes.data.payload);
    setIsLoading(false);
  };

  const waitingPaymentMethod = [
    ETransactionStatus.WAITING_PAYMENT,
    ETransactionStatus.CREATED,
  ];

  const paidMethod = [
    ETransactionStatus.COMPLETED,
    ETransactionStatus.PAID,
    ETransactionStatus.COD,
    ETransactionStatus.CREDITUR_ACCOUNT,
    ETransactionStatus.PROCESS,
    ETransactionStatus.PACKAGING,
    ETransactionStatus.PROCESS_DONE,
    ETransactionStatus.READY_FOR_SHIPMENT,
  ];

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Order Detail'
        subtitle={transaction.transactionCode}
        rightAction={
          <Space>
            <Button
              disabled={!transaction.invoiceCode}
              style={{
                backgroundColor: !transaction.invoiceCode ? '#ddd' : '#FF4A50',
                fontSize: '14px',
                color: 'white',
                border: 'unset',
              }}
              type='primary'
              onClick={() => {
                handleClickPDF([transaction]);
              }}
            >
              Export PDF
            </Button>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent
          groupTitle='Order Information'
          helpers={[
            {
              title: 'Information',
              content:
                "Once you change the Status into Paid or Done you can't change it back to CREATED",
            },
          ]}
        >
          <Row justify='space-between'>
            <Col span={12}>
              <DetailItem
                label='Order ID'
                content={transaction.transactionCode}
              />
            </Col>
            <Col span={12}>
              <DetailItem
                label='Order Type'
                content={<Tag color='blue'>{transaction.shipment.method}</Tag>}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailItem
                label='Payment Status'
                content={
                  <select
                    name='paymentStatus'
                    id='paymentStatus'
                    value={transaction.transactionStatus}
                    style={
                      paidMethod.includes(transaction.transactionStatus)
                        ? styleStatus[1].style
                        : waitingPaymentMethod.includes(
                            transaction.transactionStatus
                          )
                        ? styleStatus[0].style
                        : styleStatus[2].style
                    }
                    onChange={function (selectObject) {
                      const value = selectObject.target.value;
                      handleTransactionStatusChange(transaction, value);
                    }}
                  >
                    <option value={ETransactionStatus.WAITING_PAYMENT}>
                      Waiting payment
                    </option>
                    <option value={ETransactionStatus.PAID}>Paid</option>
                    <option value={ETransactionStatus.COD}>COD</option>
                    <option value={ETransactionStatus.CREDITUR_ACCOUNT}>
                      Credit Account
                    </option>
                    <option value={ETransactionStatus.CANCELED}>Cancel</option>
                    <option hidden value={ETransactionStatus.COMPLETED}>
                      Paid
                    </option>
                    <option hidden value={ETransactionStatus.PROCESS}>
                      Paid
                    </option>
                    <option
                      hidden
                      value={ETransactionStatus.READY_FOR_SHIPMENT}
                    >
                      Paid
                    </option>
                    <option hidden value={ETransactionStatus.PACKAGING}>
                      Paid
                    </option>
                  </select>
                }
              />
            </Col>
            <Col span={12}>
              <DetailItem
                label='Order Status'
                content={
                  <select
                    name='orderStatus'
                    id='orderStatus'
                    value={
                      transaction.transactionStatus ===
                        ETransactionStatus.WAITING_PAYMENT ||
                      transaction.transactionStatus ===
                        ETransactionStatus.CREATED
                        ? 'not set'
                        : transaction.transactionStatus ===
                          ETransactionStatus.CANCELED
                        ? 'canceled'
                        : transaction.transactionStatus ===
                            ETransactionStatus.PAID ||
                          transaction.transactionStatus ===
                            ETransactionStatus.COD ||
                          transaction.transactionStatus ===
                            ETransactionStatus.CREDITUR_ACCOUNT
                        ? ETransactionStatus.PROCESS
                        : transaction.transactionStatus
                    }
                    style={{
                      width: '15.625rem',
                      padding: '.5rem 5px',
                      borderColor: '#D5DCE1',
                      borderRadius: 4,
                    }}
                    onChange={function (selectObject) {
                      const value = selectObject.target.value;
                      handleTransactionStatusChange(transaction, value);
                    }}
                  >
                    <option value={ETransactionStatus.COMPLETED}>Done</option>
                    <option value={ETransactionStatus.PROCESS}>
                      Preparing
                    </option>
                    <option value={ETransactionStatus.PACKAGING}>
                      Packaging
                    </option>
                    <option value={ETransactionStatus.READY_FOR_SHIPMENT}>
                      Packaging Done
                    </option>
                    <option hidden value={'not set'}>
                      Waiting Payment
                    </option>
                    <option hidden value={'canceled'}>
                      Canceled
                    </option>
                  </select>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailItem
                label='Order Date'
                content={formatDate2(transaction.createdAt)}
              />
            </Col>
          </Row>
        </SectionContent>
        <Divider style={{ marginTop: '1%' }} />
        <SectionContent groupTitle='Customer Information' helpers={[]}>
          <Row>
            <Col span={12}>
              <DetailItem
                label='Name'
                content={
                  transaction.metaBuyer?.fullName
                    ? transaction.metaBuyer?.fullName
                    : 'Guest'
                }
              />
            </Col>
            <Col span={12}>
              <DetailItem
                label='Phone Number'
                content={
                  transaction.metaBuyer?.phoneNumber
                    ? transaction.metaBuyer?.phoneNumber
                    : 'not set'
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailItem
                label={
                  transaction.shipment?.method === 'PICKUP'
                    ? 'Pickup Address'
                    : transaction.shipment?.method === 'DELIVERY'
                    ? 'Delivery Address'
                    : 'Address'
                }
                content={
                  transaction.shipment?.meta?.deliveryAddress
                    ? transaction.shipment?.meta?.deliveryAddress +
                      ', ' +
                      transaction.shipment?.meta?.deliveryAddressSuburb +
                      ', ' +
                      transaction.shipment?.meta?.deliveryAddressState
                    : transaction.shipment?.meta?.pickupFromLocationName +
                      ' - ' +
                      transaction.shipment?.meta?.pickupFromLocationAddress
                }
              />
            </Col>
            <Col span={12}>
              <DetailItem
                label={
                  transaction.shipment.method === 'DELIVERY'
                    ? 'Delivery Date'
                    : transaction.shipment.method === 'PICKUP'
                    ? 'Pickup Date'
                    : 'Shipment Date'
                }
                content={
                  transaction.shipment.method === 'PICKUP'
                    ? formatDate2(transaction.shipment.scheduledAt)
                    : typeof transaction.shipment.meta
                        ?.deliveryScheduleTimeframe === 'object' &&
                      transaction.shipment.meta?.deliveryScheduleTimeframe
                        .selectedTimeframe
                    ? formatDate2(
                        transaction.shipment.meta?.deliveryScheduleTimeframe
                          .selectedTimeframe
                      )
                    : transaction.shipment.meta?.deliveryScheduleTimeframe &&
                      transaction.shipment.meta?.deliveryScheduleTimeframe
                        .length > 0
                    ? formatDate2(
                        transaction.shipment.meta?.deliveryScheduleTimeframe[0]
                          .selectedTimeframe
                      )
                    : '-'
                }
              />
            </Col>
          </Row>
          {transaction.shipment?.method === 'PICKUP' ? (
            <Row>
              <Col span={12}>
                <DetailItem
                  label='Pickup Timeframe'
                  content={
                    transaction.shipment?.meta?.pickupTimeStart +
                    ' - ' +
                    transaction.shipment?.meta?.pickupTimeEnd
                  }
                />
                <DetailItem
                  label='Address Note'
                  content={
                    transaction.shipment?.meta?.deliveryAddressNote || '-'
                  }
                />
              </Col>
            </Row>
          ) : transaction.shipment?.method === 'DELIVERY' ? (
            <Row>
              <Col span={12}>
                <DetailItem
                  label='Delivery Information'
                  content={
                    transaction.shipment?.meta?.deliveryInformation || '-'
                  }
                />
              </Col>
            </Row>
          ) : (
            false
          )}
        </SectionContent>
        <Divider style={{ marginTop: '1%' }} />
        <SectionContent
          groupTitle='Ordered Menu'
          helpers={[
            {
              title: 'Information',
              content:
                'Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.',
            },
          ]}
        >
          <Col>
            {transaction.items.map((item) => {
              return (
                <>
                  <Row justify='space-between' gutter={12}>
                    <Row>
                      <Text style={{ fontSize: '14px' }}>
                        {item.quantity}
                        {!item.metaProduct.menuItems ? 'x' : false}
                      </Text>
                      <Text
                        style={{
                          fontSize: '14px',
                          marginLeft: '1rem',
                        }}
                      >
                        {item.metaProduct.name}
                      </Text>
                    </Row>
                    <Col>
                      <Text
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#748494',
                        }}
                      >
                        $
                        {(
                          Number(item.metaProduct.variant?.price) *
                          (item.metaProduct.menuItems &&
                          item.metaProduct.menuItems.length > 0
                            ? 1
                            : item.quantity)
                        ).toFixed(2)}
                      </Text>
                    </Col>
                  </Row>
                  {item.metaProduct.variant?.label ? (
                    <Row>
                      <Text
                        style={{
                          fontSize: '12px',
                          marginLeft: '1.6rem',
                          color: '#748494',
                        }}
                      >
                        Variant
                      </Text>
                      <Text style={{ fontSize: '12px', marginLeft: '.5rem' }}>
                        {`${item.metaProduct.variant?.label}`}
                      </Text>
                    </Row>
                  ) : (
                    false
                  )}
                </>
              );
            })}
          </Col>
        </SectionContent>
        <Divider style={{ marginTop: '1%' }} />
        <SectionContent groupTitle='Order Summary' helpers={[]}>
          <Row justify='space-between'>
            <Col>
              <Text style={{ fontSize: '14px', color: '#748494' }}>
                Total Price
              </Text>
            </Col>
            <Col offset={9}>
              <Text
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  // color: "#748494",
                }}
              >
                ${getTotalPrice(transaction.items).toFixed(2)}
              </Text>
            </Col>
          </Row>
          {transaction.metaDiscount && transaction.metaDiscount.discountName ? (
            <Row justify='space-between'>
              <Col span={13}>
                <Text style={{ fontSize: '14px', color: '#748494' }}>
                  {transaction.metaDiscount?.discountName}
                </Text>
              </Col>
              <Col offset={6}>
                <Text
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#F23459',
                  }}
                >
                  -${transaction.metaDiscount?.discountNominal.toFixed(2)}
                </Text>
              </Col>
            </Row>
          ) : (
            false
          )}
          {transaction.deliveryFee ? (
            <Row justify='space-between'>
              <Col span={13}>
                <Text style={{ fontSize: '14px', color: '#748494' }}>
                  {`Delivery Fee`}
                </Text>
              </Col>
              <Col offset={6}>
                <Text
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                  }}
                >
                  ${transaction.deliveryFee.toFixed(2)}
                </Text>
              </Col>
            </Row>
          ) : (
            false
          )}
          <Divider style={{ color: 'red' }}></Divider>
          <Row justify='space-between'>
            <Col>
              <Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Total
              </Text>
            </Col>
            <Col>
              <Text
                style={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 'bold',
                }}
              >
                ${transaction.finalPrice.toFixed(2)}
                {/* {(
                  getTotalPrice(transaction.items) + transaction.deliveryFee
                ).toFixed(2)} */}
              </Text>
            </Col>
          </Row>
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;

export default TransactionDetail;
