import { SettingOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input, message, Space, Typography } from 'antd';
import React from 'react'
import { useLocation } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { initialAboutUs, AboutUsProps } from '../../../types/aboutUs.type';
import AppLayout from '../../layout/AppLayout';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import TextArea from 'antd/lib/input/TextArea';
import { generateFormRules } from '../../../helpers/formRules';
import FormUploadImage from '../../../components/FormUploadImage';
import AppMapPickLocation from '../../../components/AppMapPickLocation';
const { Text } = Typography;

interface ILocation {
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<AboutUsProps, 'createdAt' | 'updatedAt'>
}

const AboutUsEdit: React.FC = () => {
  const location = useLocation<ILocation>()
  const formRef = React.useRef<FormInstance<Omit<AboutUsProps, 'createdAt' | 'updatedAt'>>>(null)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false)
  const [aboutUs, setAboutUs] = React.useState<AboutUsProps>(initialAboutUs)

  const [image, setImage] = React.useState<File | undefined>()
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();
  const [locationCoords, setLocationCoords] = React.useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })

  const updateAboutUs = async (_props: Omit<AboutUsProps, 'createdAt' | 'updatedAt' | 'statusLoading'>) => {
    try {
      setIsLoadingAction(true)

      const formData = new FormData()
      formData.append('aboutusId', aboutUs.aboutusId)
      formData.append('officeTitle', aboutUs.officeTitle)
      formData.append('officeAddress', aboutUs.officeAddress)
      formData.append('contact', aboutUs.contact)
      formData.append('officeEmail', aboutUs.officeEmail)
      formData.append('instagramLink', aboutUs.instagramLink)
      formData.append('twitterLink', aboutUs.twitterLink)
      formData.append('youtubeLink', aboutUs.youtubeLink)
      formData.append('facebookLink', aboutUs.facebookLink)
      formData.append('officeCoords', JSON.stringify(locationCoords))

      if (image) {
        formData.append('logo', image)
      }

      await httpRequest.patch(
        '/aboutus',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      message.success('Success update data')
      setIsLoadingAction(false)

      fetchAboutUs();
    } catch (error) {
      setIsLoadingAction(false)
    }
  }

  const handleSubmit = async (values: Omit<AboutUsProps, 'createdAt' | 'updatedAt' | 'statusLoading'>) => {
    updateAboutUs(values)
  }

  const fetchAboutUs = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<ResponseProps>(
        '/aboutus'
      );
      console.info('res.data.payload', res.data.payload)
      setAboutUs(res.data.payload);
      setImageUrl(res.data.payload.logo);

      setLocationCoords(res.data.payload.officeCoords)

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    fetchAboutUs()
  }, [location])

  return (
    <AppLayout>
      <HeaderSection
        icon={<SettingOutlined />}
        title={'Edit About Us'}
        subtitle="Manage your About Us data"
        rightAction={
          <Space>
            {/* <AppButton onClick={() => history.goBack()}>Cancel</AppButton> */}
            <AppButton
              loading={isLoadingAction || isLoading}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="aboutusForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={aboutUs}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Spoonful Data"
            helpers={[
              {
                title: 'Information',
                content:
                  'Drag the map to Spoonful Location then save',
              },
            ]}
          >
            <FormUploadImage
              maxSizeInMB={1}
              imageUrl={imageUrl || ''}
              onChange={(file) => {
                setImage(file);
              }} />

            <Form.Item
              label="Office title"
              name="officeTitle"
              rules={generateFormRules('Office title', ['required'])}
            >
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    officeTitle: e.target.value,
                  })
                }
                value={aboutUs.officeTitle}
              />
            </Form.Item>
            <Form.Item
              label="Office address"
              name="officeAddress"
              rules={generateFormRules('Office address', ['required'])}
            >
              <TextArea
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    officeAddress: e.target.value,
                  })
                }
                value={aboutUs.officeAddress}
              />
            </Form.Item>

            <Form.Item
              label="Pick Location"
              name="pickLocation"
            >
              {!isLoading && (
                <AppMapPickLocation
                  isMain={true}
                  onChange={(coords) => {
                    setLocationCoords(coords)
                  }}
                  center={locationCoords}
                />
              )}
              <Text style={{ fontSize: 10, zIndex: 100 }}><b>Latitude:</b> {locationCoords.lat} · <b>Longitude:</b> {locationCoords.lng}</Text>
            </Form.Item>

            <Form.Item
              label="Contact"
              name="contact"
              rules={generateFormRules('Contact', ['required', 'phoneNumber'])}
            >
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    contact: e.target.value,
                  })
                }
                value={aboutUs.contact}
              />
            </Form.Item>
            <Form.Item
              label="Office email"
              name="officeEmail"
              rules={generateFormRules('Email', ['required', 'email'])}
            >
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    officeEmail: e.target.value,
                  })
                }
                value={aboutUs.officeEmail}
              />
            </Form.Item>
          </SectionContent>

          <SectionContent
            groupTitle="Social Media"
            helpers={[
              {
                title: '',
                content:
                  '',
              },
            ]}
          >
            <Form.Item label="Instagram" name="instagramLink" rules={[]}>
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    instagramLink: e.target.value,
                  })
                }
                value={aboutUs.instagramLink}
              />
            </Form.Item>
            <Form.Item label="Twitter" name="twitterLink" rules={[]}>
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    twitterLink: e.target.value,
                  })
                }
                value={aboutUs.twitterLink}
              />
            </Form.Item>
            {/* <Form.Item label="Youtube" name="youtubeLink" rules={[]}>
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    youtubeLink: e.target.value,
                  })
                }
                value={aboutUs.youtubeLink}
              />
            </Form.Item> */}
            <Form.Item label="Facebook" name="facebookLink" rules={[]}>
              <Input
                onChange={(e) =>
                  setAboutUs({
                    ...aboutUs,
                    facebookLink: e.target.value,
                  })
                }
                value={aboutUs.facebookLink}
              />
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
}

export default AboutUsEdit
