import { Store, createStore } from 'redux';
import { BatchState } from '../types/batch.type';
import { ConfigState } from '../types/config.type';
import rootReducer from './reducers/index';

export interface AppState {
  configApp: ConfigState
  batch: BatchState
}

const store: Store<AppState> = createStore(
  rootReducer
)

export default store;