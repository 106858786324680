import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import {
  TableOutlined,
  MoreOutlined,
  SearchOutlined,
  TagOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
  Modal,
  Form,
  InputNumber,
  Spin,
  Tooltip,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import { ContainerFilter } from "../admin";
import useFetchList from "../../hooks/useFetchList";
import {
  DiscountProperties,
  EAmountType,
  GeneralDiscountProperties,
} from "../../types/discount.type";
import { useEffect, useState } from "react";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<DiscountProperties, "createdAt" | "updatedAt">;
}

interface ResponseGeneralDiscountProps extends BaseResponseProps {
  payload: GeneralDiscountProperties;
}

const Discount = () => {
  const history = useHistory();

  const [
    isShowModalEditNewCustomerDiscount,
    setShowModalEditNewCustomerDiscount,
  ] = useState<boolean>(false);
  const [newCustomerDiscount, setNewCustomerDiscount] = useState<string>("");
  const [newCustomerDiscountInput, setNewCustomerDiscountInput] =
    useState<string>("");
  const [isLoadingNewCustomerDiscount, setIsLoadingNewCustomerDiscount] =
    useState<boolean>(false);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<DiscountProperties>({
    endpoint: "discounts",
  });

  const handleStatusChange = async (record: DiscountProperties) => {
    try {
      let newData = [];
      newData = data.map((item) => {
        if (item.discountId === record.discountId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/discounts/" + record.discountId,
        {
          discountId: record.discountId,
          isPublished: !record.isPublished,
        }
      );

      newData = data.map((item) => {
        if (item.discountId === res.data.payload.discountId) {
          return {
            ...item,
            isPublished: res.data.payload.isPublished,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + record.discountName + " status.");
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleCreateDiscount = () => {
    history.push("/discount/add");
  };

  const handleClickDetail = (e: DiscountProperties) => {
    history.push(`/discount/${e.discountId}`);
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: "active" | "inactive" | string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
    }
  };

  const handleClickEdit = (e: DiscountProperties) => {
    history.push(`/discount/${e.discountId}/edit`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "discountName",
      key: "discountName",
      render: (value: string, record: DiscountProperties) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {value}
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "amount",
      dataIndex: "amount",
      render: (value: string, record: DiscountProperties) => {
        return (
          <div>{`${value}${
            record.amountType === EAmountType.PERCENTAGE ? "%" : false
          }`}</div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (value: string, record: DiscountProperties) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Status",
      key: "isPublished",
      dataIndex: "isPublished",
      render: (isPublished: boolean, record: DiscountProperties) => {
        return (
          <>
            <Switch
              loading={record.statusLoading}
              checked={isPublished}
              onChange={() => {
                handleStatusChange(record);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Total Customers",
      dataIndex: "dataCustomers",
      key: "dataCustomers",
      render: (_: any, record: DiscountProperties) => (
        <div>{record.discount_users?.length}</div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: DiscountProperties) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: DiscountProperties) => (
    <Menu
      onClick={({ key }) => {
        if (key === "detail") {
          handleClickDetail(record);
        } else if (key === "edit") {
          handleClickEdit(record);
        }
      }}
    >
      <Menu.Item key='detail'>Detail Discount</Menu.Item>
      <Menu.Item key='edit'>Edit Discount</Menu.Item>
    </Menu>
  );

  const getCurrentNewCustomerDiscount = async () => {
    try {
      const res = await httpRequest.get<ResponseGeneralDiscountProps>(
        "general-discount"
      );

      if (res && res.data.payload) {
        if (res.data.payload.amount.includes("%")) {
          setNewCustomerDiscountInput(res.data.payload.amount);
        } else {
          setNewCustomerDiscountInput(res.data.payload.amount);
        }
        setNewCustomerDiscount(res.data.payload.amount);
      }
    } catch (error) {
      console.error("failed get getCurrentNewCustomerDiscount ", error);
    }
  };

  useEffect(() => {
    getCurrentNewCustomerDiscount();
  }, []);

  const handleUpdateNewCustomer = async () => {
    setShowModalEditNewCustomerDiscount(false);
    setIsLoadingNewCustomerDiscount(true);
    try {
      const res = await httpRequest.patch<ResponseGeneralDiscountProps>(
        "general-discount",
        {
          amount: newCustomerDiscountInput,
        }
      );

      if (res && res.data.payload) {
        await getCurrentNewCustomerDiscount();
      }
    } catch (error) {
      console.error("failed get getCurrentNewCustomerDiscount ", error);
    } finally {
      setIsLoadingNewCustomerDiscount(false);
    }
  };

  return (
    <AppLayout>
      <HeaderSection
        icon={<TagOutlined />}
        title='Discount'
        subtitle='Manage your discount data'
      />

      <div style={{ fontWeight: 600, fontSize: 20, margin: "15px 0" }}>
        General Discount
      </div>

      <div className='general-card'>
        <div style={{ opacity: 0.6 }}>New Customer Discount</div>
        <div className='general-card-wrapper'>
          {isLoadingNewCustomerDiscount ? (
            <Spin />
          ) : (
            <div className='general-value'>{newCustomerDiscount}</div>
          )}
          <div
            onClick={() => setShowModalEditNewCustomerDiscount(true)}
            className='general-edit'
          >
            <span>Edit discount</span>
            <EditOutlined />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 20, margin: "15px 0" }}>
          Membership Discount
        </div>
        <AppButton type='primary' onClick={handleCreateDiscount}>
          Add Discount
        </AppButton>
      </div>

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Discount name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title='New customer discount'
        visible={isShowModalEditNewCustomerDiscount}
        onOk={handleUpdateNewCustomer}
        onCancel={() => {
          setShowModalEditNewCustomerDiscount(false);
          if (newCustomerDiscount.includes("%")) {
            setNewCustomerDiscountInput(newCustomerDiscount);
          } else {
            setNewCustomerDiscountInput(newCustomerDiscount);
          }
        }}
        okText={`Save`}
        okButtonProps={{ type: "primary" }}
        width={800}
      >
        <Form
          name='profileForm'
          preserve={false}
          layout='vertical'
          autoComplete='off'
        >
          <Form.Item
            name='amount'
            label='Amount Discount'
            initialValue={newCustomerDiscountInput}
            style={{ width: "100%" }}
            help='Please input discount amount following with percentage symbol. ex 15%'
          >
            {/* {newCustomerDiscountInput} {newCustomerDiscount} */}
            <Input
              value={newCustomerDiscountInput}
              onChange={(e) => setNewCustomerDiscountInput(e.target.value)}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};

export default Discount;
