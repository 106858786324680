import { format } from 'date-fns';

const date = new Date();

export const now = format(new Date(), 'MMM dd, yyyy');
export const appVersion: string | undefined =
  process.env.REACT_APP_VERSION_NAME;
export const thisYear = date.getFullYear();

export const getInitialName = (name: string) => {
  const split: string[] = name.split(' ');

  let start = 0;
  let initial = [];

  if (split.length > 1) {
    while (start <= 1) {
      initial.push(split[start].charAt(0));
      start++;
    }
  } else {
    initial.push(split[0].substr(0, 2));
  }

  return initial.join('').toUpperCase();
};

export const formatDate = (date: any, formatType?: 'date-only') => {
  if (date) {
    const formated = format(
      new Date(date),
      formatType === 'date-only' ? 'MMM dd, yyyy' : 'MMM dd, yyyy, hh:iia'
    );
    return formated;
  }
  return '';
};

export const formatDate2 = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'EEE, yyyy-MM-dd, h:mm aaa');
    return formated;
  }
  return '';
};

export const formatDateDefault = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'yyyy-MM-dd');
    return formated;
  }
  return '';
};

export const formatUrlParams = (value: string) => {
  const param = value.split(' ').join('-').toLowerCase();
  return param;
};

export const PAGE_SIZE_OPTIONS = ['25', '50', '100'];
