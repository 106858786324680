import { CustomerProps } from './customer.type';

export enum EResellerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ResellerUserProperties {
  id: string;
  resellerId: string;
  userId: string;
  createdAt?: Date;
  updatedAt?: Date;
  reseller?: ResellerProperties;
  customer?: CustomerProps;
}

export interface ResellerProperties {
  resellerId: string;
  resellerName: string;
  description?: string;
  status: EResellerStatus;
  createdAt?: Date;
  updatedAt?: Date;
  reseller_users?: ResellerUserProperties[];
  statusLoading?: boolean;
}

export const initialReseller: ResellerProperties = {
  resellerId: '',
  resellerName: '',
  status: EResellerStatus.ACTIVE,
};
