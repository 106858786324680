import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';

type Props = {
  isMain?: boolean;
  width?: number | string;
  height?: number;
  defaultZoom?: number;
  center?: { lat: number; lng: number };
  onChange: (location: {
    coords: { lat: number; lng: number };
    address: string;
    suburb: string;
    state: string;
    postalCode: string;
  }) => void;
};

function MapPickLocation(props: Props) {
  const [map, setMap] = React.useState(null);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: -25.515575493284196,
    lng: 133.79259718218898,
  });
  function onLoad(data: any) {
    // console.log("autocomplete: ", data);
    setAutocomplete(data);
  }

  function onPlaceChanged() {
    if (autocomplete !== null) {
      const locationInfo = autocomplete.getPlace();
      console.log(locationInfo);
      setCenter({
        lat: locationInfo.geometry.location.lat(),
        lng: locationInfo.geometry.location.lng(),
      });

      let postalCode = '';
      let suburb = '';
      let state = '';

      for (const addressComp of locationInfo.address_components) {
        for (const dataType of addressComp.types) {
          if (dataType === 'postal_code') {
            postalCode = addressComp.long_name;
          } else if (dataType === 'locality') {
            suburb = addressComp.long_name;
          } else if (dataType === 'administrative_area_level_1') {
            state = addressComp.short_name;
          }
        }
      }

      props.onChange({
        coords: {
          lat: locationInfo.geometry.location.lat(),
          lng: locationInfo.geometry.location.lng(),
        },
        address: locationInfo.formatted_address,
        postalCode,
        state,
        suburb,
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY as string}
      libraries={['places']}
    >
      <GoogleMap
        id='checkout-map'
        center={props.center ? props.center : center}
        mapContainerStyle={{
          height: 300,
          width: '100%',
        }}
        zoom={9}
        onUnmount={onUnmount}
        options={{
          // streetViewControl: false,
          disableDefaultUI: true,
        }}
      >
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: 'AU' }}
        >
          <input
            type='text'
            placeholder='Enter address of pickup location'
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `95%`,
              height: `40px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: 'absolute',
              left: '0',
              right: '0',
              top: '10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </Autocomplete>
        <Marker
          key={'marker'}
          position={props.center ? props.center : center}
          onLoad={onLoad}
        />
      </GoogleMap>
    </LoadScript>
  );
}

export default MapPickLocation;
