import {
  Checkbox,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Radio,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import {
  initialCategory,
  CategoryProps,
  ECategoryType,
} from '../../types/category.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { useDebounce } from 'use-debounce';
import { IHttpResponse, IPayloadPagination } from '../../helpers/pagination';
import { generateQueryString } from '../../helpers/generateQueryString';
import useFetchList from '../../hooks/useFetchList';
import { generateFormRules } from '../../helpers/formRules';
import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IParams {
  categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CategoryProps, 'createdAt' | 'updatedAt'>;
}

const CategoryEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { categoryId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<CategoryProps, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const {
    isLoading: isLoadingCategories,
    data: dataCategories,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<CategoryProps>({
    endpoint: 'categories',
    initialQuery: {
      isPublished: 'active',
      limit: 1000000,
    },
  });

  const [newDataCategories, setNewDataCategories] = React.useState<
    CategoryProps[]
  >([]);

  React.useEffect(() => {
    if (dataCategories && dataCategories.length > 0) {
      const newData = dataCategories
        .filter((item) => item.subCategories.length === 0 && { ...item })
        .map((item) => ({ ...item, isMealpack: false }));

      setNewDataCategories(newData);
    }
  }, [dataCategories]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [category, setCategory] = React.useState<CategoryProps>({
    ...initialCategory,
  });
  const [categoryInput, setCategoryInput] = React.useState<string>('');
  const [categoryType, setCategoryType] = React.useState<ECategoryType>(
    ECategoryType.DEFAULT
  );
  const [subCategories, setSubCategories] = React.useState<CategoryProps[]>([]);
  const [isModalSubCategory, setIsModalSubCategory] =
    React.useState<boolean>(false);

  const [valueCategory] = useDebounce(categoryInput, 1000);
  const [notAllowSave, setNotAllowSave] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!categoryId) {
      checkCategory();
    }
  }, [valueCategory, categoryId]);

  const checkCategory = async () => {
    if (valueCategory !== '') {
      const resCategory = await httpRequest.get<
        IHttpResponse<IPayloadPagination<CategoryProps>>
      >(
        `/categories${generateQueryString({
          search: valueCategory,
          // categoryNotIncludeId: categoryId,
        })}`
      );

      if (resCategory.data.payload.count > 0) {
        setNotAllowSave(true);
      } else {
        setNotAllowSave(false);
      }
    }
  };

  React.useEffect(() => {
    if (categoryType === ECategoryType.DEFAULT) {
      setNotAllowSave(false);
    } else if (subCategories.length === 0) {
      setNotAllowSave(true);
    } else {
      setNotAllowSave(false);
    }
  }, [categoryType, subCategories]);

  const createCategory = async (
    props: Omit<
      CategoryProps,
      'createdAt' | 'updatedAt' | 'categoryId' | 'statusLoading'
    >
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.post('/categories', {
        categoryName: categoryInput,
        categoryType: props.categoryType || ECategoryType.DEFAULT,
        isPublished: props.isPublished,
        subCategories: subCategories.map((item) => ({
          categoryId: item.categoryId,
        })),
      });

      message.success('Success create ' + props.categoryName);

      history.push('/category');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateCategory = async (
    props: Omit<CategoryProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch('/categories/' + categoryId, {
        categoryName: categoryInput,
        categoryType: props.categoryType || ECategoryType.DEFAULT,
        isPublished: props.isPublished,
        subCategories: subCategories.map((item) => item.categoryId),
      });
      // if (props.categoryName.toString() !== valueCategory.toString()) {
      // } else {
      //   await httpRequest.patch("/categories/" + categoryId, {
      //     isPublished: props.isPublished,
      //   });
      // }
      message.success('Success update ' + props.categoryName + ' data');
      history.push('/category');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<
      CategoryProps,
      'createdAt' | 'updatedAt' | 'statusLoading' | 'isMealpack'
    >
  ) => {
    if (categoryId) {
      updateCategory(values);
    } else {
      createCategory(values);
    }
  };

  React.useEffect(() => {
    if (categoryId) {
      const fetchCategory = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/categories/' + categoryId
          );
          setCategory(res.data.payload);
          setCategoryInput(res.data.payload.categoryName);
          setIsLoading(false);
          setSubCategories(res.data.payload.subCategories);
          setCategoryType(res.data.payload.categoryType);

          const bcDetails = [
            {
              field: 'categoryId',
              value: categoryId,
              label: res.data.payload.categoryName,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchCategory();
    }
  }, [categoryId]);

  function handleAddSubCategory() {
    const selectedSubCategories = newDataCategories.filter(
      (item) => item.isMealpack
    );
    setSubCategories(selectedSubCategories);
    setIsModalSubCategory(false);
  }

  function onCheckSubCategory(data: CategoryProps) {
    const newValues = newDataCategories.map((item) => {
      if (item.categoryId === data.categoryId) {
        return {
          ...item,
          isMealpack: !item.isMealpack,
        };
      } else {
        return { ...item };
      }
    });
    setNewDataCategories(newValues);
  }

  function handleRemoveSubCategory(category: CategoryProps) {
    setSubCategories((oldValues) =>
      oldValues.filter((item) => item.categoryId !== category.categoryId)
    );
    setNewDataCategories((oldValues) =>
      oldValues.map((item) =>
        item.categoryId === category.categoryId
          ? { ...item, isMealpack: false }
          : { ...item }
      )
    );
  }

  function handleSetCategoryType(value: string) {
    setCategoryType(
      value === ECategoryType.MEALPACK
        ? ECategoryType.MEALPACK
        : ECategoryType.DEFAULT
    );
    if (value === ECategoryType.DEFAULT) {
      setSubCategories([]);
    }
  }

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(categoryId ? 'Edit' : 'Add') + ' Category'}
        subtitle='Manage your category data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              disabled={notAllowSave}
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={categoryId ? category : initialCategory}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Category Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'Status Unpublished will hide the Category in Menu and Web-App Menu Filter',
              },
            ]}
          >
            <Form.Item
              label='Category Name'
              name='categoryName'
              rules={generateFormRules('Category Name', [
                'required',
                'letter-and-space',
              ])}
            >
              <Input
                id='categoryName'
                type={'text'}
                placeholder='Enter category name'
                value={categoryInput}
                onChange={(event) => setCategoryInput(event.target.value)}
              />
            </Form.Item>
            {notAllowSave && categoryInput !== '' && (
              <Text type='danger'>
                Category Name "{categoryInput}" already exist, Please change the
                Category Name
              </Text>
            )}
            <Form.Item label='Is Meal Packs' name='categoryType'>
              <Radio.Group
                value={category.categoryType}
                onChange={(e) => handleSetCategoryType(e.target.value)}
              >
                <Radio value={ECategoryType.MEALPACK}>Yes</Radio>
                <Radio value={ECategoryType.DEFAULT}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {categoryType === ECategoryType.MEALPACK && (
              <Form.Item label='Sub Category'>
                <div style={{ opacity: 0.7 }}>
                  All of menu from this category will be shown if customer
                  choose this Meal Packs
                </div>
                {subCategories && subCategories.length > 0 ? (
                  subCategories.map((item, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #ddd',
                        padding: '15px 0',
                      }}
                    >
                      <div>{item.categoryName}</div>
                      <AppButton
                        type='primary'
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveSubCategory(item)}
                      ></AppButton>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom: '1px solid #ddd',
                      padding: '15px 0',
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      Sub category empty
                    </div>
                  </div>
                )}
                <AppButton
                  disabled={!categoryInput}
                  style={{ marginTop: 15 }}
                  type='primary'
                  onClick={() => setIsModalSubCategory(true)}
                >
                  Add Sub Category
                </AppButton>
              </Form.Item>
            )}
            <Form.Item
              label='Status'
              name='isPublished'
              rules={[
                {
                  required: true,
                  message: 'The Status is required.',
                },
              ]}
            >
              <Radio.Group value={category.isPublished}>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>

      <Modal
        title='Add Sub Category'
        visible={isModalSubCategory}
        onOk={handleAddSubCategory}
        onCancel={() => {
          setIsModalSubCategory(false);
        }}
        okText={`Add (${
          newDataCategories && newDataCategories.length > 0
            ? newDataCategories.filter((item) => item.isMealpack).length
            : '0'
        }) Sub Category`}
        okButtonProps={{ type: 'primary' }}
      >
        <div>
          All of menu from selected category below will be part of this "
          {categoryInput}" Category
        </div>
        {isLoadingCategories ? (
          <div>Loading categories...</div>
        ) : (
          <div
            style={{
              maxWidth: '100%',
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            {newDataCategories &&
              newDataCategories.length > 0 &&
              newDataCategories.map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #ddd',
                    padding: '15px 0',
                  }}
                >
                  <Checkbox
                    checked={item.isMealpack}
                    onChange={() => onCheckSubCategory(item)}
                  >
                    {item.categoryName}
                  </Checkbox>
                </div>
              ))}
          </div>
        )}
      </Modal>
    </AppLayout>
  );
};

export default CategoryEdit;
