import { ScheduleDeliveryProperties } from "./schedule.type";

export interface DeliveryCoverageProperties {
  id: string;
  coverageName: string;
  postalCode: any;
  isPublished: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  schedule_deliveries?: ScheduleDeliveryProperties[];
}

export const initialDeliveryCoverage: DeliveryCoverageProperties = {
  id: "",
  coverageName: "",
  postalCode: {},
  isPublished: false,
};

export interface StateProperties {
  stateId: string;
  stateName: string;
}

export interface PostcodeProperties {
  postcode: string;
  state: string;
  suburbName: string;
  fee?: number | string;
}
