import { ConfigTypes } from '../../types/config.type';

export function showSidebar() {
  return {
    type: ConfigTypes.SHOW_SIDEBAR,
  }
}

export function hideSidebar() {
  return {
    type: ConfigTypes.HIDE_SIDEBAR,
  }
}

export function setActiveSubMenu(payload: Array<string>) {
  return {
    type: ConfigTypes.SET_ACTIVE_SUB_MENU,
    payload,
  }
}