import React from 'react';
import AppLayout from '../layout/AppLayout';
import { CaretUpOutlined, HomeOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Image, Divider, Tag } from 'antd';
import HeaderSection from '../../components/HeaderSection';
import {
  ETransactionStatus,
  FetchAllAnnualDataTransaction,
  FetchAllTransactionItemResponse,
  FetchAllTransactionResponse,
} from '../../types/transaction.type';
import {
  FetchAllAnnualDataCustomer,
  FetchAllCustomerResponse,
} from '../../types/customer.type';
import moment from 'moment';
import { generateQueryString } from '../../helpers/generateQueryString';
import { httpRequest } from '../../helpers/api';
import { FetchAllMenuResponse, VariantProps } from '../../types/menu.type';
import Chart from 'react-apexcharts';
import { uniqBy } from 'lodash';
import { TagProps } from '../../types/tag.type';

type QuantityVariant = {
  variant: VariantProps;
  variants: VariantProps[];
  images: any[];
  productName: string;
  productId: string;
  productVariantId?: string;
  tags: TagProps[];
  totalQuantity: number;
};

type ChartProps = {
  name: string;
  type: 'column' | 'line';
  data: Array<number | null>;
};

const Dashboard = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [totalOrderThisMonth, setTotalOrderThisMonth] =
    React.useState<number>(0);
  const [gainPercentageOrder, setGainPercentageOrder] =
    React.useState<number>(0);

  const [newCustomerThisMonth, setNewCustomerThisMonth] =
    React.useState<number>(0);

  const [totalCustomer, setTotalCustomer] = React.useState<number>(0);
  const [gainPercentageCustomer, setGainPercentageCustomer] =
    React.useState<number>(0);

  const [totalMenu, setTotalMenu] = React.useState<number>(0);
  const [series, setSeries] = React.useState<Array<ChartProps>>([
    {
      name: 'Total Order',
      type: 'column',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: 'Total Customer',
      type: 'line',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const [mostOrdered, setTotalMostOrdered] = React.useState<
    Array<QuantityVariant>
  >([]);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        setIsLoading(true);
        const [
          resTransactionLastMonth,
          resTransactionThisMonth,
          resCustomerLastMonth,
          resNewCustomerThisMonth,
          resTotalCustomer,
          resTotalMenu,
          resMostOrdered,
          resTransactionReportAnnually,
          resCustomerReportAnnually,
        ] = await Promise.all([
          httpRequest.get<FetchAllTransactionResponse>(
            `/transactions${generateQueryString({
              scheduledAtFrom: moment()
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
                .toString(),
              scheduledAtUntil: moment()
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
                .toString(),
              transactionStatus: [
                ETransactionStatus.PAID,
                ETransactionStatus.COD,
                ETransactionStatus.CREDITUR_ACCOUNT,
              ],
            })}`
          ),
          httpRequest.get<FetchAllTransactionResponse>(
            `/transactions${generateQueryString({
              scheduledAtFrom: moment()
                .startOf('month')
                .format('YYYY-MM-DD')
                .toString(),
              scheduledAtUntil: moment()
                .endOf('month')
                .format('YYYY-MM-DD')
                .toString(),
              transactionStatus: [
                ETransactionStatus.PAID,
                ETransactionStatus.COD,
                ETransactionStatus.CREDITUR_ACCOUNT,
              ],
            })}`
          ),
          httpRequest.get<FetchAllCustomerResponse>(
            `/customers${generateQueryString({
              startAt: moment
                .utc()
                .subtract(1, 'month')
                .startOf('month')
                .toISOString(),
              endAt: moment
                .utc()
                .subtract(1, 'month')
                .endOf('month')
                .toISOString(),
            })}`
          ),
          httpRequest.get<FetchAllCustomerResponse>(
            `/customers${generateQueryString({
              startAt: moment.utc().startOf('month').toISOString(),
              endAt: moment.utc().endOf('month').toISOString(),
            })}`
          ),
          httpRequest.get<FetchAllCustomerResponse>(`/customers`),
          httpRequest.get<FetchAllMenuResponse>(`/menus`),
          httpRequest.get<FetchAllTransactionItemResponse>(
            `/transactions/summary/order-summary`
          ),
          httpRequest.get<FetchAllAnnualDataTransaction>(
            `transactions/summary/order-summary/annually${generateQueryString({
              startAt: moment.utc().startOf('year').toISOString(),
              endAt: moment.utc().endOf('year').toISOString(),
            })}`
          ),
          httpRequest.get<FetchAllAnnualDataCustomer>(
            `transactions/summary/order/chart/customer/unique/annually${generateQueryString(
              {
                startAt: moment.utc().startOf('year').toISOString(),
                endAt: moment.utc().endOf('year').toISOString(),
              }
            )}`
          ),
        ]);

        setTotalOrderThisMonth(
          resTransactionThisMonth.data.payload.results.length
        );
        setNewCustomerThisMonth(
          resNewCustomerThisMonth.data.payload.results.length
        );
        setTotalCustomer(resTotalCustomer.data.payload.results.length);

        if (resTransactionLastMonth.data.payload.results.length === 0) {
          //to avoid divided by 0
          setGainPercentageOrder(100);
        } else {
          const gainPercentageTrx =
            ((resTransactionThisMonth.data.payload.results.length -
              resTransactionLastMonth.data.payload.results.length) /
              resTransactionLastMonth.data.payload.results.length) *
            100;
          setGainPercentageOrder(gainPercentageTrx);
        }

        if (resCustomerLastMonth.data.payload.results.length === 0) {
          //to avoid divided by 0
          setGainPercentageCustomer(100);
        } else {
          const gainPercentageCust =
            ((resNewCustomerThisMonth.data.payload.results.length -
              resCustomerLastMonth.data.payload.results.length) /
              resCustomerLastMonth.data.payload.results.length) *
            100;
          setGainPercentageCustomer(gainPercentageCust);
        }

        setTotalMenu(resTotalMenu.data.payload.results.length);
        if (resMostOrdered) {
          const uniqVariantRes = uniqBy(
            resMostOrdered.data.payload.results,
            'productVariantId'
          );

          const newVariantRes = uniqVariantRes
            .map((variant) => {
              const filterRes = resMostOrdered.data.payload.results.filter(
                (item) => item.productVariantId === variant.productVariantId
              );
              let totalQuantity = 0;
              filterRes.forEach((variantFiltered) => {
                totalQuantity = totalQuantity + variantFiltered.quantity;
              });
              const menuInfo = resTotalMenu.data.payload.results.find(
                (item) => item.menuId === variant.productId
              );

              return {
                variant: variant.metaProduct.variant as any,
                variants: variant.metaProduct.variants,
                images: variant.metaProduct.images,
                productName: variant.metaProduct.name as string,
                productId: variant.productId,
                productVariantId: variant.productVariantId,
                tags: menuInfo ? menuInfo.tags : variant.metaProduct.tags,
                totalQuantity,
              };
            })
            .sort((a, b) =>
              a.totalQuantity > b.totalQuantity
                ? 1
                : b.totalQuantity > a.totalQuantity
                ? -1
                : 0
            )
            .reverse();

          setTotalMostOrdered(
            newVariantRes.length >= 3
              ? newVariantRes.slice(0, 3)
              : newVariantRes.slice(0, newVariantRes.length)
          );

          const trxAnnualReport = resTransactionReportAnnually.data.payload.map(
            (item) => (item === 0 ? null : item)
          );
          // const custAnnualReport = resCustomerReportAnnually.data.payload.map((item) => item === 0 ? null : item)

          setSeries([
            {
              name: 'Total Order',
              type: 'column',
              data: trxAnnualReport,
            },
            {
              name: 'Total Customer',
              type: 'line',
              data: resCustomerReportAnnually.data.payload,
            },
          ]);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchList();
  }, []);

  const { Text, Title } = Typography;

  const chartOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    xaxis: {
      categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    zoom: {
      enabled: false,
    },
    markers: {
      size: [2, 5],
    },
  };

  return (
    <AppLayout>
      <HeaderSection icon={<HomeOutlined />} title='Dashboard' />
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <div
            style={{
              border: '1px solid #D5DCE1',
              height: '110px',
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#748494',
                paddingLeft: '10px',
              }}
            >
              Total Order This Month
            </Text>
            <Row style={{ paddingTop: '25px', paddingLeft: '10px' }}>
              <Col>
                <Text style={{ fontSize: '39px' }}>{totalOrderThisMonth}</Text>
              </Col>
              {gainPercentageOrder > 0 && (
                <Col style={{ marginTop: '5%' }} offset={2}>
                  <CaretUpOutlined
                    style={{ color: '#56C288', fontSize: '30px' }}
                  />
                </Col>
              )}
              {gainPercentageOrder > 0 && (
                <Col style={{ marginTop: '7%' }}>
                  <Text style={{ color: '#A5B2BD', fontSize: '12px' }}>
                    {' '}
                    +{gainPercentageOrder?.toFixed(2)}% Since Last Month
                  </Text>
                </Col>
              )}
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              border: '1px solid #D5DCE1',
              height: '110px',
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#748494',
                paddingLeft: '10px',
              }}
            >
              New Customer This Month
            </Text>
            <Row style={{ paddingTop: '25px', paddingLeft: '10px' }}>
              <Text style={{ fontSize: '39px' }}>{newCustomerThisMonth}</Text>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              border: '1px solid #D5DCE1',
              height: '110px',
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#748494',
                paddingLeft: '10px',
              }}
            >
              Total Customers
            </Text>
            <Row style={{ paddingTop: '25px', paddingLeft: '10px' }}>
              <Col>
                <Text style={{ fontSize: '39px' }}>{totalCustomer}</Text>
              </Col>
              {gainPercentageCustomer > 0 && (
                <Col style={{ marginTop: '5%' }} offset={2}>
                  <CaretUpOutlined
                    style={{ color: '#56C288', fontSize: '30px' }}
                  />
                </Col>
              )}
              {gainPercentageCustomer > 0 && (
                <Col style={{ marginTop: '7%' }}>
                  <Text style={{ color: '#A5B2BD', fontSize: '12px' }}>
                    {' '}
                    +{gainPercentageCustomer?.toFixed(2)}% Since Last Month
                  </Text>
                </Col>
              )}
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              border: '1px solid #D5DCE1',
              height: '110px',
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#748494',
                paddingLeft: '10px',
              }}
            >
              Total Menu
            </Text>
            <Row style={{ paddingTop: '25px', paddingLeft: '10px' }}>
              <Text style={{ fontSize: '39px' }}>{totalMenu}</Text>
            </Row>
          </div>
        </Col>
      </Row>
      <br></br>
      <div
        style={{
          backgroundColor: 'white',
        }}
      >
        <Text type='secondary' style={{ marginLeft: '1%' }}>
          Most Ordered Menu of All Time
        </Text>
        <br></br>
        {/* {mostOrdered.length > 0 &&
                    mostOrdered.map(variantItem => {
                        return (
                            <>
                                <Row gutter={[8, 8]} style={{ marginTop: '1%' }}>
                                    <Col style={{
                                        marginLeft: '1%'
                                    }}>
                                        <Image
                                            width={130}
                                            height={70}
                                            src={
                                                variantItem?.images && variantItem?.images.length > 0
                                                    ? variantItem.images[0].imageUrl || '/images/blur-image.jpeg'
                                                    : '/images/blur-image.jpeg'
                                            }
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    src="/images/blur-image.jpeg"
                                                    width={130}
                                                    height={70}
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col span={5} offset={1}>
                                        <Text style={{ fontSize: '14px', lineHeight: '21px', marginBottom: '3%' }}>{variantItem.productName}</Text>
                                    </Col>
                                    <Col span={3}>
                                        <Row style={{ marginBottom: '3%' }}>
                                            <Text type='secondary'>Variant</Text>
                                        </Row>
                                        <Row>
                                            {variantItem.variants.length === 1 ? <Text style={{ fontStyle: 'italic', fontSize: '16px', color: '#93A1B0' }} >None</Text> : <Text>{variantItem.variant.label}</Text>}
                                        </Row>
                                    </Col>
                                    <Col span={3}>
                                        <Row style={{ marginBottom: '3%' }}>
                                            <Text type='secondary'>Price</Text>
                                        </Row>
                                        <Row>
                                            <Text style={{ fontSize: '16px', lineHeight: '24px' }}>${(variantItem.variant.price)?.toFixed(2)}</Text>
                                        </Row>
                                    </Col>
                                    <Col span={5}>
                                        <Row style={{ marginBottom: '3%' }}>
                                            <Text type='secondary'>Tags</Text>
                                        </Row>
                                        <Row>
                                            {
                                                variantItem.tags &&
                                                Array.from(variantItem.tags, (tag, index) => {
                                                    if (index <= 1) {
                                                        return (
                                                            <Tag style={{ marginLeft: '1%' }}>{tag.name}</Tag>
                                                        )
                                                    }
                                                    else if (index === variantItem.tags.length - 1) {
                                                        return (
                                                            <Tag style={{ marginLeft: '1%' }}>+{variantItem.tags.length - 2}</Tag>
                                                        )
                                                    }
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    <Col span={3}>
                                        <Row>
                                            <Text type='secondary'>Total Ordered</Text>
                                        </Row>
                                        <Row>
                                            <Text style={{ fontSize: '20px', lineHeight: '28px' }} >{variantItem.totalQuantity}</Text>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                            </>
                        )
                    })
                } */}
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: '350px',
          width: '1300px',
        }}
      >
        <Text
          type='secondary'
          style={{
            marginLeft: '1%',
            marginTop: '1%',
          }}
        >
          Order Summary
        </Text>
        <br />
        <div
          style={{
            marginLeft: '1%',
            marginRight: '1%',
          }}
        >
          <Chart
            options={chartOptions}
            series={series}
            height={300}
            width={1200}
          />
        </div>
      </div>
    </AppLayout>
  );
};

export default Dashboard;
