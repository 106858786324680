import {
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { initialTag, TagProps } from '../../types/tag.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { useDebounce } from 'use-debounce';
import { IHttpResponse, IPayloadPagination } from '../../helpers/pagination';
import { generateQueryString } from '../../helpers/generateQueryString';

const { Text } = Typography;

interface IParams {
  dietariesId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<TagProps, 'createdAt' | 'updatedAt'>;
}

const AllergenEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { dietariesId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<TagProps, 'createdAt' | 'updatedAt'>>>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState<TagProps>(initialTag);

  const [tagInput, setTagInput] = React.useState<String>('' || tag.name);

  const [valueTag] = useDebounce(tagInput, 1000);
  const [notAllowSave, setNotAllowSave] = React.useState<boolean>(false);

  React.useEffect(() => {
    checkTag();
  }, [valueTag]);

  const checkTag = async () => {
    if (valueTag !== '') {
      const resTag = await httpRequest.get<
        IHttpResponse<IPayloadPagination<TagProps>>
      >(
        `/tags${generateQueryString({
          name: valueTag,
          tagNotIncludeId: dietariesId,
          type: 'allergen',
        })}`
      );

      if (resTag.data.payload.count > 0) {
        setNotAllowSave(true);
      } else {
        setNotAllowSave(false);
      }
    }
  };

  const createTag = async (
    props: Omit<TagProps, 'createdAt' | 'updatedAt' | 'tagId' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.post('/tags', {
        ...props,
        name: valueTag,
        type: 'allergen',
      });
      message.success('Success create ' + props.name);

      history.push('/dietaries');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateTag = async (
    props: Omit<TagProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      if (props.name.toString() !== valueTag.toString()) {
        await httpRequest.patch('/tags/' + dietariesId, {
          ...props,
          name: valueTag,
        });
      } else {
        await httpRequest.patch('/tags/' + dietariesId, {
          isPublished: props.isPublished,
        });
      }
      message.success('Success update ' + valueTag + ' data');
      history.push('/dietaries');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<TagProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    if (dietariesId) {
      updateTag(values);
    } else {
      createTag(values);
    }
  };

  React.useEffect(() => {
    if (dietariesId) {
      const fetchUser = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/tags/' + dietariesId
          );
          setTag(res.data.payload);
          setTagInput(res.data.payload.name);
          setIsLoading(false);

          const bcDetails = [
            {
              field: 'tagId',
              value: dietariesId,
              label: res.data.payload.name,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchUser();
    }
  }, [dietariesId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(dietariesId ? 'Edit' : 'Add') + ' Dietaries'}
        subtitle='Manage your allergen data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              disabled={notAllowSave}
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={dietariesId ? tag : initialTag}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Dietaries Data'
            helpers={[
              {
                title: 'Information',
                content: 'Dietaries will show in Web-App Menu as Filter',
              },
            ]}
          >
            <Form.Item
              label='Dietaries Name'
              name='name'
              id='name'
              rules={generateFormRules('Name', [
                'required',
                'letter-and-space',
              ])}
            >
              <Input
                id='name'
                placeholder='Enter dietaries name'
                type={'text'}
                defaultValue={tag.name}
                onChange={(e) => setTagInput(e.target.value)}
              />
            </Form.Item>
            {tagInput !== '' && notAllowSave && (
              <Text type='danger'>
                Dietaries Name has been used by other Dietaries, Please change
                the Dietaries Name
              </Text>
            )}
            <Form.Item
              label='Status'
              name='isPublished'
              rules={generateFormRules('Status', ['required'])}
            >
              <Radio.Group value={tag.isPublished}>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default AllergenEdit;
