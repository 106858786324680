import React, { useState } from 'react';
import AppLayout from '../../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { MenuOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Space,
  Table,
  Dropdown,
  Menu,
  message,
  Image,
  Modal,
} from 'antd';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { formatDate } from '../../../helpers/constant';
import { generateQueryString } from '../../../helpers/generateQueryString';
import { DefaultQuery } from '../../../types/config.type';
import { useDebounce } from 'use-debounce';
import { FetchAllAppConfigResponse, AppConfigProps } from '../../../types/appConfig.type';
import { arrayMoveImmutable } from 'array-move';
import { getErrorMessage } from '../../../helpers/errorHandler';
import Text from 'antd/lib/typography/Text';

interface PaginationProps {
  total: number;
  perPage: number;
  page: number;
  prev: string | undefined;
  next: string | undefined;
}

interface QueryProps extends DefaultQuery {
}

const AppConfig = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<AppConfigProps>>([]);
  const [search, setSearch] = React.useState<string>();
  const [pagination, setPagination] = React.useState<PaginationProps>({
    total: 0,
    perPage: 25,
    page: 1,
    next: '',
    prev: '',
  });
  const [query, setQuery] = React.useState<QueryProps>({
    // offset: 0,
    // limit: 25,
  });

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [willBeDeleted, setWillBeDeleted] = useState<AppConfigProps>()

  const [value] = useDebounce(search, 500);

  React.useEffect(() => {
    setQuery((e: QueryProps) => {
      return { ...e, search: value as string };
    });
  }, [value]);

  const fetchList = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<FetchAllAppConfigResponse>(
        `/app-configs?keys=MINIMUM_ORDER,FREE_DELIVERY`
      );

      setPagination((oldVal) => {
        return {
          ...oldVal,
          prev: res.data.payload.prev,
          next: res.data.payload.next,
          total: res.data.payload.count,
        };
      });
      let data = res.data.payload.results

      data.sort(function(a, b) {
        var keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      

      setData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [query]);

  const handleClickEdit = (e: AppConfigProps) => {
    history.push(`/app-config/${e.configId}/edit`);
  };

  const handleClickDetail = (e: AppConfigProps) => {
    history.push(`/app-config/${e.configId}`);
  };


  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render: (url: string, record: AppConfigProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            <Text>{record.key}</Text>
          </div>
        );
      },
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      render: (text: string, record: AppConfigProps) => {
        return (
          <div>
            {record.value}
          </div>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: any) => <div>{formatDate(updatedAt)}</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: AppConfigProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  // Draggable Table

  const menu = (record: AppConfigProps) => (
    <Menu
      onClick={({ key }) => {
        if (key === 'edit') {
          handleClickEdit(record)
        } else if (key === 'delete') {
          setWillBeDeleted(record)
        }
      }}
    >
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<SettingOutlined />}
        title="App Config"
        subtitle="Manage your app config data"
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Modal
        title="Confirmation"
        visible={!!willBeDeleted}
        onCancel={() => {
          setWillBeDeleted(undefined)
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to change to delete this data?
        </p>
      </Modal>
    </AppLayout>
  );
};

export default AppConfig;
