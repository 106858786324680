import React, { useState } from 'react';
import AppLayout from '../../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { MenuOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Space,
  Table,
  Dropdown,
  Menu,
  message,
  Image,
  Modal,
} from 'antd';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { formatDate } from '../../../helpers/constant';
import { generateQueryString } from '../../../helpers/generateQueryString';
import { DefaultQuery } from '../../../types/config.type';
import { useDebounce } from 'use-debounce';
import { FetchAllHowItWorkResponse, HowItWorkProps } from '../../../types/howItWork.type';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { getErrorMessage } from '../../../helpers/errorHandler';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const CustomSortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

interface PaginationProps {
  total: number;
  perPage: number;
  page: number;
  prev: string | undefined;
  next: string | undefined;
}

interface QueryProps extends DefaultQuery {
}

const HowItWork = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<HowItWorkProps>>([]);
  const [search, setSearch] = React.useState<string>();
  const [pagination, setPagination] = React.useState<PaginationProps>({
    total: 0,
    perPage: 25,
    page: 1,
    next: '',
    prev: '',
  });
  const [query, setQuery] = React.useState<QueryProps>({
    // offset: 0,
    // limit: 25,
  });

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [willBeDeleted, setWillBeDeleted] = useState<HowItWorkProps>()

  const [value] = useDebounce(search, 500);

  React.useEffect(() => {
    setQuery((e: QueryProps) => {
      return { ...e, search: value as string };
    });
  }, [value]);

  const fetchList = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<FetchAllHowItWorkResponse>(
        `/howitworks${generateQueryString(query)}`
      );

      const newRes = res.data.payload.results.map((howItWork) => {
        return {
          ...howItWork,
          key: howItWork.howitworksId,
        };
      });

      setPagination((oldVal) => {
        return {
          ...oldVal,
          prev: res.data.payload.prev,
          next: res.data.payload.next,
          total: res.data.payload.count,
        };
      });

      setData(newRes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [query]);
  
  const handleCreateUser = () => {
    history.push('/how-it-work/add');
  };

  const handleClickDetail = (e: HowItWorkProps) => {
    history.push(`/how-it-work/${e.howitworksId}`);
  };

  const handleClickEdit = (e: HowItWorkProps) => {
    history.push(`/how-it-work/${e.howitworksId}/edit`);
  };

  const handleClickDelete = async () => {
    if (willBeDeleted) {
      setIsLoadingAction(true)
      await httpRequest.delete('/howitworks/' + willBeDeleted.howitworksId)
      setWillBeDeleted(undefined)
      setIsLoadingAction(false)

      fetchList();
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Image',
      dataIndex: 'howitworksImg',
      key: 'howitworksImg',
      render: (url: string, record: HowItWorkProps) => {
        return (
          <Image
            width={120}
            height={100}
            src={url}
            placeholder={
              <Image
                preview={false}
                src="/images/blur-image.jpeg"
                width={120}
                height={100}
              />
            }
          />
        );
      },
    },
    {
      title: 'Title',
      key: 'headline',
      dataIndex: 'headline',
      render: (text: string, record: HowItWorkProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Description',
      key: 'subtitle',
      dataIndex: 'subtitle',
      render: (text: string, record: HowItWorkProps) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: HowItWorkProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  // Draggable Table
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newData: HowItWorkProps[] = arrayMoveImmutable(
        [].concat(data as any),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // console.log('Sorted items: ', newData);
      setData(newData)

      setIsLoading(true)
      const newDataInQueue = newData.map((item, key) => ({
        howitworksId: item.howitworksId,
        queueOrder: key,
      }))

      console.info('newDataInQueue', newDataInQueue)

      httpRequest.patch('howitworks/queue/bulk', {
        bulk: newDataInQueue
      }).then(res => {
        message.success('Queue order updated successfully')
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        message.error(getErrorMessage(err))
      })
    }
  };

  const DraggableContainer = (props: any) => (
    <CustomSortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex(
      (x) => x.queueOrder === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const menu = (record: HowItWorkProps) => (
    <Menu
      onClick={({ key }) => {
        if (key === 'edit') {
          handleClickEdit(record)
        } else if (key === 'delete') {
          setWillBeDeleted(record)
        }
      }}
    >
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<SettingOutlined />}
        title="How It Works"
        subtitle="Manage your How It Work data"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleCreateUser}>
              Add How It Work
            </AppButton>
          </Space>
        }
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="queueOrder"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />

      <Modal
        title="Confirmation"
        visible={!!willBeDeleted}
        onOk={handleClickDelete}
        onCancel={() => {
          setWillBeDeleted(undefined)
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to change to delete this data?
        </p>
      </Modal>
    </AppLayout>
  );
};

export default HowItWork;
