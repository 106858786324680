import { BaseResponseProps } from "./config.type";
import { DiscountUserProperties } from "./discount.type";

export enum UserTypes {
  SET_USER = "SET_USER",
  SET_LOADING_USER = "SET_LOADING_USER",
  SET_LOADING_ACTION_USER = "SET_LOADING_ACTION_USER",
}

export interface CustomerState {
  customer: CustomerProps;
  userFetchLoading: boolean;
  userActionLoading: boolean;
}

export interface CustomerProps {
  customerId?: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  status: boolean;
  role: string;
  imageUrl?: string;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  statusLoading?: boolean;
  customerDiscounts?: DiscountUserProperties[];
}

export interface FetchAllCustomerResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: CustomerProps[];
  };
}

export interface FetchAllAnnualDataCustomer extends BaseResponseProps {
  payload: Array<number>;
}

export const initialCustomer: CustomerProps = {
  customerId: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  status: true,
  role: "customer",
};

export const initialUserFetchAll: FetchAllCustomerResponse = {
  code: "",
  message: "",
  payload: {
    count: 0,
    prev: "",
    next: "",
    results: [initialCustomer],
  },
};
