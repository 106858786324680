
import { capitalizeFirstLetter } from './text';
export const APP_AUTH_TOKEN = 'APP_AUTH_' + process.env.REACT_APP_ID;

export async function saveToken(token: string) {
  return localStorage.setItem(APP_AUTH_TOKEN, token)
}

export async function getToken() {
  return localStorage.getItem(APP_AUTH_TOKEN)
}

export async function removeToken() {
  localStorage.removeItem(APP_AUTH_TOKEN)
}

export const ROLES: {[key: string]: string} = {
  admin: 'Admin',
  // admin_manager: 'Manager',
  admin_chef: 'Chef',
  // admin_employee: 'Employee',
}

export function showRoleName(role: string) {
  if (ROLES[role]) {
    return ROLES[role];
  } else {
    return role.split('_').map(item => capitalizeFirstLetter(item)).join(' ')
  }
}
