import { BaseResponseProps } from './config.type';


export interface CustomerMessageProps {
  customermessageId: string;
  fullName: string;
  email: string;
  subject: string;
  message: string;
  status: boolean;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface FetchAllCustomerMessageResponse extends BaseResponseProps {
  payload: {
    count: number
    prev: string
    next: string
    results: CustomerMessageProps[]
  }
}

export const initialCustomerMessage: CustomerMessageProps = {
  customermessageId: '',
  fullName: '',
  email: '',
  subject: '',
  message: '',
  status: false,
  createdAt: new Date(),
  updatedAt: new Date(),
}