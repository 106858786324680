import { Space } from 'antd'
import React from 'react'
import HeaderSection from '../../../components/HeaderSection'
import AppLayout from '../../layout/AppLayout'
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton'
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type'
import AppCard from '../../../components/AppCard'
import DetailItem from '../../../components/DetailItem'
import SectionContent from '../../../components/SectionContent'
import { formatDate } from '../../../helpers/constant'
import { initialBanner, BannerProps } from '../../../types/banner.type'
import HeaderBannerItem from '../../../components/HeaderBannerItem'
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs'

interface IParams {
  bannerId: string
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<BannerProps, 'createdAt' | 'updatedAt'>
}

const BannerDetail = () => {
  const {setBreadcrumbDetails} = useDetailBreadcrumbs()
  const history = useHistory()
  const {bannerId} = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [banner, setBanner] = React.useState<BannerProps>(initialBanner)

  const handleClickEdit = () => {
    history.push(`/banner/${banner.bannerId}/edit`)
  }

  React.useEffect(() => {
    const fetchDetailBanner = async () => {
      try {
        setIsLoading(true)

        const res = await httpRequest.get<ResponseProps>('/banners/' + bannerId)

        setBanner(res.data.payload)

        setIsLoading(false)

        const bcDetails = [
          {
            field: 'bannerId',
            value: bannerId,
            label: res.data.payload.title,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false)
      }
    }
    fetchDetailBanner()
  }, [bannerId])

  return (
    <AppLayout>
      <HeaderSection
        icon="back"
        title="Banner Detail"
        subtitle="Manage your Banner data"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle="Banner Preview">
          <HeaderBannerItem rightImageUrl={banner.bannerImg} title={banner.title} subtitle={banner.subtitle} />
        </SectionContent>
        <SectionContent groupTitle="Banner Data">
          <DetailItem type="image" label="Image" content={banner.bannerImg} />
          <DetailItem label="Title" content={banner.title} />
          <DetailItem
            label="Subtitle"
            content={banner.subtitle}
          />
          <DetailItem
            label="Created at"
            content={formatDate(banner.createdAt)}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
}

export default BannerDetail