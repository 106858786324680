import React from "react";
import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import {
  AppstoreAddOutlined,
  MoreOutlined,
  SearchOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Image,
  Tag,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import styled from "styled-components";
import { initialMenu, MenuProps } from "../../types/menu.type";
import useFetchList from "../../hooks/useFetchList";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { TagProps } from "../../types/tag.type";
import { ECategoryType } from "../../types/category.type";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<MenuProps, "createdAt" | "updatedAt">;
}

const Menus = () => {
  const history = useHistory();

  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isModalTopPickVisible, setIsModalTopPickVisible] =
    React.useState<boolean>(false);
  const [isLoadingUpdateTopPick, setIsLoadingUpdateTopPick] =
    React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<MenuProps>(initialMenu);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<MenuProps>({
    endpoint: "menus",
    limit: 25,
  });

  console.log(data);
  const handleTopPickChange = async () => {
    try {
      setIsLoadingUpdateTopPick(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.menuId === tmpData.menuId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/menus/" + tmpData.menuId,
        {
          menuId: tmpData.menuId,
          isTopPick: !tmpData.isTopPick,
        }
      );

      newData = data.map((item) => {
        if (item.menuId === res.data.payload.menuId) {
          return {
            ...item,
            isTopPick: res.data.payload.isTopPick,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success(
        "Success change " + tmpData.name + " Best Seller Status."
      );

      setIsLoadingUpdateTopPick(false);
      setIsModalTopPickVisible(false);
      setTmpData(initialMenu);
    } catch (err: any) {
      message.error(err.data.message);
      setIsModalTopPickVisible(false);
      setTmpData(initialMenu);
      setIsLoadingUpdateTopPick(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdateStatus(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.menuId === tmpData.menuId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/menus/" + tmpData.menuId,
        {
          menuId: tmpData.menuId,
          isPublished: !tmpData.isPublished,
        }
      );

      newData = data.map((item) => {
        if (item.menuId === res.data.payload.menuId) {
          return {
            ...item,
            isPublished: res.data.payload.isPublished,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.name + " status.");

      setIsLoadingUpdateStatus(false);
      setIsModalVisible(false);
      setTmpData(initialMenu);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialMenu);
      setIsLoadingUpdateStatus(false);
    }
  };

  const handleCreateMenu = (menuType: string) => {
    history.push(`/menu/${menuType}/add`);
  };

  const { Option } = Select;

  const handleChangeStatus = (status: string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({
        ...oldVal,
        isPublished: status === "active" ? "1" : "0",
      }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
    }
  };

  const handleClickDetail = (e: MenuProps) => {
    history.push(`/menu/${e.menuId}`);
  };

  const handleClickAction = (props: MenuProps, key: string) => {
    if (key === "detail") {
      history.push(`/menu/${props.menuId}`);
    } else if (key === "edit") {
      history.push(
        "/menu/" +
          (props.category?.categoryType === ECategoryType.MEALPACK
            ? "meal-pack"
            : "meal") +
          "/" +
          props.menuId +
          "/edit"
      );
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      width: 120,
      render: (images: MenuProps["images"]) => {
        return (
          <Image
            width={120}
            height={70}
            style={{ objectFit: "cover" }}
            src={
              images && images.length > 0
                ? images[0].imageUrl || "/images/blur-image.jpeg"
                : "/images/blur-image.jpeg"
            }
            placeholder={
              <Image
                preview={false}
                src='/images/blur-image.jpeg'
                width={120}
                height={100}
              />
            }
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: MenuProps) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      render: (status: any, record: MenuProps) => (
        <>
          {record.category ? (
            <div>
              {record.category.categoryType === "MEALPACK" && (
                <TableOutlined style={{ marginRight: 10, color: "#FF4A50" }} />
              )}{" "}
              {record.category.categoryName}
            </div>
          ) : (
            "Not set"
          )}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: any, record: MenuProps) => (
        <>
          {
            <Switch
              loading={record.statusLoading}
              checked={record.isPublished}
              onChange={() => {
                setIsModalVisible(true);
                setTmpData(record);
              }}
            />
          }
        </>
      ),
    },
    {
      title: "Best Seller",
      key: "isTopPick",
      dataIndex: "isTopPick",
      render: (status: any, record: MenuProps) => (
        <>
          {
            <Switch
              loading={record.statusLoading}
              checked={record.isTopPick}
              onChange={() => {
                setIsModalTopPickVisible(true);
                setTmpData(record);
              }}
            />
          }
        </>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: MenuProps) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: MenuProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key='edit'>Edit Menu</Menu.Item>
      <Menu.Item key='detail'>Detail Menu</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<AppstoreAddOutlined />}
        title='Menu'
        subtitle='Manage your Menus'
        rightAction={
          <Space>
            <AppButton
              type='primary'
              onClick={() => {
                handleCreateMenu("meal");
              }}
            >
              Add Menu
            </AppButton>
            <AppButton
              type='primary'
              onClick={() => {
                handleCreateMenu("meal-pack");
              }}
            >
              Add MealPack Menu
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title='Update status confirmation'
        visible={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialMenu);
        }}
        okText='Yes'
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.name}"</b> status to{" "}
          <b>"{!tmpData.isPublished ? "Active" : "Inactive"}"</b>?.{" "}
          {!tmpData.isPublished}
        </p>
      </Modal>

      <Modal
        title='Update Best Seller confirmation'
        visible={isModalTopPickVisible}
        onOk={handleTopPickChange}
        onCancel={() => {
          setIsModalTopPickVisible(false);
          setTmpData(initialMenu);
        }}
        okText='Yes'
        confirmLoading={isLoadingUpdateTopPick}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.name}"</b> Best Seller to{" "}
          <b>"{!tmpData.isTopPick ? "Is Best Seller" : "Not Best Seller"}"</b>?.{" "}
          {!tmpData.isTopPick}
        </p>
      </Modal>
    </AppLayout>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default Menus;

function sortTags(tags: TagProps[]) {
  tags.sort((a, b) => a.name.localeCompare(b.name));
  return tags;
}
