import { Space } from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import { initialTag, TagProps } from "../../types/tag.type";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";

interface IParams {
  dietariesId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<TagProps, "createdAt" | "updatedAt">;
}

const AllergenDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { dietariesId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState<TagProps>(initialTag);

  const handleClickEdit = () => {
    history.push(`/allergen/${tag.tagId}/edit`);
  };

  React.useEffect(() => {
    const fetchDetailTag = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/tags/" + dietariesId
        );

        setTag(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: "tagId",
            value: dietariesId,
            label: res.data.payload.name,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDetailTag();
  }, [dietariesId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Dietaries Detail'
        subtitle='Manage your dietaries data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle='Dietaries Data'>
          <DetailItem label='Name' content={tag.name} />
          <DetailItem
            label='Status'
            content={tag.isPublished ? "Active" : "Inactive"}
          />
          <DetailItem label='Created at' content={formatDate(tag.createdAt)} />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default AllergenDetail;
