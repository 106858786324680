import { LeftOutlined } from '@ant-design/icons';
import { Divider, Form, FormInstance, Input, message, Radio, Select, Space } from 'antd';
import React from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { initialFAQ, FAQProps } from '../../types/faq.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import TextArea from 'antd/lib/input/TextArea';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';

interface IParams {
  faqId: string
}

interface ILocation {
  faqId: string
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<FAQProps, 'createdAt' | 'updatedAt'>
}

const FAQEdit: React.FC = () => {
  const {setBreadcrumbDetails} = useDetailBreadcrumbs()
  const history = useHistory()
  const location = useLocation<ILocation>()
  const { faqId } = useParams<IParams>();
  const formRef = React.useRef<FormInstance<Omit<FAQProps, 'createdAt' | 'updatedAt'>>>(null)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false)
  const [faq, setFAQ] = React.useState<FAQProps>(initialFAQ)

  const createFAQ = async (props: Omit<FAQProps, 'createdAt' | 'updatedAt' | 'adminId'>) => {
    try {
      setIsLoadingAction(true)

      await httpRequest.post('/faqs', props)
      message.success('Created successfully')

      history.push('/faq')

    } catch (error) {
      setIsLoadingAction(false)
    }
  }

  const updateFAQ = async (props: Omit<FAQProps, 'createdAt' | 'updatedAt'>) => {
    try {
      setIsLoadingAction(true)

      await httpRequest.patch('/faqs/' + faqId, props)
      message.success('Updated successfully')
      history.push('/faq')

    } catch (error) {
      setIsLoadingAction(false)
    }
  }

  const handleSubmit = async (values: Omit<FAQProps, 'createdAt' | 'updatedAt'>) => {
    if (faqId) {
      updateFAQ(values)
    } else {
      createFAQ(values)
    }
  }

  React.useEffect(() => {
    if (faqId) {
      const fetchFAQ = async () => {
        try {
          setIsLoading(true)

          const res = await httpRequest.get<ResponseProps>('/faqs/' + faqId)
          setFAQ(res.data.payload)
          setIsLoading(false)

          const bcDetails = [
            {
              field: 'faqId',
              value: faqId,
              label: res.data.payload.question,
            },
          ];
          setBreadcrumbDetails(bcDetails);

        } catch (error) {
          setIsLoading(false)
        }
      }

      fetchFAQ()
    }
  }, [faqId, location])

  return (
    <AppLayout>
      <HeaderSection
        icon="back"
        title={(faqId ? 'Edit' : 'Add') + ' FAQ'}
        subtitle="Manage your faq data"
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="faqForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={faqId ? faq : initialFAQ}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="FAQs Data"
            helpers={[
              {
                title: 'Information',
                content:
                  'Important will show the question in Home Page',
              },
            ]}
          >
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: 'The question is required.',
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Important"
              name="isImportant"
              rules={[
                {
                  required: true,
                  message: 'The important is required.',
                },
              ]}
            >
              <Radio.Group value={faq.isImportant || false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: 'The answer is required.',
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
}

export default FAQEdit