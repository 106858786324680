import { BaseResponseProps } from "./config.type";

export enum ECategoryType {
  MEALPACK = "MEALPACK",
  DEFAULT = "DEFAULT",
  MEAL = "MEAL",
  SNACK = "SNACK",
  SIDES = "SIDES",
  FAMILY_MEAL = "FAMILY_MEAL",
}

export type CategoryProps = {
  categoryId: string;
  categoryName: string;
  categoryType: ECategoryType;
  isPublished: boolean;
  order: number;
  subCategories: CategoryProps[];
  createdAt?: Date;
  updatedAt?: Date;
  statusLoading?: boolean;
  isMealpack?: boolean;
};

export interface FetchAllCategoryResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: CategoryProps[];
  };
}

export const initialCategory: CategoryProps = {
  categoryId: "",
  categoryName: "",
  categoryType: ECategoryType.DEFAULT,
  isPublished: true,
  order: 1,
  subCategories: [],
};
