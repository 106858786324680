import { Form, FormInstance, Input, message, Space } from 'antd';
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { initialHowItWork, HowItWorkProps } from '../../../types/howItWork.type';
import AppLayout from '../../layout/AppLayout';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import TextArea from 'antd/lib/input/TextArea';
import FormUploadImage from '../../../components/FormUploadImage';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';

interface IParams {
  howitworksId: string
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<HowItWorkProps, 'createdAt' | 'updatedAt'>
}

const HowItWorkEdit: React.FC = () => {
  const {setBreadcrumbDetails} = useDetailBreadcrumbs()
  const history = useHistory()
  const { howitworksId } = useParams<IParams>();
  const formRef = React.useRef<FormInstance<Omit<HowItWorkProps, 'createdAt' | 'updatedAt'>>>(null)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false)
  const [howitwork, setHowItWork] = React.useState<HowItWorkProps>(initialHowItWork)

  const [image, setImage] = React.useState<File | undefined>()
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();

  const createHowItWork = async (props: Omit<HowItWorkProps, 'createdAt' | 'updatedAt' | 'howitworksId' | 'statusLoading'>) => {
    try {
      setIsLoadingAction(true)

      const formData = new FormData();

      console.info('props', props);
      console.info('Object.keys(props)', Object.keys(props));
      Object.keys(props).forEach((key) => {
        if ((props as any)[key]) {
          formData.append(key, (props as any)[key])
        }
      })
      if (image) {
        formData.append('image', image)
      }
      // console.info('formData', JSON.stringify(formData))
      await httpRequest.post('/howitworks', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      message.success('Success create ')

      history.push('/how-it-work')

    } catch (error) {
      setIsLoadingAction(false)
    }
  }

  const updateHowItWork = async (props: Omit<HowItWorkProps, 'createdAt' | 'updatedAt' | 'statusLoading'>) => {
    try {
      setIsLoadingAction(true)

      const formData = new FormData();
      Object.keys(props).forEach((key) => {
        if ((props as any)[key]) {
          formData.append(key, (props as any)[key]);
        }
      });
      if (image) {
        formData.append('image', image);
      }
      await httpRequest.patch(
        '/howitworks/' + howitworksId,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      message.success('Success update ')
      history.push('/how-it-work')

    } catch (error) {
      setIsLoadingAction(false)
    }
  }

  const handleSubmit = async (values: Omit<HowItWorkProps, 'createdAt' | 'updatedAt' | 'statusLoading'>) => {
    console.info('values', values)
    if (howitworksId) {
      updateHowItWork(values)
    } else {
      createHowItWork(values)
    }
  }

  React.useEffect(() => {
    if (howitworksId) {
      const fetchHowItWork = async () => {
        try {
          setIsLoading(true)

          const res = await httpRequest.get<ResponseProps>('/howitworks/' + howitworksId)
          setHowItWork(res.data.payload)
          setImageUrl(res.data.payload.howitworksImg)
          setIsLoading(false)

          const bcDetails = [
            {
              field: 'howitworksId',
              value: howitworksId,
              label: res.data.payload.headline,
            },
          ];
          setBreadcrumbDetails(bcDetails);

        } catch (error) {
          setIsLoading(false)
        }
      }

      fetchHowItWork()
    }
  }, [howitworksId])

  return (
    <AppLayout>
      <HeaderSection
        icon="back"
        title={(howitworksId ? 'Edit' : 'Add') + ' How It Work'}
        subtitle="Manage your How It Work data"
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="howitworksForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={howitworksId ? howitwork : initialHowItWork}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Data"
            helpers={[
              {
                title: 'Information',
                content:
                  'The position of how it works can be changed on the How it work list by dragging the row',
              },
            ]}
          >
            <FormUploadImage 
              maxSizeInMB={1}
              imageUrl={imageUrl || ''}
              onChange={(file) => setImage(file)} />

            <Form.Item
              label="Title"
              name="headline"
              rules={[
                {
                  required: true,
                  message: 'The title is required.',
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  setHowItWork({
                    ...howitwork,
                    headline: e.target.value,
                  })
                }
                value={howitwork.headline}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="subtitle"
              rules={[
                {
                  required: true,
                  message: 'The description is required.',
                },
              ]}
            >
              <TextArea
                onChange={(e) =>
                  setHowItWork({
                    ...howitwork,
                    subtitle: e.target.value,
                  })
                }
                value={howitwork.subtitle}
              />
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
}

export default HowItWorkEdit
