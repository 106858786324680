import { TimePicker, Dropdown, Space, Button, Menu, Divider } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppCard from "../../components/AppCard";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import SectionContent from "../../components/SectionContent";
import { LocationProps, initialLocation } from "../../types/location.type";
import DetailItem from "../../components/DetailItem";
import moment from "moment";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import AppMap from "../../components/AppMap";

interface IParams {
  locationId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<LocationProps, "createdAt" | "updatedAt">;
}

const LocationDetail: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { locationId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [locationData, setLocationData] =
    React.useState<LocationProps>(initialLocation);

  React.useEffect(() => {
    const fetchLocationDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/locations/" + locationId
        );

        if (
          res.data.payload.locationGeoloc &&
          typeof res.data.payload.locationGeoloc === "string"
        ) {
          res.data.payload.locationGeoloc = JSON.parse(
            res.data.payload.locationGeoloc
          );
          if (typeof res.data.payload.locationGeoloc?.lat === "string") {
            res.data.payload.locationGeoloc.lat = Number(
              res.data.payload.locationGeoloc.lat
            );
          }
          if (typeof res.data.payload.locationGeoloc?.lng === "string") {
            res.data.payload.locationGeoloc.lng = Number(
              res.data.payload.locationGeoloc.lng
            );
          }
        }

        setLocationData(res.data.payload);
        setIsLoading(false);

        const bcDetails = [
          {
            field: "locationId",
            value: locationId,
            label: res.data.payload.locationName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchLocationDetail();
  }, [locationId]);

  const locationType = (
    <Menu defaultValue={locationData.locationType}>
      <Menu.Item key="main_kitchen">Main Kitchen</Menu.Item>
      <Menu.Item key="outlet">Outlet</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon="back"
        title="Pickup Location Detail"
        subtitle="Manage your pickup location data"
        rightAction={
          <Space>
            <AppButton
              type="primary"
              onClick={() =>
                history.push(`/pickup-location/${locationId}/edit`)
              }
            >
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent
          groupTitle="Location Data"
          helpers={[
            {
              title: "",
              content: "",
            },
          ]}
        >
          <DetailItem
            label="Location Name"
            content={locationData.locationName}
          />

          <DetailItem
            label="Location Code"
            content={locationData.locationCode}
          />

          <div style={{ marginBottom: 20 }}>
            <AppMap
              coords={[
                {
                  isMain: locationData.locationType === "main_kitchen",
                  lat: Number(locationData.locationGeoloc?.lat),
                  lng: Number(locationData.locationGeoloc?.lng),
                },
              ]}
            />
          </div>

          <DetailItem
            label="Location Address"
            content={locationData.locationAddress}
          />

          <DetailItem label="Contact" content={locationData.locationContact} />
        </SectionContent>

        <Divider />

        <SectionContent
          groupTitle="Location Settings"
          helpers={[
            {
              title: "",
              content: "",
            },
          ]}
        >
          <DetailItem
            label="Location Type"
            content={
              <Dropdown overlay={locationType} disabled>
                <Button
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {locationData.locationType === "outlet"
                    ? "Outlet"
                    : "Main Kitchen"}
                </Button>
              </Dropdown>
            }
          />
        </SectionContent>

        <Divider />

        <SectionContent
          groupTitle="Availability"
          helpers={[
            {
              title: "Information",
              content:
                "Availability shows the available time to pickup on the Main Kitchen or Outlet",
            },
          ]}
        >
          <DetailItem
            label="Availability"
            content={
              <div>
                {locationData.availabilityPickups.map((item, index) => {
                  return (
                    <TimePicker.RangePicker
                      style={{
                        width: "60%",
                        marginBottom: "1%",
                        marginRight: "2%",
                      }}
                      key={index}
                      value={[
                        moment(item.startAt, "HH:mm"),
                        moment(item.endAt, "HH:mm"),
                      ]}
                      use12Hours
                      format="h:mm A"
                      minuteStep={15}
                      disabled
                    />
                  );
                })}
              </div>
            }
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default LocationDetail;
