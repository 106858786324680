import {
  CloseOutlined,
  DeleteOutlined,
  TableOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Upload,
  Image,
  Button,
  InputNumber,
  Table,
  Divider,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import {
  FetchAllMenuResponse,
  initialMenu,
  MenuProps,
  VariantProps,
} from '../../types/menu.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { generateFormRules } from '../../helpers/formRules';
import { FetchAllTagResponse, TagProps } from '../../types/tag.type';
import { generateQueryString } from '../../helpers/generateQueryString';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { getErrorMessage } from '../../helpers/errorHandler';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import {
  CategoryProps,
  ECategoryType,
  FetchAllCategoryResponse,
} from '../../types/category.type';
import _ from 'lodash';
import useFetchList from '../../hooks/useFetchList';
import Text from 'antd/lib/typography/Text';

interface IParams {
  menuType: string;
  menuId: string;
}

interface ILocation {
  menuType: string;
  menuId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<MenuProps, 'createdAt' | 'updatedAt'>;
}

interface ResponseMealpackProps extends BaseResponseProps {
  payload: MenuProps[];
}

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const categoryQuery = {
  isPublished: 'active',
};

const { Option } = Select;

const dietaryQuery = {
  isPublished: 'active',
  type: 'allergen',
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const MenuEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { menuType, menuId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<MenuProps, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [isImage, setIsImage] = React.useState<boolean>(true);
  const [isMaxChar, setIsMaxChar] = React.useState<boolean>(true);
  const [isMinMealPack, setIsMinMealPack] = React.useState<boolean>(true);
  const [isCategory, setIsCategory] = React.useState<boolean>(true);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [menu, setMenu] = React.useState<MenuProps>(initialMenu);
  const [maxChar, setMaxChar] = React.useState<number>(0);
  const [isMealpack, setIsMealpack] = React.useState<boolean>(false);
  const [isLoadingMenuCategories, setIsLoadingMenuCategories] =
    React.useState<boolean>(false);

  const [categories, setCategories] = React.useState<Array<CategoryProps>>([]);
  const [dietaries, setDietaries] = React.useState<Array<TagProps>>([]);

  const [menuChilds, setMenuChilds] = React.useState<Array<MenuProps>>([]);
  const [selectedMenuChilds, setSelectedMenuChilds] = React.useState<
    Array<MenuProps>
  >([]);
  const [isModalMealpack, setIsModalMealpack] = React.useState<boolean>(false);
  const [isLoadingMenuMealpack, setIsLoadingMenuMealpack] =
    React.useState<boolean>(false);

  const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
    url: '',
    uid: '',
    name: '',
  });
  const [images, setImages] = React.useState<
    UploadChangeParam<UploadFile<any>>['fileList']
  >([]);
  const [willBeDeletedImage, setWillBeDeletedImage] =
    React.useState<UploadFile<any>>();
  const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);

  const { data, setData } = useFetchList<MenuProps>({
    endpoint: 'menus',
    limit: 200,
  });
  const [mealPackChilds, setMealPackChilds] = React.useState<Array<MenuProps>>(
    data.filter(
      (item) => item.category?.categoryType !== ECategoryType.MEALPACK
    )
  );

  const createMenu = async (
    props: Omit<MenuProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);
      console.log(props);

      let mealPackCategory = categories.find(
        (item) => item.categoryType === ECategoryType.MEALPACK
      );

      let data = {
        ...props,
        category: menuType === 'meal' ? menu.category : mealPackCategory,
        tags: menu.allergens,
        categoryId:
          menuType === 'meal'
            ? menu.category?.categoryId
            : mealPackCategory?.categoryId,
        variants: isMealpack ? [] : menu.variants,
      };

      if (isMealpack) {
        data = {
          ...data,
          maxQty: props.maxQty,
          price: props.price,
          itemIds:
            selectedMenuChilds
              .filter((item) => item.isSelected)
              .map((item) => item.menuId || '') || [],
        };
        const item = data.itemIds ? data.itemIds.length : undefined;
        if (item !== undefined && item < 1) {
          setIsMinMealPack(false);
          return null;
        }
      } else {
        if (menu.variants.some(hasNull)) {
          return null;
        }
        if (!menu?.categoryId) {
          setIsCategory(false);
          return null;
        }
      }

      // if (images.length === 0) {
      //   setIsImage(false)
      //   return null
      // }

      if (maxChar > 500) {
        setIsMaxChar(false);
        return null;
      }

      const res: any = await httpRequest.post(
        '/menus',
        {
          ...data,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      await uploadNewImage(res.data.payload.menuId);
      setMenu(initialMenu);

      message.success('Success create ' + props.name);
      history.push('/menu');
    } catch (error) {
    } finally {
      setIsLoadingAction(false);
    }
  };

  const updateMenu = async (
    props: Omit<MenuProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      let dataToBeSent = {
        ...props,
        category: menu.category,
        categoryId: menu.category?.categoryId,
        tags: menu.allergens,
        variants: menu.variants,
      };

      if (isMealpack) {
        dataToBeSent = {
          ...dataToBeSent,
          maxQty: props.maxQty,
          price: props.price,
          itemIds:
            selectedMenuChilds
              .filter((item) => item.isSelected)
              .map((item) => item.menuId || '') || [],
        };
        const item = dataToBeSent.itemIds
          ? dataToBeSent.itemIds.length
          : undefined;
        if (item !== undefined && item < 1) {
          setIsMinMealPack(false);
          return null;
        }
      } else {
        if (menu.variants.some(hasNull)) {
          return null;
        }
        if (!menu?.categoryId) {
          setIsCategory(false);
          return null;
        }
      }

      // if (images.length === 0) {
      //   setIsImage(false)
      //   return null
      // }

      if (maxChar > 500) {
        setIsMaxChar(false);
        return null;
      }

      if (menuId) {
        await Promise.all([
          httpRequest.patch('/menus/' + menuId, dataToBeSent),
          uploadNewImage(menuId),
        ]);
      }
      setMenu(initialMenu);
      message.success('Success update ' + props.name + ' data');
      history.push('/menu');
    } catch (error) {
      setIsLoadingAction(false);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const uploadNewImage = async (menuId: string) => {
    setIsLoadingAction(true);

    const newImages = images.filter((img) => img.originFileObj);
    if (newImages.length > 0) {
      const promises = [];
      for (const img of newImages) {
        let formData = new FormData();
        formData.append('image', img.originFileObj as Blob);

        const promise = httpRequest.put(
          '/menus/' + menuId + '/upload-image',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        promises.push(promise);
      }
      await Promise.all(promises);
    }

    setIsLoadingAction(false);
  };

  const handleDeleteImage = async () => {
    try {
      setIsLoadingDeleteImage(true);
      if (willBeDeletedImage && !willBeDeletedImage.originFileObj) {
        const fileId = willBeDeletedImage?.uid;
        await httpRequest.delete('/menus/' + menuId + '/' + fileId);
      }

      let current = images.filter(
        (item) => item.uid !== willBeDeletedImage?.uid
      );

      setImages(current);
      formRef.current?.setFieldsValue({
        images:
          current.length > 0
            ? [{ fileId: current[0].uid, imageUrl: current[0].url }]
            : [],
      });
      setSelectedImage(
        current.length > 0
          ? current[0]
          : {
              url: '',
              uid: '',
              name: '',
            }
      );

      setIsLoadingDeleteImage(false);
      setWillBeDeletedImage(undefined);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoadingDeleteImage(false);
    }
  };

  const handleSubmit = async (
    values: Omit<MenuProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    if (menuId) {
      updateMenu(values);
    } else {
      createMenu(values);
    }
  };

  const handleChangeVariant = (value: string, index: number, key: string) => {
    if (key === 'price') {
      menu.variants[index].price = parseFloat(value);
    } else {
      menu.variants[index].label = value;
    }
    setMenu((oldVal) => {
      return {
        ...oldVal,
      };
    });
  };

  const handleClickVariant = async (index: number, key: string) => {
    if (key === 'delete') {
      const tobeDeletedVariant = menu.variants.splice(index, 1);
      if (
        index === menu.variants.length &&
        tobeDeletedVariant[0].isDefaultPrice
      ) {
        menu.variants[index - 1].isDefaultPrice = true;
      }
      setMenu((oldVal) => {
        return {
          ...oldVal,
          variants: menu.variants,
        };
      });
    } else {
      menu.variants.forEach((variant, idx) => {
        if (idx === index) {
          variant.isDefaultPrice = true;
        } else {
          variant.isDefaultPrice = false;
        }
        setMenu((oldVal) => {
          return {
            ...oldVal,
          };
        });
      });
    }
  };

  const handleAddVariant = () => {
    const newMenuVariants = menu.variants.concat({
      price: undefined,
      label: '',
      isDefaultPrice: false,
    });

    setMenu((oldVal) => {
      return {
        ...oldVal,
        variants: newMenuVariants,
      };
    });
  };

  React.useEffect(() => {
    if (menuType === 'meal') {
      setIsMealpack(false);
    } else {
      setIsMealpack(true);
      setMealPackChilds(
        data.filter(
          (item) => item.category?.categoryType !== ECategoryType.MEALPACK
        )
      );
    }
  }, [menuType]);

  const hasNull = (element: any, index: any, array: any) => {
    return Object.keys(element).some(function (key) {
      let isNotNull = false;
      if (typeof element[key] === 'string') {
        if (element[key].length === 0) {
          isNotNull = true;
        }
      }
      if (typeof element[key] === 'number') {
        if (isNaN(element[key])) {
          isNotNull = true;
        }
      }

      return element[key] === null || element[key] === undefined || isNotNull;
    });
  };

  React.useEffect(() => {
    setMaxChar(menu.description.replace(/(<([^>]+)>)/gi, '').length);
    if (maxChar <= 500) {
      setIsMaxChar(true);
    }
  }, [menu]);

  React.useEffect(() => {
    setMenuChilds(
      data.filter(
        (item) => item.category?.categoryType !== ECategoryType.MEALPACK
      )
    );
    setMealPackChilds(
      data.filter(
        (item) => item.category?.categoryType !== ECategoryType.MEALPACK
      )
    );
  }, [isModalMealpack]);

  const handleChangeCategory = (value: any) => {
    const category = categories.find(
      (category) => category.categoryId === value
    );
    setIsCategory(true);
    if (category?.categoryType === ECategoryType.MEALPACK) {
      setIsMealpack(true);
    } else {
      setIsMealpack(false);
    }

    setMenu((oldVal) => {
      return {
        ...oldVal,
        category,
        categoryId: category?.categoryId,
      };
    });
  };

  const handleChangeDietary = (value: any) => {
    const selectedDietaries = value.map((selectedDietariesId: string) => {
      const foundDietary = dietaries.find(
        (dietary) => dietary.tagId === selectedDietariesId
      );
      return foundDietary;
    });

    setMenu((oldVal) => {
      return {
        ...oldVal,
        allergens: selectedDietaries,
      };
    });
  };

  const readFile = (file: UploadFile<any>): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      if (file.originFileObj) {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = function (event) {
          if (event.target && event.target.result) {
            const url =
              event && event.target && event.target.result
                ? (event.target.result as any)
                : undefined;
            resolve(url);
            // setImages(
            //   e.fileList.map((item) => {
            //     if (item.uid === file.uid) {
            //       item = {
            //         ...item,
            //         url,
            //       };
            //     }
            //     return item;
            //   })
            // );
            // if (!selectedImage || (selectedImage && !selectedImage.uid)) {
            //   setSelectedImage({ ...e.fileList[0], url });
            // }
          } else {
            resolve(undefined);
          }
        };
      } else {
        resolve(undefined);
      }
    });
  };

  const handleChangeImages = async (e: UploadChangeParam<UploadFile<any>>) => {
    let isImageInvalid = false;
    e.fileList.forEach((file) => {
      if (!file || (file && !file.size)) return;
      const isLtMaxSize = (file?.size || 0) / 1024 / 1024 < 1;
      if (!isLtMaxSize) {
        isImageInvalid = true;
        message.error(`Image must smaller than ${1}MB!`);
      }
    });

    if (!isImageInvalid) {
      // setIsImage(true)
      setImages(e.fileList);

      const tmpListImage: Array<UploadFile<any> & { url?: string }> = [];
      for (const file of e.fileList) {
        if (file.originFileObj) {
          const url = await readFile(file);
          console.info('url', url);
          tmpListImage.push({
            ...file,
            url: url || '',
          });

          // const reader = new FileReader();
          // reader.readAsDataURL(file.originFileObj);
          // reader.onloadend = function (event) {
          //   if (event.target && event.target.result) {
          //     const url =
          //       event && event.target && event.target.result
          //         ? (event.target.result as any)
          //         : undefined;

          // };
        }
      }

      setImages(tmpListImage);
      if (!selectedImage || (selectedImage && !selectedImage.uid)) {
        setSelectedImage(tmpListImage[0]);
      }
    }
  };

  React.useEffect(() => {
    if (menuId) {
      const fetchMenuDetail = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>('/menus/' + menuId);
          console.log('res.data.payload', res.data.payload);
          const dataImages = (res.data.payload.images || []).map((item) => ({
            url: item.imageUrl || '',
            uid: item.fileId,
            name: item.imageUrl || '',
          }));

          setImages(dataImages as any[]);
          const menu: MenuProps = {
            name: res.data.payload.name,
            description: res.data.payload.description,
            isPublished: res.data.payload.isPublished,
            isTopPick: res.data.payload.isTopPick,
            variants: res.data.payload.variants,
            allergens: res.data.payload.allergens,
            categoryId: res.data.payload.categoryId,
            category: res.data.payload.category,
            images: res.data.payload.images,
            bestSeller: res.data.payload.bestSeller,
            itemIds: res.data.payload.itemIds,
            isSelected: res.data.payload.isSelected,
            items: res.data.payload.items,
            maxQty: res.data.payload.maxQty,
            menuId: res.data.payload.menuId,
            price: res.data.payload.price,
            tags: res.data.payload.tags,
          };
          setMenu(menu);

          if (dataImages.length > 0) {
            setSelectedImage(dataImages[0]);
          }

          const bcDetails = [
            {
              field: 'menuId',
              value: menuId,
              label: res.data.payload.name,
            },
          ];
          setBreadcrumbDetails(bcDetails);

          if (
            res.data.payload.items &&
            res.data.payload.itemIds &&
            res.data.payload.itemIds.length > 0
          ) {
            setIsMealpack(true);

            setSelectedMenuChilds(
              res.data.payload.items.map((item) => {
                return {
                  ...item,
                  isSelected: true,
                };
              })
            );

            setMealPackChilds(
              res.data.payload.items.map((item) => {
                return {
                  ...item,
                  isSelected: true,
                };
              })
            );

            setData(
              res.data.payload.items.map((item) => {
                return {
                  ...item,
                  isSelected: true,
                };
              })
            );
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      fetchMenuDetail();
    }

    const getCategoryData = async () => {
      try {
        setIsLoadingMenuCategories(true);
        const categoryRes = await httpRequest.get<FetchAllCategoryResponse>(
          `/categories${generateQueryString(categoryQuery)}`
        );
        setCategories(categoryRes.data.payload.results);
      } catch (error) {
        message.warn('Failed get categories');
        console.error(error);
      } finally {
        setIsLoadingMenuCategories(false);
      }
    };

    const getDietariesData = async () => {
      const dietaryRes = await httpRequest.get<FetchAllTagResponse>(
        `/tags${generateQueryString(dietaryQuery)}`
      );
      console.log('dietaryRes', dietaryRes);
      setDietaries(dietaryRes.data.payload.results);
    };

    getCategoryData();
    getDietariesData();
  }, [menuId, location]);

  const handleRemoveSingleMenuChild = (
    menu: MenuProps,
    isOutside?: boolean
  ) => {
    const newMenuChilds = menuChilds
      .map((item) => {
        if (item.menuId === menu.menuId) {
          return {
            ...item,
            isSelected: false,
          };
        } else {
          return { ...item };
        }
      })
      .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));

    if (isOutside) {
      const newMenuSelectedChilds = selectedMenuChilds
        .map((item) => {
          if (item.menuId === menu.menuId) {
            return {
              ...item,
              isSelected: false,
            };
          } else {
            return { ...item };
          }
        })
        .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));

      setSelectedMenuChilds(newMenuSelectedChilds);

      const newMealPackChilds = mealPackChilds
        .map((item) => {
          if (item.menuId === menu.menuId) {
            return {
              ...item,
              isSelected: false,
            };
          } else {
            return { ...item };
          }
        })
        .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));
      setMealPackChilds(newMealPackChilds);
      setData(newMealPackChilds);
    }

    const newMealPackChilds = mealPackChilds
      .map((item) => {
        if (item.menuId === menu.menuId) {
          return {
            ...item,
            isSelected: false,
          };
        } else {
          return { ...item };
        }
      })
      .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));
    setMealPackChilds(newMealPackChilds);
    setData(newMealPackChilds);

    setMenuChilds(newMenuChilds);
  };

  const handleAddSingleMenuChild = (menu: MenuProps) => {
    const newMenuChilds = menuChilds
      .map((item) => {
        if (item.menuId === menu.menuId) {
          return {
            ...item,
            isSelected: true,
          };
        } else {
          return { ...item, isSelected: item.isSelected || false };
        }
      })
      .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));
    setMenuChilds(newMenuChilds);

    const newMealPackChilds = mealPackChilds
      .map((item) => {
        if (item.menuId === menu.menuId) {
          return {
            ...item,
            isSelected: true,
          };
        } else {
          return { ...item, isSelected: item.isSelected || false };
        }
      })
      .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));

    console.log('newMealPackChilds', newMealPackChilds);

    setMealPackChilds(newMealPackChilds);
    setData(newMealPackChilds);
  };

  const handleConfirmAddMenuChild = () => {
    setSelectedMenuChilds(menuChilds);
    setMealPackChilds(mealPackChilds);
    setData(mealPackChilds);
    setIsModalMealpack(false);
  };

  const columnsSelectMenu = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_: any, item: MenuProps) => (
        <>
          {item.isSelected ? (
            <AppButton
              type='primary'
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveSingleMenuChild(item)}
            />
          ) : (
            <AppButton
              type='primary'
              onClick={() => handleAddSingleMenuChild(item)}
            >
              Add
            </AppButton>
          )}
        </>
      ),
    },
  ];

  const getMenuMealpack = async (selectedData?: MenuProps[]) => {
    setIsLoadingMenuMealpack(true);
    try {
      const res = await httpRequest.get<ResponseMealpackProps>(
        'menus/get-menu-mealpack'
      );

      if (res && res.data.payload) {
        setMenuChilds(
          res.data.payload.map((item) => ({ ...item, isSelected: false }))
        );
      }
    } catch (error) {
      console.error('failed get menu for meal pack ', error);
    } finally {
      setIsLoadingMenuMealpack(false);
    }
  };

  React.useEffect(() => {
    if (isMealpack) {
      getMenuMealpack();
    }
  }, [isMealpack]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={
          (menuId ? 'Edit' : 'Add') +
          (menuType === 'meal' ? ' Menu' : ' MealPack Menu')
        }
        subtitle={
          menuType === 'meal'
            ? 'Manage your menu data'
            : 'Manage your mealpack menu data'
        }
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='menuForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={menuId ? menu : initialMenu}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Menu Information'
            helpers={[
              {
                title: 'Information',
                content:
                  'Image either jpg, png or jpeg; max size 1MB; recommended resolution is 500x300px; Best Seller will make the Menu shows in Home page',
              },
            ]}
          >
            <React.Fragment>
              <Space
                style={{
                  width: '100%',
                  background: '#f2f2f2',
                  justifyContent: 'center',
                }}
              >
                <Image
                  preview={false}
                  width='100%'
                  height={250}
                  src={selectedImage.url || '/images/select-image.jpg'}
                  fallback={'/images/blur-image.jpeg'}
                  style={{ objectFit: 'cover' }}
                  placeholder={
                    <Image
                      preview={false}
                      src='/images/blur-image.jpeg'
                      width='100%'
                      height={200}
                      style={{ objectFit: 'cover' }}
                    />
                  }
                />
              </Space>
              <Space direction='horizontal'>
                {images.map((img, index) => (
                  <div
                    style={
                      selectedImage.uid === img.uid
                        ? {
                            border: 5,
                            borderStyle: 'solid',
                            borderColor: '#FF4A50',
                            position: 'relative',
                          }
                        : { position: 'relative' }
                    }
                  >
                    <Image
                      onClick={() => {
                        setSelectedImage(img);
                      }}
                      preview={false}
                      width={100}
                      height={100}
                      src={
                        img.url || img.thumbUrl || '/images/select-image.jpg'
                      }
                      fallback={'/images/blur-image.jpeg'}
                      style={{ objectFit: 'cover' }}
                      placeholder={
                        <Image
                          preview={false}
                          src='/images/blur-image.jpeg'
                          width={100}
                          height={100}
                          style={{ objectFit: 'cover' }}
                        />
                      }
                    />
                    <div
                      onClick={() => {
                        setWillBeDeletedImage(img);
                      }}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'grey',
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        paddingRight: 5,
                        paddingLeft: 5,
                        zIndex: 100,
                      }}
                    >
                      <CloseOutlined width={20} height={20} color='#FFFFFF' />
                    </div>
                  </div>
                ))}
              </Space>
            </React.Fragment>
            <Form.Item
              label='Image'
              name='image'
              rules={generateFormRules('Image', ['required'])}
              initialValue={
                menu.images && menu.images.length
                  ? menu.images[0].imageUrl
                  : undefined
              }
            >
              <CustomSpace
                style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}
                wrap
              >
                <CustomUpload
                  accept='.jpg,.jpeg,.png'
                  beforeUpload={(file) => {
                    // console.info('file', file);
                    // const isLtMaxSize = file.size / 1024 / 1024 < 1;
                    // if (!isLtMaxSize) {
                    //   message.error(`Image must smaller than ${1}MB!`);
                    // }
                    return false;
                  }}
                  fileList={images}
                  onChange={handleChangeImages}
                  onRemove={(file) => {
                    setWillBeDeletedImage(file);
                    return false;
                  }}
                  showUploadList={false}
                  multiple={true}
                >
                  <Button
                    type='primary'
                    danger
                    icon={<UploadOutlined />}
                    style={{ width: '100%' }}
                  >
                    Upload Image
                  </Button>
                </CustomUpload>
              </CustomSpace>
            </Form.Item>
            {/* <Text type="danger" style={{ display: !isImage ? "block" : "none" }}>Image is required </Text> */}
            <Form.Item
              label='Menu name'
              name='name'
              rules={generateFormRules('Menu name', ['required'])}
            >
              <Input
                placeholder='Enter menu name'
                value={menu.name}
                onChange={(e) =>
                  setMenu({
                    ...menu,
                    name: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label='Description'
              name='description'
              rules={generateFormRules('Description', ['required'])}
            >
              <CustomQuill
                value={menu.description}
                onChange={(val) =>
                  setMenu({
                    ...menu,
                    description: val,
                  })
                }
                modules={quillModules}
                formats={quillFormats}
              />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Text
                type='danger'
                style={{ display: !isMaxChar ? 'block' : 'none' }}
              >
                You have exceeded the maximum number of 500 character
              </Text>
              <Text
                type={maxChar > 500 ? 'danger' : 'secondary'}
                style={{ marginLeft: 'auto' }}
              >
                Character {maxChar}/500
              </Text>
            </div>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle='Menu Setting'
            helpers={[
              {
                title: 'Information',
                content: 'Menu Setting',
              },
            ]}
          >
            <Form.Item
              label='Category'
              name='categoryId'
              rules={
                !isMealpack
                  ? generateFormRules('Category', ['required'])
                  : undefined
              }
              style={{ marginTop: 15, marginBottom: 8 }}
            >
              <Select
                allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                style={{ width: '100%', zIndex: 2 }}
                placeholder='Please select'
                onChange={handleChangeCategory}
                key={'category'}
                defaultValue={menuType === 'meal' ? '' : ECategoryType.MEALPACK}
                value={
                  menu.category?.categoryId || menuType === 'meal'
                    ? menu.category?.categoryName
                    : ECategoryType.MEALPACK
                }
                loading={isLoadingMenuCategories}
                disabled={menuType === 'meal' ? false : true}
              >
                {menuType === 'meal'
                  ? categories
                      .filter(
                        (item) => item.categoryType !== ECategoryType.MEALPACK
                      )
                      .map((category, index) => {
                        return (
                          <Option
                            key={`tag${index}`}
                            value={String(category.categoryId)}
                          >
                            {category.categoryName}
                          </Option>
                        );
                      })
                  : categories
                      .filter(
                        (item) => item.categoryType === ECategoryType.MEALPACK
                      )
                      .map((category, index) => {
                        return (
                          <Option
                            key={`tag${index}`}
                            value={String(category.categoryId)}
                          >
                            {category.categoryType ===
                              ECategoryType.MEALPACK && (
                              <TableOutlined
                                style={{ marginRight: 10, color: '#FF4A50' }}
                              />
                            )}{' '}
                            {category.categoryName}
                          </Option>
                        );
                      })}
              </Select>
            </Form.Item>

            {menuType === 'meal' ? (
              <>
                <div style={{ opacity: 0.8, marginTop: 15, marginBottom: 8 }}>
                  Dietaries
                </div>
                <Select
                  mode='multiple'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentElement}
                  style={{ width: '100%', marginBottom: 15 }}
                  placeholder='Choose diateries'
                  onChange={(value) => handleChangeDietary(value)}
                  value={
                    menu.allergens
                      ? menu.allergens.map((item) => item.tagId || '')
                      : []
                  }
                >
                  {dietaries.map((dietary, index) => {
                    return (
                      <Option
                        key={`dietary${index}`}
                        value={String(dietary.tagId)}
                      >
                        {dietary.name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            ) : (
              false
            )}

            {isMealpack ? (
              <>
                <Form.Item
                  label='Max Order Qty.'
                  name='maxQty'
                  style={{ width: '100%' }}
                  rules={generateFormRules('Max Qty', ['required'])}
                >
                  {/* <InputNumber
                    style={{ width: "100%" }}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    onChange={(e) =>
                      setMenu({
                        ...menu,
                        maxQty: Number(e),
                      })
                    }
                  /> */}
                  <Input
                    // prefix='$'
                    value={menu.maxQty}
                    name={`maxQty`}
                    key={`maxQty`}
                    type='number'
                    step='1'
                    min={0}
                    onChange={(e) =>
                      setMenu({
                        ...menu,
                        maxQty: Number(e),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  label='Price'
                  name='price'
                  rules={generateFormRules('Meal Packs Price', ['required'])}
                >
                  <Input
                    prefix='$'
                    value={menu.price}
                    name={`priceMealpack`}
                    key={`price`}
                    type='number'
                    step='0.1'
                    min={0}
                    onChange={(e) =>
                      setMenu({
                        ...menu,
                        price: Number(e),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label='Meal Packs Menu' name='itemIds'>
                  {selectedMenuChilds.filter((item) => item.isSelected).length >
                  0 ? (
                    selectedMenuChilds
                      .filter((item) => item.isSelected)
                      .map((item, idx) => (
                        <div
                          key={idx}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '15px 0',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          <span>{item.name}</span>
                          <AppButton
                            type='primary'
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              handleRemoveSingleMenuChild(item, true)
                            }
                          ></AppButton>
                        </div>
                      ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '15px 0',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <div style={{ textAlign: 'center', opacity: 0.6 }}>
                        Menu for meal packs empty
                      </div>
                    </div>
                  )}

                  <Text
                    type='danger'
                    style={{ display: !isMinMealPack ? 'block' : 'none' }}
                  >
                    Minimum 1 menu for mealpack
                  </Text>

                  <AppButton
                    style={{ marginTop: 15 }}
                    type='primary'
                    onClick={() => {
                      setIsMinMealPack(true);
                      setIsModalMealpack(true);
                    }}
                  >
                    Add Menu for Meal Packs
                  </AppButton>
                </Form.Item>
              </>
            ) : (
              <MenuVariantInput
                variants={menu.variants}
                onAddVariant={handleAddVariant}
                onChangeText={handleChangeVariant}
                onClickVariant={handleClickVariant}
              />
            )}

            <Form.Item
              label='Status'
              name='isPublished'
              rules={generateFormRules('Status', ['required'])}
            >
              <Radio.Group value={menu.isPublished}>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='Best Seller'
              name='isTopPick'
              rules={generateFormRules('Best Seller', ['required'])}
            >
              <Radio.Group value={menu.isTopPick}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>

      <Modal
        title='Confirmation'
        visible={!!willBeDeletedImage}
        onOk={handleDeleteImage}
        onCancel={() => {
          setWillBeDeletedImage(undefined);
        }}
        okText='Yes'
        confirmLoading={isLoadingDeleteImage}
        okButtonProps={{ type: 'primary' }}
      >
        <p>Are you sure want to delete this image?</p>
      </Modal>

      <Modal
        title='Choose menu for 10 Meal Packs'
        visible={isModalMealpack}
        onOk={handleConfirmAddMenuChild}
        onCancel={() => {
          setIsModalMealpack(false);
        }}
        okText={`Add ${
          menuChilds.filter((item) => item.isSelected).length
        } Menu`}
        okButtonProps={{ type: 'primary' }}
        width={800}
      >
        <div
          style={{
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          <Table
            loading={isLoading}
            columns={columnsSelectMenu}
            dataSource={mealPackChilds.filter(
              (item) => item.category?.categoryType !== ECategoryType.MEALPACK
            )}
            pagination={false}
          />
        </div>
      </Modal>
    </AppLayout>
  );
};

interface MenuVariantInputProps {
  variants: Array<VariantProps>;
  onAddVariant: () => void;
  onChangeText: (value: string, index: number, key: 'price' | 'label') => void;
  onClickVariant: (index: number, key: 'delete' | 'default') => void;
}

const MenuVariantInput: React.FC<MenuVariantInputProps> = ({
  variants,
  onAddVariant,
  onChangeText,
  onClickVariant,
}) => {
  return (
    <>
      <Form.Item label='Menu Variants' name='variants'>
        {variants.map((item, index) => {
          return (
            <div style={{ display: 'flex' }} key={index}>
              <Form.Item
                name={'Price' + index}
                rules={generateFormRules('Price', ['required'])}
                initialValue={item.price}
                style={{ width: '200px', marginRight: '10px' }}
              >
                <Input
                  defaultValue={item.price}
                  prefix='$'
                  value={item.price}
                  name={`price${index}`}
                  key={`price`}
                  type='number'
                  step='0.1'
                  min={0}
                  onChange={(e) => onChangeText(e.target.value, index, 'price')}
                />
              </Form.Item>
              <Form.Item
                name={'Label' + index}
                rules={generateFormRules('Label', ['required'])}
                style={{ width: '100%', marginRight: '10px' }}
                initialValue={item.label}
              >
                <Input
                  disabled={variants.length === 1}
                  defaultValue={item.label}
                  name={`label${index}`}
                  key={`label`}
                  value={item.label}
                  onChange={(e) => onChangeText(e.target.value, index, 'label')}
                />
              </Form.Item>
              <Form.Item style={{ width: '32px', marginRight: '10px' }}>
                <AppButton
                  disabled={variants.length === 1}
                  type='primary'
                  icon={<DeleteOutlined />}
                  onClick={() => onClickVariant(index, 'delete')}
                />
              </Form.Item>
              <Form.Item style={{ width: '120px' }}>
                <AppButton
                  disabled={item.isDefaultPrice}
                  type='primary'
                  onClick={() => onClickVariant(index, 'default')}
                >
                  Set default
                </AppButton>
              </Form.Item>
            </div>
          );
        })}
        <AppButton type='primary' onClick={onAddVariant}>
          Add Variant
        </AppButton>
      </Form.Item>
    </>
  );
};

const VariantInputContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 32px 120px;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  grid-template-rows: 70px;
  background-color: blue;
`;

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

const CustomUpload = styled(Upload)`
  width: 100%;
  .ant-upload {
    width: 100%;
  }
`;

const CustomQuill = styled(ReactQuill)`
  .ql-container {
    height: 200px;
  }
`;

export default MenuEdit;
