import { BaseResponseProps } from './config.type';


export interface BannerProps {
  bannerId: string;
  title: string;
  subtitle: string;
  isActive: boolean;
  bannerImg: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface FetchAllBannerResponse extends BaseResponseProps {
  payload: {
    count: number
    prev: string
    next: string
    results: BannerProps[]
  }
}

export const initialBanner: BannerProps = {
  bannerId: '',
  title: '',
  subtitle: '',
  isActive: false,
  bannerImg: '',
  createdAt: new Date(),
  updatedAt: new Date()
}