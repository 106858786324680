import { BaseResponseProps } from './config.type';
import { MenuProps } from './menu.type';

export interface TransactionProps {
  transactionId: string;
  items: Array<TransactionItemProps>;
  totalProductPrice: number;
  // payment: Payment;
  metaDiscount?: MetaDiscountProps;
  metaBuyer?: {
    fullName: string;
    email: string;
    imageUrl?: string;
    phoneNumber?: string;
    customerReferral?: any;
    checkoutType?: any;
  };
  groupedId?: string;
  shipment: ShipmentProps;
  transactionStatus: ETransactionStatus;
  transactionCode: string;
  invoiceCode: string;
  finalPrice: number;
  deliveryFee: number;
  createdAt: string;
}

export interface TransactionItemProps {
  productId: string;
  productVariantId?: string;
  productPrice: number;
  quantity: number;
  metaProduct: MetaProductProps;
}

export interface MetaProductProps {
  name: string;
  variant?: {
    isDefaultPrice: boolean;
    label: string;
    menuVariantId?: string;
    price: number;
  };
  variants?: any;
  images?: any;
  tags?: any;
  menuItems: MenuProps[];
}

export interface ShipmentProps {
  transactionId: string;
  method: EShipmentMethod;
  shipmentVendorId?: string;
  scheduledAt: string; // yyyy-mm-dd
  // meta?: MetaShipmentDelivery | MetaShipmentPickup;
  meta?: MetaShipment;
  cost: number;
  waybill?: string;
  status: EShipmentStatus;
}

export interface MetaDiscountProps {
  couponCode: string;
  discountName: string;
  discountNominal: number;
  discountType?: EAmountType;
}

export enum EShipmentMethod {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

export enum EAmountType {
  'PERCENTAGE' = 'PERCENTAGE',
  'NOMINAL' = 'NOMINAL',
  'FORMULA' = 'FORMULA',
}

export enum ETransactionStatus {
  CREATED = 'CREATED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',

  CANCELED = 'CANCELED',

  PROCESS = 'PROCESS', // ORDER PREPARING (SEDANG DIMASAK)
  PROCESS_DONE = 'PROCESS_DONE', // SELESAI DIMASAK (FOOD READY)
  PACKAGING = 'PACKAGING', // LAGI DI PACKAGING
  READY_FOR_SHIPMENT = 'READY_FOR_SHIPMENT', //ORDER PACKAGIN DONE
  COMPLETED = 'COMPLETED', //ORDER DONE
  PAID = 'PAID',
  COD = 'COD',
  CREDITUR_ACCOUNT = 'CREDITUR_ACCOUNT',

  SHIPMENT = 'SHIPMENT',
  ARRIVED = 'ARRIVED',
  ACCEPTED = 'ACCEPTED',
  CANCELED_BY_BUYER = 'CANCELED_BY_BUYER',
  CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM',
  CANCELED_BY_ADMIN = 'CANCELED_BY_ADMIN',
  REJECTED = 'REJECTED',
}

export enum EShipmentStatus {
  CREATED = 'CREATED',
  PROCESS = 'PROCESS',
  DELIVERING = 'DELIVERING',
  ARRIVED = 'ARRIVED',
}

export type MetaShipment = {
  pickupFromLocationId?: string;
  pickupFromLocationName?: string;
  pickupFromLocationAddress?: string;
  pickupTimeStart?: string;
  pickupTimeEnd?: string;

  deliveryAddress?: string;
  deliveryAddressNote?: string;
  deliveryAddressState?: string;
  deliveryAddressPostcode?: string;
  deliveryAddressSuburb?: string;
  deliveryInformation?: string;

  deliveryScheduleTimeframe: any;
};

// export type MetaShipmentPickup = {
//   pickupFromLocationId: string;
//   pickupFromLocationName: string;
//   pickupFromLocationAddress: string;
//   pickupTimeStart?: string;
//   pickupTimeEnd?: string;
// };

// export type MetaShipmentDelivery = {
//   deliveryFromLocationId: string;
//   deliveryFromLocationName: string;

//   deliveryToLocationId: string;
//   deliveryToLocationName: string;
// };

export interface FetchAllTransactionResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: TransactionProps[];
  };
}

export interface FetchAllAnnualDataTransaction extends BaseResponseProps {
  payload: Array<number>;
}

export interface FetchAllTotalTransactionDates extends BaseResponseProps {
  payload: Array<TransactionProps[]>;
}

export interface FetchAllTransactionItemResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: TransactionItemProps[];
  };
}

export const initialTransactionItem: TransactionItemProps = {
  productId: '',
  productVariantId: '',
  productPrice: 0,
  quantity: 0,
  metaProduct: {
    name: '',
    variant: {
      isDefaultPrice: false,
      label: '',
      menuVariantId: '',
      price: 0,
    },
    menuItems: [],
    variants: [],
    images: [],
    tags: [],
  },
};

export const initialShipment: ShipmentProps = {
  transactionId: '',
  method: EShipmentMethod.PICKUP,
  shipmentVendorId: '',
  scheduledAt: '', // yyyy-mm-dd
  meta: {
    pickupFromLocationId: '',
    pickupFromLocationName: '',
    pickupFromLocationAddress: '',
    pickupTimeStart: '',
    pickupTimeEnd: '',

    deliveryAddress: '',
    deliveryAddressNote: '',
    deliveryAddressState: '',
    deliveryAddressPostcode: '',
    deliveryAddressSuburb: '',
    deliveryInformation: '',

    deliveryScheduleTimeframe: {},
  },
  cost: 0,
  waybill: '',
  status: EShipmentStatus.CREATED,
};

export const initialTransactions: TransactionProps = {
  transactionId: '',
  items: [initialTransactionItem],
  // payment: Payment;
  shipment: initialShipment,
  createdAt: '',
  transactionCode: '',
  finalPrice: 0,
  deliveryFee: 0,
  totalProductPrice: 0,
  transactionStatus: ETransactionStatus.CREATED,
  invoiceCode: '',
};

export interface TransactionSummaryProps {
  scheduledAt: string;
  productId: string;
  productVariantId: string;
  productName?: string;
  productVariantName?: string;
  totalQuantity?: number;
  totalQuantityPickup?: number;
  totalQuantityDelivery?: number;
}

export const initialSummaries: TransactionSummaryProps = {
  scheduledAt: '',
  productId: '',
  productVariantId: '',
  productName: '',
  productVariantName: '',
  totalQuantity: 0,
  totalQuantityPickup: 0,
  totalQuantityDelivery: 0,
};
