import { DownOutlined, LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Divider, Dropdown, Menu, Image } from 'antd'
import React from 'react'
import { useHistory } from 'react-router';
import styled from 'styled-components'
import useAuth from '../hooks/useAuth';
import { generateInitialFromName } from '../helpers/generator';

const UserAvatar: React.FC = () => {

  const history = useHistory()
  const { isLoggedIn, getProfile, user, logout } = useAuth()
  const initial = generateInitialFromName(user.name)
  
  const handleMenuClick = (e: any) => {
    if (e.key === 'profile') {
      history.push({ pathname: '/profile' })
    } else if (e.key === 'change-password') {
      history.push({ pathname: '/change-password' });
    } else {
      logout();
      history.replace({ pathname: '/login' });
    }
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      getProfile()
    }
  }, [isLoggedIn])

  const ContentDropdown = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup
        title={
          'Welcome, ' +
          (user.name.length > 15 ? user.name.substr(0, 15) + '...' : user.name)
        }
      >
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item key="profile" icon={<UserOutlined />}>
          My Profil
        </Menu.Item>
        <Menu.Item key="change-password" icon={<LockOutlined />}>
          Change Password
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={ContentDropdown}>
      <div>
        {user.imageUrl ? (
          <AppAvatar size="large" icon={(
            <Image
              preview={false}
              height={40}
              width={40}
              src={user.imageUrl || '/images/blur-image.jpeg'}
              fallback={'/images/blur-image.jpeg'}
              style={{objectFit: 'cover', borderRadius: '50%'}}
              placeholder={
                <Image
                  preview={false}
                  src="/images/blur-image.jpeg"
                  width={40}
                  height={40}
                />
              }
            />
          )}/>
        ) : (
          <AppAvatar size="large">
            {initial}
          </AppAvatar>
        )}
        <Icon />
      </div>
    </Dropdown>
  )
}

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`

export default UserAvatar