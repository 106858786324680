import { UserOutlined } from '@ant-design/icons'
import AppButton from '../../components/AppButton'
import HeaderSection from '../../components/HeaderSection'
import AppLayout from '../layout/AppLayout'
import { useHistory, useParams } from 'react-router-dom';
import { Form, FormInstance, Input, message, Space } from 'antd';
import React from 'react';
import AppCard from '../../components/AppCard';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { IChangePassword } from '../../types/user.type';
import { getErrorMessage } from '../../helpers/errorHandler';
import { httpRequest } from '../../helpers/api';

type IParams = {
  userId: string;
}

const ChangePassword: React.FC = () => {
  const history = useHistory()
  const { userId } = useParams<IParams>()
  const formRef =
    React.useRef<FormInstance<IChangePassword>>(
      null
    );

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSave = (values: IChangePassword) => {
    if (values.newPassword !== values.retypePassword) {
      message.error('New Password and Retype Password is not same. Please check!')
    } else {
      setIsLoading(true);
      httpRequest.patch('/auth/' + userId + '/change-password', {
        newPassword: values.newPassword,
      }).then(() => {
        message.success('Password updated succesfully.')
        setIsLoading(false)
        history.goBack()
      }).catch(err => {
        setIsLoading(false)
        message.error('Failed. ' + getErrorMessage(err))
        formRef.current?.resetFields()
      })
    }
  }
  const handleCancel = () => {
    history.goBack()
  }

  return (
    <AppLayout>
      <HeaderSection
        icon={<UserOutlined />}
        title="Change Password"
        subtitle="Manage password"
        rightAction={
          <Space>
            <AppButton onClick={handleCancel}>Cancel</AppButton>
            <AppButton
              loading={isLoading}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />

      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="passwordForm"
          layout="vertical"
          onFinish={handleSave}
          initialValues={{
            newPassword: '',
            retypePassword: ''
          }}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Password Data"
            helpers={[
              {
                title: 'Information',
                content:
                  'Password minimum 8 characters (letter, number and special character)',
              },
            ]}
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={generateFormRules('Old Password', [
                'required',
              ])}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Retype Password"
              name="retypePassword"
              rules={generateFormRules('Old Password', [
                'required',
              ])}
            >
              <Input.Password />
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
}

export default ChangePassword