import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import {
  MoreOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
  Tag,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import { ContainerFilter } from "../admin";
import useFetchList from "../../hooks/useFetchList";
import { useEffect, useState } from "react";
import { DeliveryCoverageProperties } from "../../types/delivery-coverage.type";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<DeliveryCoverageProperties, "createdAt" | "updatedAt">;
}

const Discount = () => {
  const history = useHistory();

  const [loadingId, setLoadingId] = useState<string>("");

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<DeliveryCoverageProperties>({
    endpoint: "delivery-coverages",
  });

  const handleStatusChange = async (record: DeliveryCoverageProperties) => {
    setLoadingId(record.id);
    try {
      await httpRequest.patch<ResponseProps>(
        "/delivery-coverages/" + record.id,
        {
          id: record.id,
          isPublished: !record.isPublished,
        }
      );
      message.success("Success change " + record.coverageName + " status.");
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setLoadingId(record.id);
    }
  };

  const handleCreateDeliveryCoverage = () => {
    history.push("/delivery-coverage/add");
  };

  const handleClickDetail = (e: DeliveryCoverageProperties) => {
    history.push(`/delivery-coverage/${e.id}`);
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: "active" | "inactive" | string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
    }
  };

  const handleClickEdit = (e: DeliveryCoverageProperties) => {
    history.push(`/delivery-coverage/${e.id}/edit`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "coverageName",
      key: "coverageName",
      render: (value: string, record: DeliveryCoverageProperties) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {value}
          </div>
        );
      },
    },
    {
      title: "Postcode",
      key: "postalCode",
      dataIndex: "postalCode",
      render: (value: string[], record: DeliveryCoverageProperties) => {
        return (
          <div>
            {Object.keys(record.postalCode)
              .slice(0, 11)
              .map((item) => (
                <Tag key={item} color='default'>
                  {item} (${record.postalCode[item]})
                </Tag>
              ))}
            <span style={{ opacity: 0.5, fontSize: 12 }}>
              {Object.keys(record.postalCode).length > 11
                ? `+${Object.keys(record.postalCode).length - 11} more`
                : false}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "isPublished",
      dataIndex: "isPublished",
      render: (isPublished: boolean, record: DeliveryCoverageProperties) => {
        return (
          <>
            <Switch
              loading={record.id === loadingId}
              checked={isPublished}
              onChange={() => {
                handleStatusChange(record);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: DeliveryCoverageProperties) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: DeliveryCoverageProperties) => (
    <Menu
      onClick={({ key }) => {
        if (key === "detail") {
          handleClickDetail(record);
        } else if (key === "edit") {
          handleClickEdit(record);
        }
      }}
    >
      <Menu.Item key='detail'>Detail Delivery Coverage</Menu.Item>
      <Menu.Item key='edit'>Edit Delivery Coverage</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<InfoCircleOutlined />}
        title='Delivery Coverage'
        subtitle='Manage your delivery coverage'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleCreateDeliveryCoverage}>
              Add Delivery Coverage
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by delivery coverage name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </AppLayout>
  );
};

export default Discount;
