import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import {
  TableOutlined,
  MoreOutlined,
  SearchOutlined,
  TagOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import { CategoryProps, ECategoryType } from "../../types/category.type";
import { ContainerFilter } from "../admin";
import useFetchList from "../../hooks/useFetchList";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getErrorMessage } from "../../helpers/errorHandler";
import { useState } from "react";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CategoryProps, "createdAt" | "updatedAt">;
}

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const CustomSortableContainer = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const Category = () => {
  const history = useHistory();

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<CategoryProps>({
    endpoint: "categories",
    // initialQuery: {
    //   type: "category",
    // },
  });

  const handleStatusChange = async (record: CategoryProps) => {
    try {
      let newData = [];
      newData = data.map((item) => {
        if (item.categoryId === record.categoryId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/categories/" + record.categoryId,
        {
          categoryId: record.categoryId,
          isPublished: !record.isPublished,
        }
      );

      newData = data.map((item) => {
        if (item.categoryId === res.data.payload.categoryId) {
          return {
            ...item,
            isPublished: res.data.payload.isPublished,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + record.categoryName + " status.");
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleCreateUser = () => {
    history.push("/category/add");
  };

  const handleClickDetail = (e: CategoryProps) => {
    history.push(`/category/${e.categoryId}`);
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: "active" | "inactive" | string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
    }
  };

  const handleClickEdit = (e: CategoryProps) => {
    history.push(`/category/${e.categoryId}/edit`);
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (text: string, record: CategoryProps) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {record.categoryType === ECategoryType.MEALPACK && (
              <TableOutlined style={{ marginRight: 10, color: "#FF4A50" }} />
            )}
            {text}
          </div>
        );
      },
    },
    {
      title: "Type",
      key: "categoryType",
      dataIndex: "categoryType",
      render: (text: string, record: CategoryProps) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Status",
      key: "isPublished",
      dataIndex: "isPublished",
      render: (isPublished: boolean, record: CategoryProps) => {
        return (
          <>
            <Switch
              loading={record.statusLoading}
              checked={isPublished}
              onChange={() => {
                handleStatusChange(record);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: CategoryProps) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const [isLoadingOrder, setIsLoadingOrder] = useState(false);

  // Draggable Table
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData: CategoryProps[] = arrayMoveImmutable(
        [].concat(data as any),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setData(newData);
      setIsLoadingOrder(true);
      const newDataInQueue = newData.map((item, key) => ({
        categoryId: item.categoryId,
        order: key,
      }));
      console.info("newDataInQueue", newDataInQueue);
      httpRequest
        .patch("categories/queue/bulk", {
          bulk: newDataInQueue,
        })
        .then((res) => {
          message.success("Category Order updated successfully");
          setIsLoadingOrder(false);
        })
        .catch((err) => {
          setIsLoadingOrder(false);
          message.error(getErrorMessage(err));
        });
    }
  };

  const DraggableContainer = (props: any) => (
    <CustomSortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.order === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const menu = (record: CategoryProps) => (
    <Menu
      onClick={({ key }) => {
        if (key === "detail") {
          handleClickDetail(record);
        } else if (key === "edit") {
          handleClickEdit(record);
        }
      }}
    >
      <Menu.Item key='detail'>Detail Category</Menu.Item>
      <Menu.Item key='edit'>Edit Category</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<TagOutlined />}
        title='Category'
        subtitle='Manage your category data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleCreateUser}>
              Add Category
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Category name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading || isLoadingOrder}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey='order'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </AppLayout>
  );
};

export default Category;
