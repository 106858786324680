import {
  Form,
  FormInstance,
  Input,
  message,
  Dropdown,
  Button,
  Space,
  Menu,
  TimePicker,
  Typography,
  Radio,
  Row,
  Col,
  Divider,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import {
  // GoogleMapJsonResponseProps,
  // initialGoogleMapJsonResponse,
  initialLocation,
  LocationProps,
} from '../../types/location.type';
import moment from 'moment';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import AppMapPickLocation from '../../components/AppMapPickLocation';
import { useDebounce } from 'use-debounce';
import { IHttpResponse, IPayloadPagination } from '../../helpers/pagination';
import { generateQueryString } from '../../helpers/generateQueryString';
import Autocomplete from 'react-google-autocomplete';
import MapPickLocation from '../../components/AppReactMapPickLocation';

const { Text } = Typography;

interface IParams {
  locationId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<LocationProps, 'createdAt' | 'updatedAt'>;
}

const LocationEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { locationId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<LocationProps, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const [locationCodeInput, setLocationCodeInput] = React.useState<String>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [locationData, setLocationData] = React.useState<LocationProps>({
    ...initialLocation,
  });
  // const [googleMapJsonInfo, setGoogleMapJsonInfo] =
  //   React.useState<GoogleMapJsonResponseProps>({
  //     ...initialGoogleMapJsonResponse,
  //   });
  const [valueLocationCode] = useDebounce(locationCodeInput, 500);
  const [notAllowSave, setNotAllowSave] = React.useState<boolean>(false);

  React.useEffect(() => {
    checkLocationCode();
  }, [valueLocationCode]);

  const checkLocationCode = async () => {
    if (valueLocationCode !== '') {
      const resLocation = await httpRequest.get<
        IHttpResponse<IPayloadPagination<LocationProps>>
      >(
        `/locations${generateQueryString({
          locationCode: valueLocationCode,
          locationNotId: locationId,
        })}`
      );
      if (resLocation.data.payload.count > 0) {
        setNotAllowSave(true);
      } else {
        setNotAllowSave(false);
      }
    }
  };

  const createLocation = async (
    props: Omit<
      LocationProps,
      'createdAt' | 'updatedAt' | 'locationId' | 'statusLoading'
    >
  ) => {
    try {
      setIsLoadingAction(true);

      const dataToBeSend = {
        ...props,
        locationCode: valueLocationCode,
        locationGeoloc: locationData.locationGeoloc,
        locationType: locationData.locationType,
        availabilityPickups: locationData.availabilityPickups.map((item) => [
          item.startAt,
          item.endAt,
        ]),
        isPickup: locationData.isPickup,
        locationAddress: props.locationAddress
          ? props.locationAddress
          : locationData.locationAddress,
        state: props.state ? props.state : locationData.state,
        suburb: props.suburb ? props.suburb : locationData.suburb,
        postalCode: props.postalCode
          ? props.postalCode
          : locationData.postalCode,
        timeframe: props.timeframe ? props.timeframe : locationData.timeframe,
      };

      await httpRequest.post('/locations', dataToBeSend);
      message.success('Success create ' + props.locationName);

      history.push('/pickup-location');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateLocation = async (
    props: Omit<LocationProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch('/locations/' + locationId, {
        ...props,
        locationCode: valueLocationCode,
        locationGeoloc: locationData.locationGeoloc,
        locationType: locationData.locationType,
        availabilityPickups: locationData.availabilityPickups.map((item) => [
          item.startAt,
          item.endAt,
        ]),
        isPickup: locationData.isPickup,
        locationAddress: props.locationAddress
          ? props.locationAddress
          : locationData.locationAddress,
        state: props.state ? props.state : locationData.state,
        suburb: props.suburb ? props.suburb : locationData.suburb,
        postalCode: props.postalCode
          ? props.postalCode
          : locationData.postalCode,
        timeframe: props.timeframe ? props.timeframe : locationData.timeframe,
      });
      message.success('Success update ' + props.locationName + ' data');
      history.push('/pickup-location');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<LocationProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    if (locationId) {
      updateLocation(values);
    } else {
      createLocation(values);
    }
  };

  const handleAddAvailability = () => {
    const newLocationAvailability = locationData.availabilityPickups.concat({
      startAt: '00:00',
      endAt: '00:00',
    });

    setLocationData((oldVal) => {
      return {
        ...oldVal,
        availabilityPickups: newLocationAvailability,
      };
    });
  };

  const handleDeleteAvailability = (index: number) => {
    locationData.availabilityPickups.splice(index, 1);

    setLocationData((oldVal) => {
      return {
        ...oldVal,
        availabilityPickups: locationData.availabilityPickups,
      };
    });
  };

  const handleChangeAvailability = (value: any, index: number) => {
    locationData.availabilityPickups[index].startAt = value[0];
    locationData.availabilityPickups[index].endAt = value[1];
    setLocationData({
      ...locationData,
    });
  };

  function handleLocationTypeClick(e: any) {
    console.info('e', e);
    setLocationData({
      ...locationData,
      locationType: e.key,
    });
  }

  const locationType = (
    <Menu onClick={handleLocationTypeClick} defaultValue={'outlet'}>
      <Menu.Item key='main_kitchen'>Main Kitchen</Menu.Item>
      <Menu.Item key='outlet'>Outlet</Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    if (locationId) {
      const fetchMenuDetail = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/locations/' + locationId
          );
          if (
            res.data.payload.locationGeoloc &&
            typeof res.data.payload.locationGeoloc === 'string'
          ) {
            res.data.payload.locationGeoloc = JSON.parse(
              res.data.payload.locationGeoloc
            );
            if (typeof res.data.payload.locationGeoloc?.lat === 'string') {
              res.data.payload.locationGeoloc.lat = Number(
                res.data.payload.locationGeoloc.lat
              );
            }
            if (typeof res.data.payload.locationGeoloc?.lng === 'string') {
              res.data.payload.locationGeoloc.lng = Number(
                res.data.payload.locationGeoloc.lng
              );
            }
          }

          setLocationData(res.data.payload);
          setLocationCodeInput(res.data.payload.locationCode);
          setIsLoading(false);
          const bcDetails = [
            {
              field: 'locationId',
              value: locationId,
              label: res.data.payload.locationName,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchMenuDetail();
    }
  }, [locationId]);

  const [form] = Form.useForm();

  function setAddressDetail(location: any) {
    console.log('location', location);
    form.setFieldsValue({
      postalCode: location.postalCode,
      locationAddress: location.address,
      state: location.state,
      suburb: location.suburb,
    });

    setLocationData({
      ...locationData,
      locationGeoloc: location.coords,
      locationAddress: location.address,
      state: location.state,
      postalCode: location.postalCode,
      suburb: location.suburb,
    });
  }

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(locationId ? 'Edit' : 'Add') + ' Pickup Location'}
        subtitle='Manage your pickup location data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              disabled={notAllowSave}
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          form={form}
          ref={formRef}
          name='locationForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={locationId ? locationData : initialLocation}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Location Information'
            helpers={[
              {
                title: 'Information',
                content:
                  'Drag the map to Main Kitchen or Outlet location then save; Maps will show on Web-App Cart before Checkout; Location Code is abbreviation of the location name',
              },
            ]}
          >
            <Form.Item
              label='Location Name'
              name='locationName'
              rules={generateFormRules('Location name', ['required'])}
            >
              <Input
                placeholder='Enter location name'
                onChange={(e) => {
                  setLocationData({
                    ...locationData,
                    locationName: e.target.value,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label='Location Code'
              name='locationCode'
              // required
              rules={generateFormRules('Location code', ['required'])}
            >
              <Input
                type={'text'}
                placeholder='Enter location code'
                defaultValue={locationData.locationCode}
                onChange={(event) => setLocationCodeInput(event.target.value)}
              />
            </Form.Item>
            {notAllowSave && locationCodeInput !== '' && (
              <Text type='danger'>
                Location Code has been used by other location, Please change the
                Location Code
              </Text>
            )}

            {/* <Form.Item
              label="Address"
              name="locationAddress"
              rules={[
                {
                  required: true,
                  message: "The Address is Required.",
                },
              ]}
            >
              <Input placeholder='input your address' />

              <Autocomplete
                apiKey={process.env.REACT_APP_MAP_API_KEY as string}
                onPlaceSelected={(place, ref, autocompleteRef) => {
                  console.log("place : ", place);
                }}
                options={{
                  types: [
                    "postal_code",
                    "postal_code_suffix",
                    "street_number",
                    "country",
                    "route",
                    "locality",
                    "name",
                    "geometry",
                  ],
                }}
                style={{
                  width: "100%",
                  padding: "4px 11px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                defaultValue={googleMapJsonInfo.formatted_address}
              />
            </Form.Item> */}

            <Form.Item label='Pick Location' name='pickLocation'>
              {!isLoading && (
                // <AppMapPickLocation
                //   isMain={locationData.locationType === "main_kitchen"}
                //   onChange={async (coords) => {
                //     setLocationData((oldValue) => ({
                //       ...oldValue,
                //       locationGeoloc: coords,
                //     }));
                //     const locationInfo = await httpRequest.post<any>(
                //       `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${
                //         coords.lat
                //       },${coords.lng}&key=${
                //         process.env.REACT_APP_MAP_API_KEY as string
                //       }`,
                //       {},
                //       {
                //         headers: { "Access-Control-Allow-Origin": "*" },
                //       }
                //     );
                //     // setGoogleMapJsonInfo(locationInfo.data.results[2]);
                //     console.log(
                //       "locationInfo : ",
                //       locationInfo.data.results[2]
                //     );
                //   }}
                //   center={
                //     locationData.locationGeoloc
                //       ? {
                //           lat: Number(locationData.locationGeoloc?.lat),
                //           lng: Number(locationData.locationGeoloc?.lng),
                //         }
                //       : undefined
                //   }
                // />
                <MapPickLocation
                  onChange={(location) => {
                    setAddressDetail(location);
                  }}
                  center={
                    locationData.locationGeoloc
                      ? {
                          lat: Number(locationData.locationGeoloc.lat),
                          lng: Number(locationData.locationGeoloc.lng),
                        }
                      : undefined
                  }
                />
              )}
              <Text style={{ fontSize: 10, zIndex: 100 }}>
                <b>Latitude:</b> {locationData?.locationGeoloc?.lat} ·{' '}
                <b>Longitude:</b> {locationData?.locationGeoloc?.lng}
              </Text>
            </Form.Item>
            <Row gutter={[24, 24]} justify='space-between'>
              <Col span={12}>
                <Form.Item label='State' name='state'>
                  <Input
                    disabled
                    placeholder='State'
                    onChange={(e) => {
                      setLocationData({
                        ...locationData,
                        state: e.target.value,
                      });
                    }}
                    value={locationData.state}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Postcode' name='postalCode'>
                  <Input
                    disabled
                    placeholder='Postcode'
                    onChange={(e) => {
                      setLocationData({
                        ...locationData,
                        postalCode: e.target.value,
                      });
                    }}
                    value={locationData.postalCode}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label='Suburb' name='suburb'>
              <Input
                disabled
                placeholder='Suburb'
                onChange={(e) => {
                  setLocationData({
                    ...locationData,
                    suburb: e.target.value,
                  });
                }}
                value={locationData.suburb}
              />
            </Form.Item>

            <Form.Item label='Address' name='locationAddress'>
              <Input
                disabled
                onChange={(e) => {
                  setLocationData({
                    ...locationData,
                    locationAddress: e.target.value,
                  });
                }}
                value={locationData.locationAddress}
              />
            </Form.Item>

            <Form.Item
              label='Contact'
              name='locationContact'
              rules={generateFormRules('Contact', ['required'])}
            >
              <Input />
            </Form.Item>
          </SectionContent>
          <Divider />
          <SectionContent
            groupTitle='Config'
            helpers={[
              {
                title: 'Information',
                content:
                  'Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.',
              },
            ]}
          >
            <Form.Item
              label='Pickup Timeframe'
              name='timeframe'
              rules={[
                {
                  required: true,
                  message: 'Pickup Timeframe is required.',
                },
              ]}
            >
              <Input required placeholder='ex. 07:00 AM - 05:00 PM' />
            </Form.Item>
            <div style={{ color: '#93A1B0', margin: '-.5rem 0 .5rem .1rem' }}>
              ex. 07:00 AM - 05:00 PM
            </div>

            <Form.Item
              label='Status'
              name='isPublished'
              rules={[
                {
                  required: true,
                  message: 'Status is required.',
                },
              ]}
            >
              <Radio.Group defaultValue={locationData.isPublished}>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>

            {/* <Form.Item label='Type' name='type' required>
              <Dropdown overlay={locationType}>
                <Button
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {locationData.locationType === "outlet"
                    ? "Outlet"
                    : "Main Kitchen"}
                </Button>
              </Dropdown>
            </Form.Item> */}
            {/* <Form.Item label="Availability" required>
              {locationData.availabilityPickups.map((item, index) => {
                return (
                  <Form.Item key={index}>
                    <TimePicker.RangePicker
                      style={{
                        width: "60%",
                        marginBottom: "1%",
                        marginRight: "2%",
                      }}
                      key={index}
                      defaultValue={[
                        moment(item.startAt, "HH:mm"),
                        moment(item.endAt, "HH:mm"),
                      ]}
                      use12Hours
                      format="h:mm A"
                      minuteStep={15}
                      order={false}
                      onChange={(_, formatString: [string, string]) =>
                        handleChangeAvailability(formatString, index)
                      }
                    />
                    <AppButton
                      disabled={locationData.availabilityPickups.length === 1}
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteAvailability(index)}
                    />
                  </Form.Item>
                );
              })}
            </Form.Item> */}

            {/* <AppButton type='primary' onClick={handleAddAvailability}>
              Add Availability
            </AppButton> */}
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default LocationEdit;
