import { BaseResponseProps } from './config.type';


export interface AppConfigProps {
  configId: string;
  key: string;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface FetchOneAppConfigResponse extends BaseResponseProps {
  payload: AppConfigProps
}

export interface FetchAllAppConfigResponse extends BaseResponseProps {
  payload: {
    count: number
    prev: string
    next: string
    results: AppConfigProps[]
  }
}

export const initialAppConfig: AppConfigProps = {
  configId: '',
  key: '',
  value: '',
  createdAt: new Date(),
  updatedAt: new Date()
}