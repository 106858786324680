import { BatchState, BatchTypes } from '../../types/batch.type';
import { Reducer } from 'redux';

const initialState: BatchState = {
  unapprovedBatch: 10
}

export const batch: Reducer<BatchState> = (state = initialState, action) => {
  switch (action.type) {
    case BatchTypes.SET_UNAPPROVED_BATCH:
      return {
        ...state,
        unapprovedBatch: action.payload
      }
  
    default:
      return state
  }
}