import { Col, Divider, Space, Typography } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import AppLayout from '../layout/AppLayout';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { formatDate } from '../../helpers/constant';
import {
  EResellerStatus,
  initialReseller,
  ResellerProperties,
} from '../../types/reseller.type';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';

interface IParams {
  resellerId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<ResellerProperties, 'createdAt' | 'updatedAt'>;
}
const { Text } = Typography;

const ReferralDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { resellerId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reseller, setReseller] =
    React.useState<ResellerProperties>(initialReseller);

  const handleClickEdit = () => {
    history.push(`/reseller/${reseller.resellerId}/edit`);
  };

  React.useEffect(() => {
    const fetchDetailReseller = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          '/resellers/' + resellerId
        );

        setReseller(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: 'resellerId',
            value: resellerId,
            label: res.data.payload.resellerName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDetailReseller();
  }, [resellerId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Reseller Detail'
        subtitle='Manage your reseller data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent
          groupTitle='Reseller Data'
          helpers={
            [
              // {
              //   title: 'Information',
              //   content:
              //     'Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.',
              // },
            ]
          }
        >
          <DetailItem label='Name' content={reseller.resellerName} />
          <DetailItem
            label='Description'
            content={reseller.description || '-'}
          />
          <DetailItem
            label='Status'
            content={
              reseller.status === EResellerStatus.ACTIVE ? 'Active' : 'Inactive'
            }
          />
          <DetailItem
            label='Created at'
            content={formatDate(reseller.createdAt)}
          />
        </SectionContent>
        <Divider />
        <SectionContent
          groupTitle='Customers'
          helpers={
            [
              // {
              //   title: 'Information',
              //   content:
              //     'Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.',
              // },
            ]
          }
        >
          <DetailItem
            label={`List of Customers :`}
            content={
              reseller.reseller_users && reseller.reseller_users.length > 0 ? (
                <>
                  {reseller.reseller_users?.map((item, idx) => {
                    return (
                      <div style={{ marginTop: 15 }} key={idx}>
                        {idx + 1}. {item.customer?.fullName}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{ opacity: 0.5, fontStyle: 'italic', marginTop: 15 }}
                >
                  Data empty
                </div>
              )
            }
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default ReferralDetail;
