import { Form, FormInstance, Input, message, Space } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppCard from "../../../components/AppCard";
import HeaderSection from "../../../components/HeaderSection";
import { initialBanner, BannerProps } from "../../../types/banner.type";
import AppLayout from "../../layout/AppLayout";
import { httpRequest } from "../../../helpers/api";
import { BaseResponseProps } from "../../../types/config.type";
import SectionContent from "../../../components/SectionContent";
import TextArea from "antd/lib/input/TextArea";
import FormUploadImage from "../../../components/FormUploadImage";
import { getErrorMessage } from "../../../helpers/errorHandler";
import HeaderBannerItem from "../../../components/HeaderBannerItem";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";

interface IParams {
  bannerId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<BannerProps, "createdAt" | "updatedAt">;
}

const BannerEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { bannerId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<BannerProps, "createdAt" | "updatedAt">>>(
      null
    );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [banner, setBanner] = React.useState<BannerProps>(initialBanner);

  const [image, setImage] = React.useState<File | undefined>();
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();

  const createBanner = async (
    props: Omit<
      BannerProps,
      "createdAt" | "updatedAt" | "bannerId" | "statusLoading"
    >
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append("title", banner.title);
      formData.append("subtitle", banner.subtitle);
      formData.append("isActive", banner.isActive ? "1" : "0");

      if (image) {
        formData.append("image", image);
      }

      await httpRequest.post("/banners", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Success create ");

      history.push("/banner");
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateBanner = async (
    props: Omit<BannerProps, "createdAt" | "updatedAt" | "statusLoading">
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append("title", banner.title);
      formData.append("subtitle", banner.subtitle);
      formData.append("isActive", banner.isActive ? "1" : "0");

      if (image) {
        formData.append("image", image);
      }
      await httpRequest.patch("/banners/" + bannerId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Success update ");
      history.push("/banner");
    } catch (error) {
      message.error(getErrorMessage(error));
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<BannerProps, "createdAt" | "updatedAt" | "statusLoading">
  ) => {
    console.info("values", values);
    if (bannerId) {
      updateBanner(values);
    } else {
      createBanner(values);
    }
  };

  React.useEffect(() => {
    if (bannerId) {
      const fetchBanner = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            "/banners/" + bannerId
          );
          console.info("res.data.payload", res.data.payload);
          setBanner(res.data.payload);
          setImageUrl(res.data.payload.bannerImg);
          setIsLoading(false);

          const bcDetails = [
            {
              field: "bannerId",
              value: bannerId,
              label: res.data.payload.title,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchBanner();
    }
  }, [bannerId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(bannerId ? "Edit" : "Add") + " Banner"}
        subtitle='Manage your Banner data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='bannerForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={bannerId ? banner : initialBanner}
          autoComplete='off'
        >
          {/* <SectionContent
            groupTitle="Banner Preview"
          >
            <HeaderBannerItem title={banner.title} subtitle={banner.subtitle} rightImageUrl={imageUrl || ''} />
          </SectionContent>

          <hr color="lightgrey" style={{marginBottom: 20}} /> */}

          <SectionContent
            groupTitle='Banner Data'
            helpers={[
              {
                title: "Information",
                content:
                  "Banner will shows on the very top of the Web-App Home; You can set multiple Banner active at once",
              },
            ]}
          >
            <FormUploadImage
              maxSizeInMB={1}
              imageUrl={imageUrl || ""}
              onChange={(file) => {
                setImage(file);
              }}
              onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
            />

            {/* <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'The title is required.',
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  setBanner({
                    ...banner,
                    title: e.target.value,
                  })
                }
                value={banner.title}
              />
            </Form.Item>
            <Form.Item
              label="Subtitle"
              name="subtitle"
              rules={[
                {
                  required: true,
                  message: 'The subtitle is required.',
                },
              ]}
            >
              <TextArea
                onChange={(e) =>
                  setBanner({
                    ...banner,
                    subtitle: e.target.value,
                  })
                }
                value={banner.subtitle}
              />
            </Form.Item> */}
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default BannerEdit;
