import {
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  Select,
  Space,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { initialUser, IUser } from '../../types/user.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { showRoleName, ROLES } from '../../helpers/auth';
import useAuth from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../helpers/text';
import FormUploadImage from '../../components/FormUploadImage';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';

interface ILocation {
  userId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<IUser, 'createdAt' | 'updatedAt'>;
}

const UserEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { user: myProfile } = useAuth();
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { userId } = useParams<ILocation>();
  const formRef =
    React.useRef<FormInstance<Omit<IUser, 'createdAt' | 'updatedAt'>>>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser>(initialUser);

  const [image, setImage] = React.useState<File>();
  const [imageUrl, setImageUrl] = React.useState(user.imageUrl);

  const createUser = async (
    props: Omit<IUser, 'createdAt' | 'updatedAt' | 'adminId'>
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append('name', props.name);
      formData.append('email', props.email);
      formData.append('phoneNumber', props.phoneNumber);
      formData.append('role', props.role);
      formData.append('status', props.status);

      if (image) {
        formData.append('image', image);
      }

      await httpRequest.post('/admins', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Success create ' + props.name);

      history.push('/user');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateUser = async (props: Omit<IUser, 'createdAt' | 'updatedAt'>) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append('name', props.name);
      formData.append('email', props.email);
      formData.append('phoneNumber', props.phoneNumber);
      formData.append('role', props.role);
      formData.append('status', props.status);

      if (image) {
        formData.append('image', image);
      }

      await httpRequest.patch('/admins/' + userId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Success update ' + props.name + ' data');
      history.push('/user');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<IUser, 'createdAt' | 'updatedAt'>
  ) => {
    if (userId) {
      updateUser(values);
    } else {
      createUser(values);
    }
  };

  React.useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/admins/' + userId + '/detail'
          );
          setUser(res.data.payload);
          setImageUrl(res.data.payload.imageUrl);
          setIsLoading(false);

          const bcDetails = [
            {
              field: 'userId',
              value: userId,
              label: res.data.payload.name,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchUser();
    }
  }, [userId, location]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(userId ? 'Edit' : 'Add') + ' User'}
        subtitle='Manage your user data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={userId ? user : initialUser}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Profil Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'Image either jpg, png or jpeg; max size 1MB; recommended resolution is 500x300px',
              },
            ]}
          >
            <FormUploadImage
              imageUrl={imageUrl || ''}
              onChange={(file) => setImage(file)}
              onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
            />
            <Form.Item
              label='Name'
              name='name'
              rules={generateFormRules('Name', [
                'required',
                'letter-and-space',
              ])}
            >
              <Input placeholder='Enter name' />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={generateFormRules('Email', ['required', 'email'])}
            >
              <Input disabled={!!userId} placeholder='Enter email' />
            </Form.Item>
            <Form.Item
              label='Phone number'
              name='phoneNumber'
              rules={generateFormRules('Phone number', [
                'required',
                'phoneNumber',
              ])}
            >
              <Input placeholder='Enter phone number' />
            </Form.Item>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle='Profile Setting'
            helpers={[
              {
                title: 'Information',
                content: 'Status Inactive makes the user unable to logging in',
              },
            ]}
          >
            <Form.Item
              label='Role'
              name='role'
              rules={[
                {
                  required: true,
                  message: 'The Role is required.',
                },
              ]}
            >
              {myProfile.adminId === userId ? (
                showRoleName(user.role)
              ) : (
                <Select style={{ width: '100%' }}>
                  {Object.keys(ROLES).map((roleKey) => (
                    <Select.Option key={roleKey} value={roleKey}>
                      {ROLES[roleKey]}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: 'The Status is required.',
                },
              ]}
            >
              {myProfile.adminId === userId ? (
                capitalizeFirstLetter(user.status)
              ) : (
                <Radio.Group value={user.status || 'active'}>
                  <Radio value='active'>Active</Radio>
                  <Radio value='inactive'>Inactive</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default UserEdit;
