import React from 'react';
import AppLayout from '../layout/AppLayout';
import { MoreOutlined, SearchOutlined, MessageOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Dropdown,
  Menu,
  Input,
  Select,
  Modal,
  Tag,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { FetchAllCustomerMessageResponse, CustomerMessageProps, initialCustomerMessage } from '../../types/customerMessage.type';
import { ContainerFilter } from '../admin';
import DetailItem from '../../components/DetailItem';
import useFetchList from '../../hooks/useFetchList';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
const { Option } = Select;

const CustomerMessage = () => {
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<CustomerMessageProps>(initialCustomerMessage);
  const {isLoading, data, pagination, setData, setSearch, setQuery, changePage, changeLimit} = useFetchList<CustomerMessageProps>({
    endpoint: 'customer-messages'
  });

  const handleFilterStatusChange = (status: '1' | '0' | string) => {
    if (status === 'all') {
      setQuery((oldVal) => ({ ...oldVal, status: undefined }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, status: status }));
    }
  };

  const handleClickDetail = (e: CustomerMessageProps) => {
    setIsModalVisible(true)
    setTmpData(e)

    if (!e.status) {
      httpRequest.patch<FetchAllCustomerMessageResponse>(
        `/customer-messages/${e.customermessageId}`
      );

      setData(data.map(item => {
        if (item.customermessageId === e.customermessageId) {
          item = {
            ...item,
            status: !item.status
          }
        }
        return item;
      }))
    }
  };

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text: string, record: CustomerMessageProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: (text: string, record: CustomerMessageProps) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Subject',
      key: 'subject',
      dataIndex: 'subject',
      render: (text: string, record: CustomerMessageProps) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
      render: (text: string, record: CustomerMessageProps) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: boolean, record: CustomerMessageProps) => {
        return status ? (
          <Tag color="#f50">Read</Tag>
        ) : (
          <Tag color="default">Unread</Tag>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string, _record: CustomerMessageProps) => {
        return <div>{formatDate(createdAt)}</div>
      },
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: CustomerMessageProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: CustomerMessageProps) => (
    <Menu onClick={() => handleClickDetail(record)}>
      <Menu.Item key="detail">Detail Customer Message</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<MessageOutlined />}
        title="Customer Message"
        subtitle="Manage your Customer Message data"
      />

      <ContainerFilter>
        <Input
          size="large"
          placeholder="Search by subject"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size="large"
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder="Status"
        >
          <Option value="all">All</Option>
          <Option value="1">Read</Option>
          <Option value="0">Unread</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title="Detail Customer Message"
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialCustomerMessage);
        }}
      >
        <DetailItem label="Full name" content={tmpData.fullName} />
        <DetailItem label="Email" content={tmpData.email} />
        <DetailItem label="Subject" content={tmpData.subject} />
        <DetailItem label="Message" content={tmpData.message} />
      </Modal>
    </AppLayout>
  );
};

export default CustomerMessage;
