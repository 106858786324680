import { Space } from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import { initialCategory, CategoryProps } from "../../types/category.type";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";

interface IParams {
  categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CategoryProps, "createdAt" | "updatedAt">;
}

const CategoryDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { categoryId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [category, setCategory] =
    React.useState<CategoryProps>(initialCategory);

  const handleClickEdit = () => {
    history.push(`/category/${category.categoryId}/edit`);
  };

  React.useEffect(() => {
    const fetchDetailCategory = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/categories/" + categoryId
        );

        setCategory(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: "categoryId",
            value: categoryId,
            label: res.data.payload.categoryName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDetailCategory();
  }, [categoryId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Category Detail'
        subtitle='Manage your category data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle='Category Data'>
          <DetailItem label='Name' content={category.categoryName} />
          <DetailItem
            label='Status'
            content={category.isPublished ? "Active" : "Inactive"}
          />
          <DetailItem
            label='Created at'
            content={formatDate(category.createdAt)}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default CategoryDetail;
