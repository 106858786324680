import {
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Radio,
  Space,
  Table,
  Typography,
} from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { useDebounce } from 'use-debounce';
import { IHttpResponse, IPayloadPagination } from '../../helpers/pagination';
import { generateQueryString } from '../../helpers/generateQueryString';
import useFetchList from '../../hooks/useFetchList';
import { generateFormRules } from '../../helpers/formRules';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import {
  DiscountProperties,
  DiscountUserProperties,
  initialDiscount,
} from '../../types/discount.type';
import { CustomerProps } from '../../types/customer.type';

const { Text } = Typography;

interface IParams {
  discountId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<DiscountProperties, 'createdAt' | 'updatedAt'>;
}

interface ResponseDiscountProps extends BaseResponseProps {
  payload: DiscountProperties;
}

interface ResponseDiscountUserProps extends BaseResponseProps {
  payload: DiscountUserProperties;
}

const DiscountEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const location = useLocation();
  const isEdit = location.pathname.includes('edit');
  const { discountId } = useParams<IParams>();
  const formRef =
    React.useRef<
      FormInstance<Omit<DiscountProperties, 'createdAt' | 'updatedAt'>>
    >(null);

  const {
    isLoading: isLoadingAllCustomers,
    data: dataAllCustomers,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    query,
  } = useFetchList<CustomerProps>({
    endpoint: 'customers',
    initialQuery: {
      isPublished: 'active',
      limit: 25,
    },
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingRemoveCustomer, setIsLoadingRemoveCustomer] =
    React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [discount, setDiscount] = React.useState<DiscountProperties>({
    ...initialDiscount,
  });
  const [selectedCustomers, setSelectedCustomers] = React.useState<
    CustomerProps[]
  >([]);
  const [currDiscountUser, setCurrDiscountUser] = React.useState<
    DiscountUserProperties[]
  >([]);
  const [notAllowSave, setNotAllowSave] = React.useState<boolean>(false);
  const [isShowModalAddCustomer, setIsShowModalAddCustomer] =
    React.useState<boolean>(false);

  const createDiscount = async (
    props: Omit<
      DiscountProperties,
      'createdAt' | 'updatedAt' | 'discountId' | 'statusLoading'
    >
  ) => {
    try {
      setIsLoadingAction(true);

      const res = await httpRequest.post<ResponseDiscountProps>('/discounts', {
        discountName: props.discountName,
        description: props.description || '',
        amountType: props.amountType || initialDiscount.amountType,
        amount: props.amount,
        isPublished: props.isPublished,
      });

      if (res.data && res.data.payload) {
        const newCustomerMemberData = selectedCustomers.map((item) => ({
          discountId: res.data.payload.discountId,
          userId: item.customerId,
        }));

        await httpRequest.post<ResponseDiscountUserProps>(
          '/discount-users/bulk',
          {
            bulk: newCustomerMemberData,
          }
        );
      }

      message.success('Success create ' + props.discountName);

      history.push('/discount');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateDiscount = async (
    props: Omit<DiscountProperties, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch('/discounts/' + discountId, {
        discountName: props.discountName,
        description: props.description,
        amountType: props.amountType,
        amount: props.amount,
        isPublished: props.isPublished,
      });

      const newCustomerMemberData = selectedCustomers.map((item) => {
        const found = currDiscountUser.find(
          (a) => a.userId === item.customerId
        );
        return {
          id: found ? found.id : null,
          discountId: discountId,
          userId: item.customerId,
        };
      });

      await httpRequest.patch<ResponseDiscountUserProps>(
        '/discount-users/discount/bulk',
        {
          bulk: newCustomerMemberData,
        }
      );
      console.log('success');
      message.success('Success update ' + props.discountName + ' data');
      history.push('/discount');
    } catch (error) {
      console.error('failed update discount', error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  React.useEffect(() => {
    if (discountId) {
      const fetchDiscount = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/discounts/' + discountId
          );

          setDiscount(res.data.payload);
          const customers: any = [];
          if (
            res.data.payload.discount_users &&
            res.data.payload.discount_users.length > 0
          ) {
            for (const item of res.data.payload.discount_users!) {
              customers.push(item.customer);
            }
            setSelectedCustomers(customers);
            setCurrDiscountUser(res.data.payload.discount_users);
          }

          const bcDetails = [
            {
              field: 'discountId',
              value: discountId,
              label: res.data.payload.discountName,
            },
          ];
          setBreadcrumbDetails(bcDetails);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchDiscount();
    }
  }, [discountId]);

  const handleSubmit = async (
    values: Omit<
      DiscountProperties,
      'createdAt' | 'updatedAt' | 'statusLoading' | 'isMealpack'
    >
  ) => {
    if (discountId) {
      updateDiscount(values);
    } else {
      createDiscount(values);
    }
  };

  async function handleRemoveCustomer(data: CustomerProps) {
    try {
      setIsLoadingRemoveCustomer(true);
      if (currDiscountUser.length > 0) {
        const found = currDiscountUser.find(
          (item) => item.customer?.customerId === data.customerId
        );
        if (found) {
          await httpRequest.delete('discount-users/' + found.id);
          message.success('Success remove ' + found.customer?.fullName);
          const newCurrDiscountUser = currDiscountUser.filter(
            (item) => item.id !== found.id
          );
          setCurrDiscountUser(newCurrDiscountUser);
        }
      }

      setSelectedCustomers((oldVal) =>
        oldVal.filter((item) => item.customerId !== data.customerId)
      );

      const newSelectedCustomersIds = selectedCustomers
        .filter((item) => item.customerId !== data.customerId)
        .map((item) => item.customerId);

      setQuery({
        ...query,
        notIncludeIds: newSelectedCustomersIds,
      });
    } catch (error) {
      console.error('failed error remove customer', error);
    } finally {
      setIsLoadingRemoveCustomer(false);
    }
  }

  const handleShowModalAddCustomer = () => {
    setIsShowModalAddCustomer(true);
    if (currDiscountUser.length > 0) {
      setQuery({
        ...query,
        notIncludeIds: currDiscountUser.map(
          (item) => item.customer?.customerId
        ),
      });
    }
  };

  function handleAddCustomer(data: CustomerProps) {
    const newSelectedCustomers = [...selectedCustomers, data];
    setSelectedCustomers(newSelectedCustomers);
    setQuery({
      ...query,
      notIncludeIds: newSelectedCustomers.map((item) => item.customerId),
    });
  }

  function handleConfirmAddCustomer() {
    setIsShowModalAddCustomer(false);
  }

  const columnsSelectedCustomer = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, item: CustomerProps) => (
        <AppButton
          type='primary'
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveCustomer(item)}
        />
      ),
    },
  ];

  const columnsAllCustomer = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      render: (value: string) => <div>{value}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, item: CustomerProps) => (
        <AppButton type='primary' onClick={() => handleAddCustomer(item)}>
          Add
        </AppButton>
      ),
    },
  ];

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(discountId ? 'Edit' : 'Add') + ' Discount'}
        subtitle='Manage your discount data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              disabled={notAllowSave}
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={discountId ? discount : initialDiscount}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Discount Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'Status In-Active will make this discount unable to be used by customers',
              },
            ]}
          >
            <Form.Item
              label='Name'
              name='discountName'
              rules={generateFormRules('discountName', [
                'required',
                'letter-and-space',
              ])}
            >
              <Input placeholder='Enter discount name' />
            </Form.Item>
            <Form.Item
              label='Amount'
              name='amount'
              rules={[
                {
                  required: true,
                },
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                },
              ]}
              style={{ width: '100%' }}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                parser={(value) => value!.replace('%', '')}
              />
            </Form.Item>
            <Form.Item label='Description' name='description'>
              <Input.TextArea
                placeholder='Enter discount description'
                showCount
                maxLength={500}
              />
            </Form.Item>
            <Form.Item
              label='Status'
              name='isPublished'
              rules={[
                {
                  required: true,
                  message: 'The Status is required.',
                },
              ]}
            >
              <Radio.Group value={discount.isPublished}>
                <Radio value={true}>Active</Radio>
                <Radio value={false}>In Active</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle='Customer Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'Customers that listed in here will have this discount whenever they checkout',
              },
            ]}
          >
            <Form.Item label='List of Customers'>
              {selectedCustomers.length === 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: '1px solid #ddd',
                    padding: '15px 0',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>Data customer empty</div>
                </div>
              ) : (
                selectedCustomers.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #ddd',
                      padding: '15px 0',
                    }}
                  >
                    <div>{item.fullName}</div>
                    <AppButton
                      disabled={isLoadingRemoveCustomer}
                      type='primary'
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveCustomer(item)}
                    ></AppButton>
                  </div>
                ))
              )}

              <AppButton
                disabled={isLoadingRemoveCustomer}
                style={{ marginTop: 15 }}
                type='primary'
                onClick={() => handleShowModalAddCustomer()}
              >
                Add Customer
              </AppButton>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>

      <Modal
        title='Add Customer'
        visible={isShowModalAddCustomer}
        onOk={handleConfirmAddCustomer}
        onCancel={() => {
          setIsShowModalAddCustomer(false);
        }}
        okText={`Confirm ${selectedCustomers.length} Customer`}
        okButtonProps={{ type: 'primary' }}
        width={800}
        centered
      >
        <div style={{ fontWeight: 600, fontSize: 20, marginBottom: 10 }}>
          Selected Customer
        </div>
        <Table
          loading={isLoading}
          columns={columnsSelectedCustomer}
          dataSource={selectedCustomers}
          pagination={false}
        />

        <div
          style={{
            fontWeight: 600,
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          All Customer
        </div>

        <Input
          style={{ marginBottom: 10 }}
          size='large'
          placeholder='Search by Name or Email'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />

        <Table
          loading={isLoadingAllCustomers}
          columns={columnsAllCustomer}
          dataSource={dataAllCustomers}
          pagination={false}
        />
        <Pagination
          current={pagination.page}
          total={pagination.totalData}
          defaultPageSize={pagination.perPage}
          pageSize={pagination.perPage}
          onShowSizeChange={(_current, size) => {
            changeLimit(size);
          }}
          showSizeChanger={true}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={changePage}
        />
      </Modal>
    </AppLayout>
  );
};

export default DiscountEdit;
