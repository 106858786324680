import { Space } from 'antd'
import React from 'react'
import HeaderSection from '../../../components/HeaderSection'
import AppLayout from '../../layout/AppLayout'
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton'
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type'
import AppCard from '../../../components/AppCard'
import DetailItem from '../../../components/DetailItem'
import SectionContent from '../../../components/SectionContent'
import { formatDate } from '../../../helpers/constant'
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs'
import { AppConfigProps, FetchAllAppConfigResponse, FetchOneAppConfigResponse, initialAppConfig } from '../../../types/appConfig.type';

interface IParams {
  appConfigId: string;
}


const AppConfigDetail = () => {
  const {setBreadcrumbDetails} = useDetailBreadcrumbs()
  const history = useHistory()
  const { appConfigId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [appConfigs, setAppConfigs] = React.useState<AppConfigProps>(initialAppConfig)

  const handleClickEdit = () => {
    history.push(`/app-config/${appConfigs.configId}/edit`)
  }

  React.useEffect(() => {
    const fetchDetailAppConfigs = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<FetchOneAppConfigResponse>(
          '/app-configs/id/' + appConfigId
        );

        setAppConfigs(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: 'appConfigId',
            value: appConfigId,
            label: res.data.payload.configId,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchDetailAppConfigs();
  }, []);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title="App Config Detail"
        subtitle="Manage your App Config data"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle="App Config Data">
          <DetailItem label="Headline" content={appConfigs.key} />
          <DetailItem
            label="Subtitle"
            content={appConfigs.value}
          />
          <DetailItem
            label="Created at"
            content={formatDate(appConfigs.createdAt)}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
}

export default AppConfigDetail