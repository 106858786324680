import { BaseResponseProps } from './config.type';


export interface FAQProps {
  faqId: string;
  question: string;
  answer: string;
  isImportant: boolean;
  updatedAt?: Date | string;
  createdAt?: Date | string;
}

export interface FetchAllFAQResponse extends BaseResponseProps {
  payload: {
    count: number
    prev: string
    next: string
    results: FAQProps[]
  }
}

export const initialFAQ: FAQProps = {
  faqId: '',
  question: '',
  answer: '',
  isImportant: true,
  updatedAt: new Date(),
  createdAt: new Date(),
}