import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from '../screens/auth/Login';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Profile from '../screens/profile';
import EditProfile from '../screens/profile/Edit';
import Allergen from '../screens/allergens';
import CustomerMessage from '../screens/customer-message';
import FAQs from '../screens/faq';
import User from '../screens/admin';
import AboutUs from '../screens/web-config/about-us/Edit';
import AppConfig from '../screens/web-config/app-config/Index';
import AppConfigDetail from '../screens/web-config/app-config/Detail';
import AppConfigEdit from '../screens/web-config/app-config/Edit';
import Banner from '../screens/web-config/banner';
import HowItWork from '../screens/web-config/how-it-work';
import Location from '../screens/location';
import Menu from '../screens/menu';
import Tag from '../screens/tag';
import ForgotPassword from '../screens/auth/ForgotPassword';
import ResetPassword from '../screens/auth/ResetPassword';
import UserEdit from '../screens/admin/Edit';
import UserDetail from '../screens/admin/Detail';
import TagDetail from '../screens/tag/Detail';
import TagEdit from '../screens/tag/Edit';
import AllergenDetail from '../screens/allergens/Detail';
import AllergenEdit from '../screens/allergens/Edit';
import MenuEdit from '../screens/menu/Edit';
import HowItWorkEdit from '../screens/web-config/how-it-work/Edit';
import FAQEdit from '../screens/faq/Edit';
import PrivacyPolicyEdit from '../screens/privacy-policy/Edit';
import TnCEdit from '../screens/tnc/Edit';
import CustomerDetail from '../screens/customers/Detail';
import Customer from '../screens/customers';
import MenuDetail from '../screens/menu/Detail';
import LocationEdit from '../screens/location/Edit';
import LocationDetail from '../screens/location/Detail';
import BannerEdit from '../screens/web-config/banner/Edit';
import HowItWorkDetail from '../screens/web-config/how-it-work/Detail';
import BannerDetail from '../screens/web-config/banner/Detail';
import Schedule from '../screens/schedule';
import ScheduleEdit from '../screens/schedule/Edit';
import Transaction from '../screens/transaction';
import TransactionSummary from '../screens/transaction-summary';
import TransactionDetail from '../screens/transaction/Detail';
import CustomerEdit from '../screens/customers/Edit';
import ChangeMyPassword from '../screens/profile/ChangeMyPassword';
import ChangePassword from '../screens/admin/ChangePassword';
import Dashboard from '../screens/dashboard';
import Category from '../screens/category';
import CategoryDetail from '../screens/category/Detail';
import CategoryEdit from '../screens/category/Edit';
import Discount from '../screens/discount';
import DiscountEdit from '../screens/discount/Edit';
import DiscountDetail from '../screens/discount/Detail';
import DeliveryCoverage from '../screens/delivery-coverage';
import DeliveryCoverageEdit from '../screens/delivery-coverage/Edit';
import DeliveryCoverageDetail from '../screens/delivery-coverage/Detail';
import EditSchedule2 from '../screens/schedule/Edit2';
import FeesDetail from '../screens/fees/Detail';
import Fees from '../screens/fees';
import Packaging from '../screens/packaging';
import Referral from '../screens/reseller';
import ReferralDetail from '../screens/reseller/Detail';
import ReferralEdit from '../screens/reseller/Edit';

const RootNavigator = () => {
  return (
    <Router>
      <Switch>
        {/* Public Route */}
        <PublicRoute path='/reset-password/:sessionId' restricted={true}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute exact path='/' restricted={true}>
          <Login />
        </PublicRoute>
        <PublicRoute path='/login' restricted={true}>
          <Login />
        </PublicRoute>
        <PublicRoute path='/forgot-password' restricted={true}>
          <ForgotPassword />
        </PublicRoute>

        <PrivateRoute path='/dashboard'>
          <Dashboard />
        </PrivateRoute>

        {/* Private Route */}
        <PrivateRoute exact path='/profile/edit'>
          <EditProfile />
        </PrivateRoute>
        <PrivateRoute exact path='/profile'>
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path='/change-password'>
          <ChangeMyPassword />
        </PrivateRoute>

        {/* Master Data Group */}
        <PrivateRoute exact path='/menu/:menuType/:menuId/edit'>
          <MenuEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/menu/:menuType/add'>
          <MenuEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/menu/:menuType/add'>
          <MenuEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/menu/:menuId'>
          <MenuDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/menu'>
          <Menu />
        </PrivateRoute>

        {/* <PrivateRoute exact path='/tag/:tagId/edit'>
          <TagEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/tag/add'>
          <TagEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/tag/:tagId'>
          <TagDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/tag'>
          <Tag />
        </PrivateRoute> */}

        <PrivateRoute exact path='/category/:categoryId/edit'>
          <CategoryEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/category/add'>
          <CategoryEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/category/:categoryId'>
          <CategoryDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/category'>
          <Category />
          {/* <IndexCategory /> */}
        </PrivateRoute>

        <PrivateRoute exact path='/dietaries/:dietariesId/edit'>
          <AllergenEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/dietaries/add'>
          <AllergenEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/dietaries/:dietariesId'>
          <AllergenDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/dietaries'>
          <Allergen />
        </PrivateRoute>

        <PrivateRoute exact path='/pickup-location/:locationId/edit'>
          <LocationEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/pickup-location/add'>
          <LocationEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/pickup-location/:locationId'>
          <LocationDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/pickup-location'>
          <Location />
        </PrivateRoute>

        <PrivateRoute exact path='/delivery-coverage/:deliveryCoverageId/edit'>
          <DeliveryCoverageEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/delivery-coverage/add'>
          <DeliveryCoverageEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/delivery-coverage/:deliveryCoverageId'>
          <DeliveryCoverageDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/delivery-coverage'>
          <DeliveryCoverage />
        </PrivateRoute>

        <PrivateRoute exact path='/schedule/:scheduleAt/detail'>
          <EditSchedule2 />
        </PrivateRoute>
        <PrivateRoute exact path='/schedule/:scheduleAt/edit'>
          <EditSchedule2 />
        </PrivateRoute>
        <PrivateRoute path='/schedule/'>
          <Schedule />
        </PrivateRoute>

        <PrivateRoute exact path='/discount/:discountId/edit'>
          <DiscountEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/discount/add'>
          <DiscountEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/discount/:discountId'>
          <DiscountDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/discount'>
          <Discount />
        </PrivateRoute>

        <PrivateRoute exact path='/order/:transactionId'>
          <TransactionDetail />
        </PrivateRoute>
        <PrivateRoute path='/order/'>
          <Transaction />
        </PrivateRoute>

        <PrivateRoute exact path='/order-summary/'>
          <TransactionSummary />
        </PrivateRoute>

        {/* User Management Group */}
        <PrivateRoute exact path='/user/:userId/change-password'>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute exact path='/user/:userId/edit'>
          <UserEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/user/add'>
          <UserEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/user/:userId'>
          <UserDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/user'>
          <User />
        </PrivateRoute>

        <PrivateRoute exact path='/customer/:userId/change-password'>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute exact path='/customer/:customerId/edit'>
          <CustomerEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/customer/add'>
          <CustomerEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/customer/:customerId'>
          <CustomerDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/customer'>
          <Customer />
        </PrivateRoute>

        {/* Feedback Group */}
        <PrivateRoute path='/customer-message'>
          <CustomerMessage />
        </PrivateRoute>

        {/* Configuration Group */}
        <PrivateRoute exact path='/banner'>
          <Banner />
        </PrivateRoute>
        <PrivateRoute exact path='/banner/add'>
          <BannerEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/banner/:bannerId'>
          <BannerDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/banner/:bannerId/edit'>
          <BannerEdit />
        </PrivateRoute>

        <PrivateRoute exact path='/how-it-work'>
          <HowItWork />
        </PrivateRoute>
        <PrivateRoute exact path='/how-it-work/add'>
          <HowItWorkEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/how-it-work/:howitworksId'>
          <HowItWorkDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/how-it-work/:howitworksId/edit'>
          <HowItWorkEdit />
        </PrivateRoute>

        <PrivateRoute path='/about-us'>
          <AboutUs />
        </PrivateRoute>
        <PrivateRoute exact path='/app-config'>
          <AppConfig />
        </PrivateRoute>
        <PrivateRoute exact path='/app-config/:appConfigId'>
          <AppConfigDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/app-config/:appConfigId/edit'>
          <AppConfigEdit />
        </PrivateRoute>
        <PrivateRoute path='/privacy-policy'>
          <PrivacyPolicyEdit />
        </PrivateRoute>
        <PrivateRoute path='/terms-and-conditions'>
          <TnCEdit />
        </PrivateRoute>
        <PrivateRoute path='/faq' exact>
          <FAQs />
        </PrivateRoute>
        <PrivateRoute path='/faq/add' exact>
          <FAQEdit />
        </PrivateRoute>
        <PrivateRoute path='/faq/:faqId/edit' exact>
          <FAQEdit />
        </PrivateRoute>

        {/* Fees */}
        {/* <PrivateRoute exact path="/fees">
          <Fees />
        </PrivateRoute>
        <PrivateRoute exact path="/fees/:key">
          <FeesDetail />
        </PrivateRoute> */}

        <PrivateRoute exact path='/packaging'>
          <Packaging />
        </PrivateRoute>

        <PrivateRoute exact path='/reseller/:resellerId/edit'>
          <ReferralEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/reseller/add'>
          <ReferralEdit />
        </PrivateRoute>
        <PrivateRoute exact path='/reseller/:resellerId'>
          <ReferralDetail />
        </PrivateRoute>
        <PrivateRoute exact path='/reseller'>
          <Referral />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default RootNavigator;
