import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface HeaderSectionProps {
  icon: React.ReactNode | 'back' | undefined
  title: string
  subtitle?: string
  rightAction?: React.ReactNode | React.ReactDOM | undefined
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ icon, title, subtitle, rightAction }) => {
  const history = useHistory()
  return (
    <Container>
      <WrapperTitle>
        {icon === 'back' ? (
          <WrapperIcon style={{cursor: 'pointer'}} onClick={() => {
            history.goBack()
          }}><LeftOutlined /></WrapperIcon>
        ) : (
          <WrapperIcon>{icon}</WrapperIcon>
        )}

        <Title>{title}</Title>
        {subtitle && (
          <Divider
            type="vertical"
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 15,
              marginRight: 15,
              height: 30,
            }}
          />
        )}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </WrapperTitle>
      <WrapperAction>{rightAction}</WrapperAction>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const WrapperAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const WrapperIcon = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.ash400};
`

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black};
  margin-left: 15px;
`

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.colors.charcoal300};
`

export default HeaderSection