import React, { useState } from 'react';
import AppLayout from '../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { MoreOutlined, SearchOutlined, QuestionOutlined } from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
  Modal,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import { BaseResponseProps } from '../../types/config.type';
import { FAQProps, initialFAQ } from '../../types/faq.type';
import { ContainerFilter } from '../admin';
import useFetchList from '../../hooks/useFetchList';

interface ResponseProps extends BaseResponseProps {
  payload: Omit<FAQProps, 'createdAt' | 'updatedAt'>;
}

const FAQ = () => {
  const history = useHistory();

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    fetchList,
    changeLimit
  } = useFetchList<FAQProps>({
    endpoint: 'faqs',
    initialQuery: {
      type: 'tag',
      isPublished: undefined,
    }
  });

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<FAQProps>(initialFAQ);
  const [isLoadingUpdateIsImportant, setIsLoadingUpdateIsImportant] = React.useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [willBeDeleted, setWillBeDeleted] = useState<FAQProps>();

  const handleCreateFAQ = () => {
    history.push('/faq/add');
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: '1' | '0' | string) => {
    if (status === 'all') {
      setQuery((oldVal) => ({ ...oldVal, isImportant: undefined }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isImportant: status }));
    }
  };

  const handleClickEdit = (e: FAQProps) => {
    history.push(`/faq/${e.faqId}/edit`);
  };

  const handleClickDelete = async () => {
    if (willBeDeleted) {
      setIsLoadingAction(true);
      await httpRequest.delete('/faqs/' + willBeDeleted.faqId);
      setWillBeDeleted(undefined);
      setIsLoadingAction(false);

      fetchList();
    }
  };

  const handleIsImportantChange = async () => {
    try {
      setIsLoadingUpdateIsImportant(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.faqId === tmpData.faqId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const newIsImportant = tmpData.isImportant ? false : true;

      const res = await httpRequest.patch<ResponseProps>(
        '/faqs/' + tmpData.faqId,
        {
          faqId: tmpData.faqId,
          isImportant: newIsImportant,
        }
      );

      newData = data.map((item) => {
        if (item.faqId === res.data.payload.faqId) {
          return {
            ...item,
            isImportant: res.data.payload.isImportant,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success('Changed successfully');

      setIsLoadingUpdateIsImportant(false);
      setIsModalVisible(false);
      setTmpData(initialFAQ);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialFAQ);
      setIsLoadingUpdateIsImportant(false);
    }
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (text: string, record: FAQProps) => {
        return (
          <div className="table-link" onClick={() => handleClickEdit(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Answer',
      key: 'answer',
      dataIndex: 'answer',
      render: (text: string) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Important',
      key: 'isImportant',
      dataIndex: 'isImportant',
      render: (isImportant: any, record: FAQProps) => (
        <>
          <Switch
            loading={isLoadingUpdateIsImportant}
            checked={isImportant}
            onChange={() => {
              setIsModalVisible(true);
              setTmpData(record);
            }}
          />
        </>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: FAQProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: FAQProps) => (
    <Menu
      onClick={({ key }) => {
        if (key === 'edit') {
          handleClickEdit(record);
        } else if (key === 'delete') {
          setWillBeDeleted(record);
        }
      }}
    >
      <Menu.Item key="edit">Edit FAQ</Menu.Item>
      <Menu.Item key="delete">Delete FAQ</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<QuestionOutlined />}
        title="Frequently Ask Question"
        subtitle="Manage your Frequently Ask Question data"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleCreateFAQ}>
              Add Frequently Ask Question
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size="large"
          placeholder="Search by question"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size="large"
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder="Status"
        >
          <Option value="all">All</Option>
          <Option value="1">Important</Option>
          <Option value="0">Not Important</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title="Confirmation"
        visible={isModalVisible}
        onOk={handleIsImportantChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialFAQ);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateIsImportant}
        okButtonProps={{ type: 'primary' }}
      >
        <div style={{ background: '#F2F2F2', padding: 10 }}>
          {tmpData.question}
        </div>
        <p>
          Are you sure want to change to be{' '}
          <b>"{tmpData.isImportant ? 'NOT IMPORTANT' : 'IMPORTANT'}"</b>?.{' '}
        </p>
      </Modal>

      <Modal
        title="Confirmation"
        visible={!!willBeDeleted}
        onOk={handleClickDelete}
        onCancel={() => {
          setWillBeDeleted(undefined);
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <p>Are you sure want to change to delete this FAQ?</p>
      </Modal>
    </AppLayout>
  );
};

export default FAQ;
