import { Space, Tag } from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import {
  DeliveryCoverageProperties,
  initialDeliveryCoverage,
} from "../../types/delivery-coverage.type";

interface IParams {
  deliveryCoverageId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<DeliveryCoverageProperties, "createdAt" | "updatedAt">;
}

const DeliveryCoverageDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { deliveryCoverageId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [deliveryCoverage, setDeliveryCoverage] =
    React.useState<DeliveryCoverageProperties>(initialDeliveryCoverage);
  const [postalCodes, setPostalCodes] = React.useState<string[]>([]);

  const handleClickEdit = () => {
    history.push(`/delivery-coverage/${deliveryCoverage.id}/edit`);
  };

  React.useEffect(() => {
    const fetchDetailDeliveryCoverage = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/delivery-coverages/" + deliveryCoverageId
        );
        setPostalCodes(Object.keys(res.data.payload.postalCode));
        setDeliveryCoverage(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: "discountId",
            value: deliveryCoverageId,
            label: res.data.payload.coverageName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDetailDeliveryCoverage();
  }, [deliveryCoverageId]);

  const _renderPostcodes = () => {
    return (
      <>
        {Object.keys(deliveryCoverage.postalCode).map((key, idx) => (
          <Tag key={idx} closable={false}>
            {key} (${deliveryCoverage.postalCode[key]})
          </Tag>
        ))}
      </>
    );
  };

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Delivery Coverage Detail'
        subtitle='Manage your delivery coverage data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle='Delivery Coverage Data'>
          <DetailItem label='Name' content={deliveryCoverage.coverageName} />
          <DetailItem
            label='Status'
            content={deliveryCoverage.isPublished ? "Active" : "Inactive"}
          />
          <DetailItem
            label='Postcode'
            content={
              <div
                style={{
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {_renderPostcodes()}
              </div>
            }
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default DeliveryCoverageDetail;
