import React from 'react';
import { Provider } from 'react-redux';
import RootNavigator from './navigation/RootNavigator';
import store from './store';
import './assets/app.less';
import { theme } from './assets/theme';
import { ThemeProvider } from 'styled-components';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RootNavigator />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
