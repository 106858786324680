import { CategoryProps } from "./category.type";
import { BaseResponseProps } from "./config.type";
import { TagProps } from "./tag.type";

export interface VariantProps {
  menuVariantId?: string;
  menuId?: string;
  price: number | undefined;
  label: string;
  isDefaultPrice: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface MenuProps {
  menuId?: string;
  name: string;
  description: string;
  isPublished: boolean;
  isTopPick: boolean;
  images: Array<{
    fileId: string;
    imageUrl: string | null;
  }>;
  variants: VariantProps[];
  tags?: TagProps[];
  allergens?: TagProps[];
  updatedAt?: Date | string;
  createdAt?: Date | string;
  statusLoading?: boolean;
  category?: CategoryProps;
  categoryId?: string;
  itemIds?: string[];
  maxQty?: number;
  price?: number;
  bestSeller?: boolean;
  isSelected?: boolean;
  items?: MenuProps[];
}

export interface FetchAllMenuResponse extends BaseResponseProps {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: MenuProps[];
  };
}

export const initialVariant: VariantProps = {
  price: undefined,
  label: "Default",
  isDefaultPrice: true,
};

export const initialMenu: MenuProps = {
  menuId: "",
  name: "",
  description: "",
  isPublished: false,
  isTopPick: false,
  images: [
    {
      fileId: "",
      imageUrl: "",
    },
  ],
  variants: [initialVariant],
  tags: [],
  updatedAt: "",
  createdAt: "",
  statusLoading: false,
};
