import React, { useRef } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
  INITIAL_PAGINATION,
  INITIAL_QUERY,
  IPagination,
} from '../helpers/pagination';
import {
  BaseResponsePaginationProps,
  DefaultQuery,
} from '../types/config.type';

type Props = {
  lazyQuery?: boolean;
  endpoint: string;
  initialQuery?: Object;
  limit?: number;
};

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType>(props: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<DataType>>([]);
  const [pagination, setPagination] = React.useState<IPagination>({
    ...INITIAL_PAGINATION,
  });
  const [query, setQuery] = React.useState<
    DefaultQuery & { [key: string]: any }
  >({
    ...INITIAL_QUERY,
    limit: props.limit || DEFAULT_LIMIT,
    ...props.initialQuery,
  });
  const [isBlocked, setIsBlocked] = React.useState(props.lazyQuery);

  const [search, setSearch] = React.useState<string>();
  const [searchValue] = useDebounce(search, 500);
  const ref = useRef(false);

  const fetchList = async () => {
    ref.current = true;
    try {
      setIsLoading(true);

      const res = await httpRequest.get<BaseResponsePaginationProps<DataType>>(
        `${props.endpoint}${generateQueryString(query)}`
      );

      setPagination((oldVal) => {
        return {
          ...oldVal,
          perPage: query.limit || props.limit || DEFAULT_LIMIT,
          prev: res.data.payload.prev,
          next: res.data.payload.next,
          totalData: res.data.payload.count,
          countPage: Math.ceil(
            res.data.payload.count / (props.limit || DEFAULT_LIMIT)
          ),
        };
      });
      setData(res.data.payload.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

    ref.current = false;
  };

  React.useEffect(() => {
    if (!isBlocked) {
      console.log('triggered');
      if (!ref.current) {
        fetchList();
      }
    }
    setIsBlocked(false);
  }, [query]);

  React.useEffect(() => {
    setQuery((e: DefaultQuery) => {
      return { ...e, search: searchValue as string };
    });
  }, [searchValue]);

  const changePage = (page: number) => {
    setPagination((oldVal) => {
      return {
        ...oldVal,
        page,
      };
    });
    setQuery((oldVal) => {
      return {
        ...oldVal,
        offset: (page - 1) * (props.limit || DEFAULT_LIMIT),
      };
    });
  };

  const changeLimit = (perPage: number) => {
    setPagination((oldVal) => {
      return {
        ...oldVal,
        perPage,
      };
    });
    setQuery((oldVal) => {
      return {
        ...oldVal,
        limit: perPage,
        offset: 0,
      };
    });
  };

  return {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setPagination,
    setQuery,
    setSearch,
    changePage,
    changeLimit,
    fetchList,
    setIsLoading,
  };
}
