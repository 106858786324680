import { Col, Divider, Space, Typography } from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import { initialDiscount, DiscountProperties } from "../../types/discount.type";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";

interface IParams {
  discountId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<DiscountProperties, "createdAt" | "updatedAt">;
}
const { Text } = Typography;

const DiscountDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { discountId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [discount, setDiscount] =
    React.useState<DiscountProperties>(initialDiscount);

  const handleClickEdit = () => {
    history.push(`/discount/${discount.discountId}/edit`);
  };

  React.useEffect(() => {
    const fetchDetailDiscount = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/discounts/" + discountId
        );

        setDiscount(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: "discountId",
            value: discountId,
            label: res.data.payload.discountName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDetailDiscount();
  }, [discountId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Discount Detail'
        subtitle='Manage your discount data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent
          groupTitle='Discount Data'
          helpers={[
            {
              title: "Information",
              content:
                "Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.",
            },
          ]}
        >
          <DetailItem label='Name' content={discount.discountName} />
          <DetailItem label='Amount' content={`${discount.amount}%`} />
          <DetailItem label='Description' content={discount.description} />
          <DetailItem
            label='Status'
            content={discount.isPublished ? "Active" : "Inactive"}
          />
          <DetailItem
            label='Created at'
            content={formatDate(discount.createdAt)}
          />
        </SectionContent>
        <Divider />
        <SectionContent
          groupTitle='Customers'
          helpers={[
            {
              title: "Information",
              content:
                "Nunc sed blandit libero volutpat sed cras ornare arcu dui. Justo nec ultrices dui sapien. Morbi tincidunt ornare massa eget.",
            },
          ]}
        >
          <DetailItem
            label={`List of Customers :`}
            content={
              discount.discount_users && discount.discount_users.length > 0 ? (
                <>
                  {discount.discount_users?.map((item, idx) => {
                    return (
                      <div style={{ marginTop: 15 }} key={idx}>
                        {idx + 1}. {item.customer?.fullName}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{ opacity: 0.5, fontStyle: "italic", marginTop: 15 }}
                >
                  Data empty
                </div>
              )
            }
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default DiscountDetail;
