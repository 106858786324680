import AppLayout from '../layout/AppLayout';
import { Table, Col, Tabs, Space, Card, Spin, Empty } from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { ContainerFilter } from '../admin';
import moment, { Moment } from 'moment';
import {
  ETransactionStatus,
  TransactionSummaryProps,
} from '../../types/transaction.type';
import { ReactComponent as OrderLogo } from '../../assets/icons/order-icon.svg';
import './summary.css';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import AppButton from '../../components/AppButton';
import { getToken } from '../../helpers/auth';
import { httpRequest } from '../../helpers/api';
import { generateQueryString } from '../../helpers/generateQueryString';
import DatePicker from 'react-horizontal-datepicker';
import Calendar from '../../components/Calender/Calendar';
import useFetchList from '../../hooks/useFetchList';

const columns = [
  {
    title: 'Name',
    key: 'Name',
    dataIndex: 'productName',
    render: (_text: string, record: TransactionSummaryProps) => {
      return (
        <Col>
          <div style={{ fontWeight: 'bold' }}>{record.productName}</div>
        </Col>
      );
    },
  },
  {
    title: 'Variant',
    key: 'variant',
    dataIndex: 'productVariantName',
    render: (_text: string, record: TransactionSummaryProps) => {
      return (
        <div style={{ textAlign: 'left' }}>
          {record.productVariantName
            ? record.productVariantName
            : record.productName}
        </div>
      );
    },
  },
  {
    title: 'Total',
    key: 'totalQuantity',
    dataIndex: 'totalQuantity',
    ellipsis: true,
    render: (_total: string, record: TransactionSummaryProps) => {
      return (
        <Col>
          <div style={{ color: '#FF4A50' }}>{`${record.totalQuantity}`}</div>
        </Col>
      );
    },
  },
  {
    title: 'Pickup',
    key: 'totalQuantityPickup',
    dataIndex: 'totalQuantityPickup',
    render: (_total: number, record: TransactionSummaryProps) => {
      return (
        <div style={{ textAlign: 'left' }}>{record.totalQuantityPickup}</div>
      );
    },
  },
  {
    title: 'Delivery',
    key: 'totalQuantityDelivery',
    dataIndex: 'totalQuantityDelivery',
    ellipsis: true,
    render: (_total: ETransactionStatus, record: TransactionSummaryProps) => {
      return (
        <div style={{ textAlign: 'left' }}>{record.totalQuantityDelivery}</div>
      );
    },
  },
];

const TransactionSummary = () => {
  const [currentDate, setCurrentDate] = useState<Moment>(
    moment().startOf('day')
  );
  const [data, setData] = useState<TransactionSummaryProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      const res = await httpRequest.get<{
        payload: TransactionSummaryProps[];
      }>(`/transactions/summary/order-summary`);
      setData(res.data.payload);
    }
    getData();
  }, []);

  const onClickExport = async (scheduledAt: string) => {
    setLoading(true);
    axios({
      url: `transactions/export/xlsx`,
      method: 'POST',
      data: { shipmentDates: scheduledAt },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getToken()),
      },
      responseType: 'blob',
    })
      .then((res: any) => {
        //          // 1. Create blob link to download
        //   const url = window.URL.createObjectURL(result);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.setAttribute("download", `BPOM Register Primer.xlsx`);
        //   // 2. Append to html page
        //   document.body.appendChild(link);
        //   // 3. Force download
        //   link.click();
        //   // 4. Clean up and remove the link
        //   link.parentNode?.removeChild(link);
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `spoonfull-kitchen-${scheduledAt}-downloadedAt-${moment()
              .format('YYYY-MM-DD:hh:mm:ss')
              .toString()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      })
      .catch((err) => {
        console.error('Error Export PDF', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDetailsHandle = (dayStr: any) => {
    console.log('dayStr', moment(dayStr).startOf('day').format('YYYY-MM-DD'));
    setCurrentDate(moment(dayStr).startOf('day'));
  };

  return (
    <AppLayout>
      <HeaderSection
        icon={<OrderLogo />}
        title='Orders'
        subtitle='Manage your transactions summary data'
        rightAction={
          <Space>
            <AppButton
              loading={loading}
              type='primary'
              onClick={() => {
                onClickExport(currentDate.format('YYYY-MM-DD'));
              }}
            >
              Export Excel ({currentDate.format('DD MMM YYYY')})
            </AppButton>
          </Space>
        }
      />
      <>
        <ContainerFilter>
          {/* <DatePicker
            color={"red"}
            getSelectedDay={(val: any) => {
              setCurrentDate(moment(val).startOf("day"));
            }}
            selectDate={new Date(currentDate.toDate())}
            endDate={50}
          /> */}
          <Calendar showDetailsHandle={showDetailsHandle} data={data} />
        </ContainerFilter>

        <Card>
          <SummaryContent currentDate={currentDate} />
        </Card>
      </>
    </AppLayout>
  );
};

export default TransactionSummary;

type SummaryProps = {
  currentDate: Moment;
};

function SummaryContent(summaryProps: SummaryProps) {
  const perPage = 25;
  const [numberOfPaginationButton, setNumberOfPaginationButton] = useState<
    number[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const [filteredSummaries, setFilteredSummaries] =
    useState<TransactionSummaryProps[]>();

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const res = await httpRequest.get<{
          payload: TransactionSummaryProps[];
        }>(
          `/transactions/summary/order-summary${generateQueryString({
            scheduledAt: summaryProps.currentDate.format('YYYY-MM-DD'),
          })}`
        );

        const filteredList = res.data.payload.filter(
          (_, index) =>
            index >= (page - 1) * perPage && index <= perPage * page - 1
        );

        const buttonNumber = Math.ceil(res.data.payload.length / perPage);
        setNumberOfPaginationButton(
          Array.from({ length: buttonNumber }, (v, i) => i)
        );
        setFilteredSummaries(filteredList);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [summaryProps.currentDate, page]);

  return (
    <>
      {isLoading ? (
        <Spin style={{ margin: '30vh 35vw' }} />
      ) : (
        <Tabs
          // activeKey={page.toString()}
          defaultActiveKey={'0'}
          onChange={(activeKey) => {
            setPage(parseInt(activeKey) + 1);
          }}
        >
          {numberOfPaginationButton ? (
            numberOfPaginationButton.map((item, index) => {
              return (
                <Tabs.TabPane tab={`Page ${item + 1}`} key={index.toString()}>
                  <Table
                    rowClassName={(_, index) =>
                      index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                    loading={isLoading}
                    columns={columns}
                    dataSource={filteredSummaries}
                    pagination={false}
                  />
                </Tabs.TabPane>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Tabs>
      )}
    </>
  );
}
