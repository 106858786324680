import { Row, Col, Image, Typography, Space } from 'antd'
import React from 'react'
import styled from 'styled-components';

const { Text, Title } = Typography;

type Props = {
  title: string;
  subtitle: string;
  rightImageUrl: string;
}
export default function HeaderBannerItem(props: Props) {
  return (
    <Col style={{ border: '1px solid lightgrey', padding: 20 }}>
      {/** Header Menu */}
      <Row>
        <Col span={12}>
          <Image
            style={{ textAlign: 'center', paddingLeft: 20 }}
            preview={false}
            height={30}
            src={'/images/logo-spoonful.svg'}
            fallback={'/images/blur-image.jpeg'}
            placeholder={
              <Image
                preview={false}
                src="/images/blur-image.jpeg"
                height={30}
              />
            }
          />
        </Col>

        <Col span={12}>
          <Space
            direction="horizontal"
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            {[1, 2, 3, 4, 5, 6].map((_) => (
              <div
                style={{
                  background: 'lightgrey',
                  width: 55,
                  height: 10,
                  borderRadius: 10,
                }}
              ></div>
            ))}
          </Space>
        </Col>
      </Row>

      {/** Header Banner */}
      <Row>
        <Col
          span={12}
          style={{
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 50,
          }}
        >
          <Title level={1}>
            {props.title || 'Your Title Will Be Shown Here'}
          </Title>
          <Text>
            {props.subtitle}
          </Text>

          <div
            style={{
              marginTop: 20,
              background: 'lightgrey',
              width: '100%',
              height: 30,
              borderRadius: 30,
            }}
          ></div>
        </Col>

        <Col span={12} style={{ textAlign: 'center' }}>
          <Image
            style={{ textAlign: 'center' }}
            preview={false}
            width={300}
            height={300}
            src={props.rightImageUrl || '/images/select-image.jpg'}
            fallback={'/images/blur-image.jpeg'}
            placeholder={
              <Image
                preview={false}
                src="/images/blur-image.jpeg"
                width={300}
                height={300}
              />
            }
          />
        </Col>
      </Row>
    </Col>
  );
}

