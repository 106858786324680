import { FileProtectOutlined } from '@ant-design/icons';
import { Form, FormInstance, message, Space } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { initialTnC, TnCProps } from '../../types/tnc.type';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../helpers/formRules';

interface ILocation {}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<TnCProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const TnCEdit: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ILocation>();
  const formRef =
    React.useRef<FormInstance<Omit<TnCProps, 'createdAt' | 'updatedAt'>>>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [tnc, setTnC] = React.useState<TnCProps>(initialTnC);

  const updateTnC = async (
    props: Omit<TnCProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch('/terms-and-conditions', {
        content: tnc.content,
      });
      message.success('Success update terms and conditions');

      setIsLoadingAction(false);

      fetchTnCDetail();
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<TnCProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    if (!tnc.content || tnc.content === '<p><br></p>') {
      message.warn('Terms and Conditions is empty');
    } else {
      updateTnC(values);
    }
  };

  const fetchTnCDetail = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<ResponseProps>('/terms-and-conditions');
      setTnC(res.data.payload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTnCDetail();
  }, [location]);

  return (
    <AppLayout>
      <HeaderSection
        icon={<FileProtectOutlined />}
        title={'Edit Terms and Conditions'}
        subtitle='Manage your Terms and Conditions data'
        rightAction={
          <Space>
            {/* <AppButton onClick={() => history.goBack()}>Cancel</AppButton> */}
            <AppButton
              loading={isLoadingAction}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='tncForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={tnc}
          autoComplete='off'
        >
          <Form.Item
            name='tncText'
            // rules={generateFormRules("Terms and Conditions", ["required"])}
          >
            <div style={{ display: 'none' }}>{tnc.content}</div>
            <ReactQuill
              theme='snow'
              value={tnc.content}
              onChange={(content) =>
                setTnC({
                  ...tnc,
                  content: content,
                })
              }
              modules={quillModules}
              formats={quillFormats}
            />
          </Form.Item>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default TnCEdit;
