import { BaseResponseProps } from './config.type';


export interface HowItWorkProps {
  howitworksId: string;
  headline: string;
  subtitle: string;
  queueOrder: number;
  howitworksImg: string;
  image?: any; // for upload file
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface FetchAllHowItWorkResponse extends BaseResponseProps {
  payload: {
    count: number
    prev: string
    next: string
    results: HowItWorkProps[]
  }
}

export const initialHowItWork: HowItWorkProps = {
  howitworksId: '',
  headline: '',
  subtitle: '',
  queueOrder: 0,
  howitworksImg: '',
  createdAt: new Date(),
  updatedAt: new Date()
}