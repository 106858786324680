import AppLayout from '../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import { BaseResponseProps } from '../../types/config.type';
import { ContainerFilter } from '../admin';
import useFetchList from '../../hooks/useFetchList';
import { ResellerProperties, EResellerStatus } from '../../types/reseller.type';
import { useEffect, useState } from 'react';

interface ResponseProps extends BaseResponseProps {
  payload: Omit<ResellerProperties, 'createdAt' | 'updatedAt'>;
}

const Referral = () => {
  const history = useHistory();

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<ResellerProperties>({
    endpoint: 'resellers',
  });

  const handleStatusChange = async (record: ResellerProperties) => {
    try {
      let newData = [];
      newData = data.map((item) => {
        if (item.resellerId === record.resellerId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        '/resellers/' + record.resellerId,
        {
          resellerId: record.resellerId,
          status:
            record.status === EResellerStatus.ACTIVE
              ? EResellerStatus.INACTIVE
              : EResellerStatus.ACTIVE,
        }
      );

      newData = data.map((item) => {
        if (item.resellerId === res.data.payload.resellerId) {
          return {
            ...item,
            status: res.data.payload.status,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success('Success change ' + record.resellerName + ' status.');
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleCreateReseller = () => {
    history.push('/reseller/add');
  };

  const handleClickDetail = (e: ResellerProperties) => {
    history.push(`/reseller/${e.resellerId}`);
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
    if (status !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
    }
  };

  const handleClickEdit = (e: ResellerProperties) => {
    history.push(`/reseller/${e.resellerId}/edit`);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'resellerName',
      key: 'resellerName',
      render: (value: string, record: ResellerProperties) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {value}
          </div>
        );
      },
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (value: string, record: ResellerProperties) => {
        if (value) {
          return <div>{value}</div>;
        }

        return (
          <div style={{ fontStyle: 'italic', color: 'gray' }}>Not set</div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: EResellerStatus, record: ResellerProperties) => {
        return (
          <>
            <Switch
              loading={record.statusLoading}
              checked={status === EResellerStatus.ACTIVE ? true : false}
              onChange={() => {
                handleStatusChange(record);
              }}
            />
          </>
        );
      },
    },
    {
      title: 'Total Customers',
      dataIndex: 'dataCustomers',
      key: 'dataCustomers',
      render: (_: any, record: ResellerProperties) => (
        <div>{record.reseller_users?.length}</div>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: ResellerProperties) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: ResellerProperties) => (
    <Menu
      onClick={({ key }) => {
        if (key === 'detail') {
          handleClickDetail(record);
        } else if (key === 'edit') {
          handleClickEdit(record);
        }
      }}
    >
      <Menu.Item key='detail'>Detail Reseller</Menu.Item>
      <Menu.Item key='edit'>Edit Reseller</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<TagOutlined />}
        title='Reseller'
        subtitle='Manage your reseller data'
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 20, margin: '15px 0' }}>
          Spoonful Reseller
        </div>
        <AppButton type='primary' onClick={handleCreateReseller}>
          Add Reseller
        </AppButton>
      </div>

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Reseller name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </AppLayout>
  );
};

export default Referral;
