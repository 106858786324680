import React from "react";
import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import { MoreOutlined, SearchOutlined, ShopOutlined } from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import styled from "styled-components";
import { initialLocation, LocationProps } from "../../types/location.type";
import useFetchList from "../../hooks/useFetchList";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<LocationProps, "createdAt" | "updatedAt">;
}

const Locations = () => {
  const history = useHistory();

  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<LocationProps>(initialLocation);
  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<LocationProps>({
    endpoint: "locations",
  });

  const handlePickUpChange = async () => {
    try {
      setIsLoadingUpdateStatus(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.locationId === tmpData.locationId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/locations/" + tmpData.locationId,
        {
          locationId: tmpData.locationId,
          isPickup: !tmpData.isPickup,
        }
      );

      newData = data.map((item) => {
        if (item.locationId === res.data.payload.locationId) {
          return {
            ...item,
            isPickup: res.data.payload.isPickup,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.locationName + " pickup.");

      setIsLoadingUpdateStatus(false);
      setIsModalVisible(false);
      setTmpData(initialLocation);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialLocation);
      setIsLoadingUpdateStatus(false);
    }
  };

  const handleCreateLocation = () => {
    history.push("/pickup-location/add");
  };

  const handleClickDetail = (e: LocationProps) => {
    history.push(`/pickup-location/${e.locationId}`);
  };

  const { Option } = Select;

  const handleChangeType = (type: string) => {
    if (type !== "all") {
      setQuery((oldVal) => ({ ...oldVal, locationType: type }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, locationType: undefined }));
    }
  };

  const handleClick = (key: string, e: LocationProps) => {
    if (key === "edit") {
      history.push(`/pickup-location/${e.locationId}/edit`);
    } else if (key === "detail") {
      history.push(`/pickup-location/${e.locationId}`);
      // history.push(`/location/${e.locationId}/delete`)
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "locationName",
      key: "locationName",
      render: (text: string, record: LocationProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "locationCode",
      key: "locationCode",
    },
    {
      title: "Address",
      dataIndex: "locationAddress",
      key: "locationAddress",
    },
    {
      title: "Contact",
      dataIndex: "locationContact",
      key: "locationContact",
    },
    {
      title: "Location Type",
      key: "locationType",
      dataIndex: "locationType",
      render: (locationType: any, record: LocationProps) => (
        <>{locationType === "main_kitchen" ? "Main Kitchen" : "Outlet"}</>
      ),
    },
    // {
    //   title: 'Pick Up?',
    //   key: 'isPickup',
    //   dataIndex: 'isPickup',
    //   render: (status: any, record: LocationProps) => (
    //     <>
    //       {
    //         <Switch
    //           loading={record.statusLoading}
    //           checked={record.isPickup}
    //           onChange={() => {
    //             setIsModalVisible(true);
    //             setTmpData(record);
    //           }}
    //         />
    //       }
    //     </>
    //   ),
    // },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: LocationProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: LocationProps) => (
    <Menu onClick={(e) => handleClick(e.key, record)}>
      <Menu.Item key="edit">Edit Pickup Location</Menu.Item>
      <Menu.Item key="detail">Detail Pickup Location</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<ShopOutlined />}
        title="Pickup Location"
        subtitle="Manage Pickup Locations"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleCreateLocation}>
              Add Pickup Location
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size="large"
          placeholder="Search by Name"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size="large"
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeType}
          placeholder="Location-Type"
        >
          <Option value="all">All</Option>
          <Option value="main_kitchen">Main Kitchen</Option>
          <Option value="outlet">Outlet</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title="Update Pickup confirmation"
        visible={isModalVisible}
        onOk={handlePickUpChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialLocation);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.locationName}"</b> status to{" "}
          <b>"{!tmpData.isPickup ? "Yes" : "No"}"</b>?. {!tmpData.isPickup}
        </p>
      </Modal>
    </AppLayout>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default Locations;
