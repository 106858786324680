import { Space } from 'antd'
import React from 'react'
import HeaderSection from '../../../components/HeaderSection'
import AppLayout from '../../layout/AppLayout'
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton'
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type'
import AppCard from '../../../components/AppCard'
import DetailItem from '../../../components/DetailItem'
import SectionContent from '../../../components/SectionContent'
import { formatDate } from '../../../helpers/constant'
import { initialHowItWork, HowItWorkProps } from '../../../types/howItWork.type'
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs'

interface IParams {
  howitworksId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<HowItWorkProps, 'createdAt' | 'updatedAt'>
}

const HowItWorkDetail = () => {
  const {setBreadcrumbDetails} = useDetailBreadcrumbs()
  const history = useHistory()
  const { howitworksId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [howitworks, setHowItWork] = React.useState<HowItWorkProps>(initialHowItWork)

  const handleClickEdit = () => {
    history.push(`/how-it-work/${howitworks.howitworksId}/edit`)
  }

  React.useEffect(() => {
    const fetchDetailHowItWork = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          '/howitworks/' + howitworksId
        );

        setHowItWork(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: 'howitworksId',
            value: howitworksId,
            label: res.data.payload.headline,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchDetailHowItWork();
  }, [howitworksId]);

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title="How It Work Detail"
        subtitle="Manage your How It Work data"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle="How It Work Data">
          <DetailItem type="image" label="Image" content={howitworks.howitworksImg} />
          <DetailItem label="Headline" content={howitworks.headline} />
          <DetailItem
            label="Subtitle"
            content={howitworks.subtitle}
          />
          <DetailItem
            label="Created at"
            content={formatDate(howitworks.createdAt)}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
}

export default HowItWorkDetail