import React from 'react'
import {Image} from 'antd'
import styled from 'styled-components'

interface DetailItemProps {
  type?: 'image' | string;
  label: string
  content: string | any
}

const DetailItem: React.FC<DetailItemProps> = ({ type, label, content }) => {
  return (
    <ContentWrapper>
      <Label>{label}</Label>
      <Content>
        {type === 'image' ? (
          <Image
            preview={false}
            height={200}
            src={content || '/images/select-image.jpg'}
            fallback={'/images/blur-image.jpeg'}
            placeholder={
              <Image
                preview={false}
                src="/images/blur-image.jpeg"
                width={137}
                height={132}
              />
            }
          />
        ) : (
          content
        )}
      </Content>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  margin-bottom: 15px;
`

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.colors.charcoal300};
`

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.colors.black};
`

export default DetailItem