import { CustomerProps } from "./customer.type";

export enum EAmountType {
  "PERCENTAGE" = "PERCENTAGE",
  "NOMINAL" = "NOMINAL",
  "FORMULA" = "FORMULA",
}

export interface DiscountUserProperties {
  id: string;
  discountId: string;
  userId: string;
  createdAt?: Date;
  updatedAt?: Date;
  discounts?: DiscountProperties;
  customer?: CustomerProps;
}

export interface DiscountProperties {
  discountId: string;
  discountName: string;
  description?: string;
  amountType: EAmountType;
  amount: number;
  isPublished: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  discount_users?: DiscountUserProperties[];
  statusLoading?: boolean;
}

export const initialDiscount: DiscountProperties = {
  discountId: "",
  discountName: "",
  amountType: EAmountType.PERCENTAGE,
  amount: 0,
  isPublished: false,
};

export interface GeneralDiscountProperties {
  key: string;
  amount: string;
  createdAt: Date;
  updatedAt: Date;
}
