import { UserOutlined } from '@ant-design/icons'
import AppButton from '../../components/AppButton'
import HeaderSection from '../../components/HeaderSection'
import AppLayout from '../layout/AppLayout'
import { useHistory } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import SectionContent from '../../components/SectionContent';
import { Divider, Space, Image } from 'antd';
import DetailItem from '../../components/DetailItem';
import useAuth from '../../hooks/useAuth';
import { showRoleName } from '../../helpers/auth';
import { capitalizeFirstLetter } from '../../helpers/text';

const Profile = () => {
  const history = useHistory()

  const { user, isLoading } = useAuth()

  const handleClickEdit = () => {
    history.push('/profile/edit')
  }

  const handleClickChangePassword = () => {
    history.push('/change-password');
  };

  return (
    <AppLayout>
      <HeaderSection
        icon={<UserOutlined />}
        title="Profile"
        subtitle="Manage your profile"
        rightAction={
          <Space>
            <AppButton type="primary" onClick={handleClickChangePassword}>
              Change Password
            </AppButton>
            <AppButton type="primary" onClick={handleClickEdit}>
              Edit Profile
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle="Profil Data">
          <Image
            preview={false}
            height={200}
            width={200}
            src={user.imageUrl || '/images/blur-image.jpeg'}
            fallback={'/images/blur-image.jpeg'}
            style={{objectFit: 'cover'}}
            placeholder={
              <Image
                preview={false}
                src="/images/blur-image.jpeg"
                width={200}
                height={200}
              />
            }
          />
          <DetailItem label="Name" content={user.name} />
          <DetailItem label="Email" content={user.email} />
          <DetailItem label="Phone Number" content={user.phoneNumber} />
        </SectionContent>

        <Divider />

        <SectionContent groupTitle="Profil Settings">
          <DetailItem label="Role" content={showRoleName(user.role)} />
          <DetailItem
            label="Status"
            content={capitalizeFirstLetter(user.status)}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
}

export default Profile