import { Skeleton, Space } from 'antd'
import React from 'react'
import { FormGroup, FormGroupTitle, FormContent, FormGroupHelper } from './SectionContent'

export interface LoadingProps {
  type?: 'form' | 'detail' | undefined
}

const LoadingCard: React.FC<LoadingProps> = ({ type = 'form' }) => {

  if (type === 'detail') {
    return (
      <>
        {
          [...Array(3)].map((_, idx) => {
            return (
              <FormGroup key={idx}>
                <FormGroupTitle>
                  <Skeleton.Input style={{ height: 20 }} active={true} />
                </FormGroupTitle>
                <FormContent>
                  <Space direction="vertical" style={{ width: '100%' }} size="middle">
                    {
                      [...Array(4)].map((_, i) => <Skeleton.Input key={i} style={{ height: 20 }} active={true} />)
                    }
                  </Space>
                </FormContent>
              </FormGroup>
            )
          })
        }
      </>
    )
  }
  return (
    <>
      {
        [...Array(3)].map((_, idx) => {
          return (
            <FormGroup key={idx}>
              <FormGroupTitle>
                <Skeleton.Input style={{ height: 20 }} active={true} />
              </FormGroupTitle>
              <FormContent>
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  {
                    [...Array(4)].map((_, i) => <Skeleton.Input key={i} style={{ height: 20 }} active={true} />)
                  }
                </Space>
              </FormContent>
              <FormGroupHelper>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Skeleton.Input style={{ width: 200, height: 20 }} active={true} />
                  <Skeleton.Input style={{ height: 20 }} active={true} />
                  <Skeleton.Input style={{ width: 120, height: 20 }} active={true} />
                </Space>
              </FormGroupHelper>
            </FormGroup>
          )
        })
      }
    </>
  )
}

export default LoadingCard