import {
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  Select,
  Space,
} from "antd";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppCard from "../../components/AppCard";
import HeaderSection from "../../components/HeaderSection";
import { initialCustomer, CustomerProps } from "../../types/customer.type";
import AppLayout from "../layout/AppLayout";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import SectionContent from "../../components/SectionContent";
import { generateFormRules } from "../../helpers/formRules";
import FormUploadImage from "../../components/FormUploadImage";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import TextArea from "antd/lib/input/TextArea";
import { EAmountType } from "../../types/discount.type";

interface ILocation {
  customerId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CustomerProps, "createdAt" | "updatedAt">;
}

const CustomerEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { customerId } = useParams<ILocation>();
  const formRef =
    React.useRef<FormInstance<Omit<CustomerProps, "createdAt" | "updatedAt">>>(
      null
    );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [customer, setCustomer] =
    React.useState<CustomerProps>(initialCustomer);
  const [discount, setDiscount] = React.useState<any>({});

  const [image, setImage] = React.useState<File>();
  const [imageUrl, setImageUrl] = React.useState(customer.imageUrl);

  const createCustomer = async (
    props: Omit<CustomerProps, "createdAt" | "updatedAt" | "adminId">
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append("fullName", props.fullName);
      formData.append("email", props.email);
      formData.append("phoneNumber", props.phoneNumber);
      formData.append("role", props.role);
      formData.append("status", props.status ? "1" : "0");

      if (image) {
        formData.append("image", image);
      }

      await httpRequest.post("/customers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Success create " + props.fullName);

      history.push("/customer");
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateCustomer = async (
    props: Omit<CustomerProps, "createdAt" | "updatedAt">
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();
      formData.append("fullName", props.fullName);
      formData.append("email", props.email);
      formData.append("phoneNumber", props.phoneNumber);
      formData.append("role", props.role);
      formData.append("status", props.status ? "1" : "0");

      if (image) {
        formData.append("image", image);
      }

      await httpRequest.patch("/customers/" + customerId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Success update " + props.fullName + " data");
      history.push("/customer");
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<CustomerProps, "createdAt" | "updatedAt">
  ) => {
    if (customerId) {
      updateCustomer(values);
    } else {
      createCustomer(values);
    }
  };

  React.useEffect(() => {
    if (customerId) {
      const fetchCustomer = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            "/customers/" + customerId + "/detail"
          );
          setCustomer(res.data.payload);
          setImageUrl(res.data.payload.imageUrl);
          setIsLoading(false);

          setDiscount({
            discountId: res.data.payload.customerDiscounts![0].discountId,
            amount: res.data.payload.customerDiscounts![0].discounts?.amount,
            amountType:
              res.data.payload.customerDiscounts![0].discounts?.amountType,
            discountName:
              res.data.payload.customerDiscounts![0].discounts?.discountName,
            description:
              res.data.payload.customerDiscounts![0].discounts?.description,
            isPublished: res.data.payload.customerDiscounts![0].discounts
              ?.isPublished
              ? true
              : false,
          });

          const bcDetails = [
            {
              field: "customerId",
              value: customerId,
              label: res.data.payload.fullName,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchCustomer();
    }
  }, [customerId, location]);

  return (
    <AppLayout>
      <HeaderSection
        icon="back"
        title={(customerId ? "Edit" : "Add") + " Customer"}
        subtitle="Manage your customer data"
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={customerId ? customer : initialCustomer}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Profil Data"
            helpers={[
              {
                title: "",
                content: "",
              },
            ]}
          >
            <FormUploadImage
              imageUrl={imageUrl || ""}
              onChange={(file) => setImage(file)}
              onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
            />
            <Form.Item
              label="Name"
              name="fullName"
              rules={generateFormRules("Name", [
                "required",
                "letter-and-space",
              ])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={generateFormRules("Email", ["required", "email"])}
            >
              <Input disabled={!!customerId} />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phoneNumber"
              rules={generateFormRules("Phone number", [
                "required",
                "phoneNumber",
              ])}
            >
              <Input />
            </Form.Item>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Profile Setting"
            helpers={[
              {
                title: "Information",
                content: "Status Inactive makes the user unable to logging in",
              },
            ]}
          >
            {/* <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: 'The Role is required.',
                },
              ]}
            >
              <Select style={{ width: '100%' }}>
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="manager">Manager</Select.Option>
                <Select.Option value="chef">Chef</Select.Option>
                <Select.Option value="employee">Employee</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "The Status is required.",
                },
              ]}
            >
              <Radio.Group value={customer.status}>
                <Radio value={true}>Active</Radio>
                <Radio value={false}>Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default CustomerEdit;
