import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import { MoreOutlined, SearchOutlined, TagOutlined } from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import { TagProps } from "../../types/tag.type";
import { ContainerFilter } from "../admin";
import useFetchList from "../../hooks/useFetchList";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<TagProps, "createdAt" | "updatedAt">;
}

const Dietaries = () => {
  const history = useHistory();
  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<TagProps>({
    endpoint: "tags",
    initialQuery: {
      type: "allergen",
    },
  });

  const handleStatusChange = async (record: TagProps) => {
    try {
      let newData = [];
      newData = data.map((item) => {
        if (item.tagId === record.tagId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.patch<ResponseProps>(
        "/tags/" + record.tagId,
        {
          tagId: record.tagId,
          isPublished: !record.isPublished,
        }
      );

      newData = data.map((item) => {
        if (item.tagId === res.data.payload.tagId) {
          return {
            ...item,
            isPublished: res.data.payload.isPublished,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + record.name + " status.");
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleCreateUser = () => {
    history.push("/dietaries/add");
  };

  const handleClickDetail = (e: TagProps) => {
    history.push(`/dietaries/${e.tagId}`);
  };

  const { Option } = Select;

  const handleFilterStatusChange = (status: "active" | "inactive" | string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
    }
  };

  const handleClickAction = (e: TagProps, key: string) => {
    if (key === "edit") {
      history.push(`/dietaries/${e.tagId}/edit`);
    } else if (key === "detail") {
      history.push(`/dietaries/${e.tagId}`);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: TagProps) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "isPublished",
      dataIndex: "isPublished",
      render: (isPublished: boolean, record: TagProps) => {
        return (
          <>
            <Switch
              loading={record.statusLoading}
              checked={isPublished}
              onChange={() => {
                handleStatusChange(record);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: TagProps) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: TagProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key='edit'>Edit Dietaries</Menu.Item>
      <Menu.Item key='detail'>Detail Dietaries</Menu.Item>
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<TagOutlined />}
        title='Dietary'
        subtitle='Manage your allergens data'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleCreateUser}>
              Add Dietaries
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Dietaries name'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleFilterStatusChange}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </AppLayout>
  );
};

export default Dietaries;
