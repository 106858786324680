export const GOOGLE_MAP_API_KEY = "AIzaSyAeh1W3aArtTFdA0VBnegt-lOfWTntYgm8";

export const PROPS_MAPS = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `250px` }} />,
  mapElement: <div style={{ height: `100%` }} />,
};

export const MAP_DEFAULT_CENTER = {
  lat: -25.515575493284196,
  lng: 133.79259718218898,
};
