import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import AppVersion from '../../components/AppVersion'
import AppButton from '../../components/AppButton';
import AuthLayout from '../layout/AuthLayout'
import AuthHeaderCard from '../../components/AuthHeaderCard'
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const history = useHistory()
  const { isLoading, login, isLoggedIn } = useAuth()
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  React.useEffect(() => {
    if (!isLoading && isLoggedIn) {
      history.push('/dashboard')
    }
  }, [isLoggedIn, isLoading])

  return (
    <AuthLayout>
      <Card style={{ width: 400 }}>
        <AuthHeaderCard
          subtitle="Spoonful Kitchen CMS Sign In"
        />
        <Form
          layout="vertical"
          name="basic"
          initialValues={{
            // email: 'linda.arden@gmail.com',
            // password: 'secret'
            email: '',
            password: ''
          }}
          onFinish={login}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="Email address"
          >
            <Input size="large" type="email" prefix={<UserOutlined />} placeholder="Email address" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <AppButton loading={isLoading} size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
              Sign In
            </AppButton>
          </Form.Item>

          <Link style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }} to="/forgot-password">Forgot Password?</Link>
        </Form>
        <AppVersion />
      </Card>
    </AuthLayout>
  )
}

export default Login