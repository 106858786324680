import { Reducer } from 'redux';
import { ConfigState, ConfigTypes } from '../../types/config.type';

const initialState: ConfigState = {
  sidebarCollapsed: false,
  counterBatch: 0,
  activeSubMenu: ['']
}

export const configApp: Reducer<ConfigState> = (state = initialState, action) => {
  switch (action.type) {
    case ConfigTypes.SHOW_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: true,
        counterBatch: state.counterBatch + 1
      }
    case ConfigTypes.HIDE_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: false,
        counterBatch: state.counterBatch + 1
      }
    case ConfigTypes.SET_ACTIVE_SUB_MENU:
      return {
        ...state,
        activeSubMenu: action.payload
      }
  
    default:
      return state
  }
}