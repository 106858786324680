import { Divider, Space, Image } from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import { initialUser, IUser } from "../../types/user.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import AppButton from "../../components/AppButton";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import useAuth from "../../hooks/useAuth";

interface ILocation {
  userId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<IUser, "createdAt" | "updatedAt">;
}

const UserDetail = () => {
  const { user: currUser } = useAuth();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { userId } = useParams<ILocation>();
  const location = useLocation<ILocation>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser>(initialUser);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/admins/" + userId + "/detail"
        );

        setUser(res.data.payload);

        setIsLoading(false);

        const bcDetails = [
          {
            field: "userId",
            value: userId,
            label: res.data.payload.name,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [location]);

  const handleClickEdit = () => {
    history.push({
      pathname: `/user/${userId}/edit`,
    });
  };

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='User Detail'
        subtitle='Manage your user data'
        rightAction={
          user && currUser.adminId === user.adminId ? (
            <Space>
              <AppButton
                type='primary'
                onClick={() =>
                  history.push("/user/" + userId + "/change-password")
                }
              >
                Change Password
              </AppButton>
              <AppButton type='primary' onClick={handleClickEdit}>
                Edit
              </AppButton>
            </Space>
          ) : (
            false
          )
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle='Profil Data'>
          <Image
            preview={false}
            height={200}
            src={user.imageUrl || "/images/blur-image.jpeg"}
            fallback={"/images/blur-image.jpeg"}
            placeholder={
              <Image
                preview={false}
                src='/images/blur-image.jpeg'
                width={137}
                height={132}
              />
            }
          />
          <DetailItem label='Name' content={user.name} />
          <DetailItem label='Email' content={user.email} />
          <DetailItem label='Phone Number' content={user.phoneNumber} />
          <DetailItem label='Created at' content={formatDate(user.createdAt)} />
          <DetailItem label='Updated at' content={formatDate(user.updatedAt)} />
        </SectionContent>

        <Divider />

        <SectionContent groupTitle='Profil Settings'>
          <DetailItem label='Role' content={user.role} />
          <DetailItem label='Status' content={user.status} />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

export default UserDetail;
