/* eslint-disable valid-typeof */
import AppLayout from "../layout/AppLayout";
import { InboxOutlined } from "@ant-design/icons";
import {
  Select,
  Row,
  Col,
  DatePicker,
  DatePickerProps,
  Card,
  Divider,
  Button,
  Empty,
  Spin,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponsePaginationProps } from "../../types/config.type";
import {
  ETransactionStatus,
  TransactionProps,
} from "../../types/transaction.type";
import { ContainerFilter } from "../admin";
import useFetchList from "../../hooks/useFetchList";
import { useEffect, useState } from "react";
import { LocationProps } from "../../types/location.type";
import { ReactComponent as PackagingDoneLogo } from "../../assets/icons/packaging_done.svg";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
import { MenuProps } from "../../types/menu.type";

interface ResponseProps extends BaseResponsePaginationProps<LocationProps> {
  payload: {
    count: number;
    prev: string;
    next: string;
    results: Array<LocationProps>;
  };
}

const labelStyle = {
  width: "150px",
  color: "grey",
};

const Packaging = () => {
  const [locationRes, setLocationRes] = useState<LocationProps[]>([]);
  const [orderType, setOrderType] = useState<string>("");
  const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);
  const { token } = useAuth();
  const {
    isLoading,
    data,
    query,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    setIsLoading,
  } = useFetchList<TransactionProps>({
    endpoint: "transactions",
    initialQuery: {
      transactionStatus: [
        ETransactionStatus.PACKAGING,
        ETransactionStatus.PAID,
        ETransactionStatus.COD,
        ETransactionStatus.CREDITUR_ACCOUNT,
        ETransactionStatus.READY_FOR_SHIPMENT,
      ],
    },
  });

  console.log(data);
  useEffect(() => {
    async function getLocationData() {
      const res = await httpRequest.get<ResponseProps>("/locations");
      setLocationRes(res.data.payload.results);
    }
    getLocationData();
  }, []);

  // useEffect(() => {
  //   async function filterMealPack() {
  //     setIsLoadingDone(true);
  //     data.map((dataItem) => {
  //       dataItem.items.map(async (item) => {
  //         if (item.metaProduct.name.toLowerCase().includes("meal pack")) {
  //           const itemId = item.productId;
  //           const getMenu = await httpRequest.get<{ payload: MenuProps }>(
  //             "/menus/" + itemId,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           );
  //           const childItemIds = getMenu.data.payload.itemIds;
  //           const childItems: MenuProps[] = [];
  //           childItemIds?.map(async (child) => {
  //             const getMenu = await httpRequest.get<{ payload: MenuProps }>(
  //               "/menus/" + child,
  //               {
  //                 headers: {
  //                   Authorization: `Bearer ${token}`,
  //                 },
  //               }
  //             );
  //             childItems.push(getMenu.data.payload);
  //           });
  //           item.metaProduct = {
  //             ...item.metaProduct,
  //             menuItems: childItems,
  //           };
  //         }
  //       });
  //     });
  //     setIsLoadingDone(false);
  //   }
  //   filterMealPack();
  //   console.log(data);
  // }, [isLoading]);

  const handleDeliveryScheduleChange: DatePickerProps["onChange"] = (
    _,
    dateString
  ) => {
    setQuery((oldVal) => ({ ...oldVal, scheduledAt: dateString }));
  };

  const handleOrderTypeChange = (orderType: "PICKUP" | "DELIVERY" | string) => {
    if (orderType === "PICKUP") {
      setOrderType("PICKUP");
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: "PICKUP" }));
    } else if (orderType === "DELIVERY") {
      setOrderType("DELIVERY");
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: "DELIVERY" }));
    } else {
      setOrderType("ALL");
      setQuery((oldVal) => ({ ...oldVal, shipmentMethod: null }));
    }
  };

  const handlePickupLocationChange = (location: string) => {
    setQuery((oldVal) => ({ ...oldVal, locationId: location }));
    console.log("query : ", query);
  };

  const handleTransactionStatusChange = async (
    record: TransactionProps,
    newStatus: string
  ) => {
    setIsLoading(true);
    const res = await httpRequest.patch<ResponseProps>(
      "/transactions/" + record.transactionId + "/transaction-status",
      {
        transactionStatus: newStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setQuery((oldVal) => ({ ...oldVal }));
    setIsLoading(false);
  };

  const { Option } = Select;

  return (
    <CustomAppLayout>
      <HeaderSection
        icon={<InboxOutlined />}
        title='Packaging'
        subtitle='Excepteur sint occaecat cupidatat non proident'
      />

      <ContainerFilter>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={4}>
            <h4 style={labelStyle}>Order Type</h4>
            <Select
              size='middle'
              allowClear
              style={{ width: 130 }}
              onChange={handleOrderTypeChange}
              placeholder='Order Type'
            >
              <Option value='all'>All</Option>
              <Option value='DELIVERY'>Delivery</Option>
              <Option value='PICKUP'>Pickup</Option>
            </Select>
          </Col>
          <Col span={6}>
            <h4 style={labelStyle}>Delivery Schedule</h4>
            <DatePicker
              onChange={handleDeliveryScheduleChange}
              size='middle'
              allowClear
              style={{ width: 200 }}
              placeholder={new Date().toDateString()}
            />
          </Col>
          <Col span={5}>
            <h4 style={labelStyle}>Pickup Location</h4>
            <Select
              size='middle'
              allowClear
              style={{ width: 220 }}
              placeholder='Pickup Location'
              disabled={orderType === "DELIVERY" ? true : false}
              onChange={handlePickupLocationChange}
            >
              <Option value='all'>All</Option>
              {locationRes.map((item) => {
                return (
                  <Option value={item.locationId}>
                    {`${item.locationAddress}, ${item.locationName}`}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={4} offset={2}>
            <h4 style={labelStyle}>Total need to packed:</h4>
            <h4 style={labelStyle}>
              <span style={{ color: "#39AC6E" }}>
                {
                  data.filter(
                    (item) =>
                      item.transactionStatus ===
                      ETransactionStatus.READY_FOR_SHIPMENT
                  ).length
                }
              </span>
              {`/${data.length}`}
            </h4>
          </Col>
        </Row>
      </ContainerFilter>
      {isLoading ? (
        <Spin style={{ margin: "30vh 35vw" }} />
      ) : data.length > 0 ? (
        <div
          className='site-card-wrapper'
          style={{ backgroundColor: "#556575", padding: "1rem" }}
        >
          <Row gutter={[16, 16]}>
            {data.map((item) => {
              return (
                <Col span={8}>
                  <Card
                    style={{ height: "100%" }}
                    loading={isLoadingDone}
                    title={
                      <div>
                        <h6>Order ID: {item.transactionCode}</h6>
                        <Row gutter={[0, 20]}>
                          <Col span={6}>
                            <h4 style={{ fontWeight: "bold" }}>
                              {item.metaBuyer?.fullName
                                ? item.metaBuyer?.fullName
                                : "Linda Arden"}
                            </h4>
                          </Col>
                          <Col span={6} offset={10}>
                            <h5
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {item.shipment.method}
                            </h5>
                          </Col>
                        </Row>
                        <h6>
                          {item.shipment.meta?.pickupFromLocationAddress
                            ? item.shipment.meta?.pickupFromLocationAddress
                            : item.shipment.meta?.deliveryAddress}
                        </h6>
                      </div>
                    }
                    bordered={false}
                    headStyle={{
                      backgroundColor: "#F1F3F5",
                      padding: "0 0 0 12px",
                    }}
                  >
                    {item.items.map((dataItem) => {
                      return (
                        <>
                          {!dataItem.metaProduct.name
                            .toLowerCase()
                            .includes("meal pack") ? (
                            <>
                              <Row gutter={[0, 0]}>
                                <Col span={4}>
                                  <h4>{dataItem.quantity}</h4>
                                </Col>
                                <Col span={14}>
                                  <h4>{dataItem.metaProduct.name}</h4>
                                </Col>
                                <Col span={4}>
                                  <h4 style={{ textAlign: "left" }}>
                                    {dataItem.metaProduct.variant?.label}
                                  </h4>
                                </Col>
                              </Row>
                              <Divider style={{ margin: "0 0 .5rem 0" }} />
                            </>
                          ) : (
                            <>
                              <div>
                                {/* Not Meal Pack:{" "}
                                {(dataItem.metaProduct.menuItems || []).length} */}
                              </div>
                              {dataItem.metaProduct.menuItems
                                ? dataItem.metaProduct.menuItems.map(
                                    (childItem: any) => {
                                      return (
                                        <>
                                          <Row gutter={[0, 0]}>
                                            <Col span={4}>
                                              <h4>{childItem.qty}</h4>
                                            </Col>
                                            <Col span={14}>
                                              <h4>{childItem.menuName}</h4>
                                            </Col>
                                            <Col span={4}>
                                              <h4 style={{ textAlign: "left" }}>
                                                {dataItem.metaProduct.name}
                                              </h4>
                                            </Col>
                                          </Row>
                                          <Divider
                                            style={{ margin: "0 0 .5rem 0" }}
                                          />
                                        </>
                                      );
                                    }
                                  )
                                : false}
                            </>
                          )}
                        </>
                      );
                    })}

                    <Button
                      block
                      style={{
                        backgroundColor:
                          item.transactionStatus ===
                          ETransactionStatus.READY_FOR_SHIPMENT
                            ? "#39AC6E"
                            : "#FF4A50",
                        fontSize: "14px",
                        color: "white",
                        cursor:
                          item.transactionStatus ===
                          ETransactionStatus.READY_FOR_SHIPMENT
                            ? "default"
                            : "pointer",
                      }}
                      disabled={
                        item.transactionStatus ===
                        ETransactionStatus.READY_FOR_SHIPMENT
                          ? true
                          : false
                      }
                      icon={
                        item.transactionStatus ===
                        ETransactionStatus.READY_FOR_SHIPMENT ? (
                          <PackagingDoneLogo
                            width={25}
                            height={15}
                            style={{ position: "relative", top: ".2rem" }}
                          />
                        ) : (
                          false
                        )
                      }
                      onClick={async () => {
                        handleTransactionStatusChange(
                          item,
                          ETransactionStatus.READY_FOR_SHIPMENT
                        );
                      }}
                    >
                      {item.transactionStatus ===
                      ETransactionStatus.READY_FOR_SHIPMENT
                        ? "Packaging Done"
                        : "Food Ready"}
                    </Button>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <CustomEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </CustomAppLayout>
  );
};

export default Packaging;

const CustomEmpty = styled(Empty)`
  margin: 25vh 0;
`;

const CustomAppLayout = styled(AppLayout)``;
