import {
  CloseOutlined,
  DeleteOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Input, Row, Space, Col, Divider, Image } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppCard from "../../components/AppCard";
import HeaderSection from "../../components/HeaderSection";
import { initialMenu, MenuProps, VariantProps } from "../../types/menu.type";
import AppLayout from "../layout/AppLayout";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import SectionContent from "../../components/SectionContent";
import styled from "styled-components";
import DetailItem from "../../components/DetailItem";
import HTMLPreview from "../../components/HTMLPreview";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { TagProps } from "../../types/tag.type";
import { ECategoryType } from "../../types/category.type";

interface IParams {
  menuId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<MenuProps, "createdAt" | "updatedAt">;
}

const MenuDetail: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { menuId } = useParams<IParams>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
    url: "",
    uid: "",
    name: "",
  });
  const [images, setImages] = React.useState<
    UploadChangeParam<UploadFile<any>>["fileList"]
  >([]);
  const [menu, setMenu] = React.useState<MenuProps>(initialMenu);

  React.useEffect(() => {
    const fetchMenuDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>("/menus/" + menuId);
        setMenu(res.data.payload);

        const dataImages = (res.data.payload.images || []).map((item) => ({
          url: item.imageUrl || "",
          uid: item.fileId,
          name: item.imageUrl || "",
        }));

        setImages(dataImages as any[]);

        if (dataImages.length > 0) {
          setSelectedImage(dataImages[0]);
        }

        setIsLoading(false);

        const bcDetails = [
          {
            field: "name",
            value: res.data.payload.name,
            label: res.data.payload.name,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchMenuDetail();
  }, [menuId]);

  const handleChangeImages = (e: UploadChangeParam<UploadFile<any>>) => {
    setImages(e.fileList);

    e.fileList.forEach((file) => {
      if (file.originFileObj) {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = function (event) {
          if (event.target && event.target.result) {
            setImages(
              e.fileList.map((item) => {
                if (item.uid === file.uid) {
                  item = {
                    ...item,
                    url:
                      event && event.target && event.target.result
                        ? (event.target.result as any)
                        : undefined,
                  };
                }
                return item;
              })
            );
          }
        };
      }
    });
  };

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Menu Detail'
        subtitle='Manage your menu data'
        rightAction={
          <Space>
            <AppButton
              type='primary'
              onClick={() =>
                history.push(
                  "/menu/" +
                    (menu.category?.categoryType === ECategoryType.MEALPACK
                      ? "meal-pack"
                      : "meal") +
                    "/" +
                    menuId +
                    "/edit"
                )
              }
            >
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent
          groupTitle='Menu Information'
          helpers={[
            {
              title: "Information",
              content:
                "Image either jpg, png or jpeg; max size 1MB; recommended resolution is 500x300px; Best Seller will make the Menu shows in Home page",
            },
          ]}
        >
          <Space
            style={{
              width: "100%",
              background: "grey",
              justifyContent: "center",
            }}
          >
            <Image
              preview={false}
              width='100%'
              height={200}
              src={selectedImage.url || "/images/select-image.jpg"}
              fallback={"/images/blur-image.jpeg"}
              style={{ objectFit: "cover" }}
              placeholder={
                <Image
                  preview={false}
                  src='/images/blur-image.jpeg'
                  width='100%'
                  height={200}
                  style={{ objectFit: "cover" }}
                />
              }
            />
          </Space>
          <Space direction='horizontal'>
            {images.map((img) => (
              <div
                style={
                  selectedImage.uid === img.uid
                    ? {
                        border: 5,
                        borderStyle: "solid",
                        borderColor: "#FF4A50",
                        position: "relative",
                      }
                    : { position: "relative" }
                }
              >
                <Image
                  onClick={() => {
                    setSelectedImage(img);
                  }}
                  preview={false}
                  width={100}
                  height={100}
                  src={img.url || img.thumbUrl || "/images/select-image.jpg"}
                  fallback={"/images/blur-image.jpeg"}
                  style={{ objectFit: "cover" }}
                  placeholder={
                    <Image
                      preview={false}
                      src='/images/blur-image.jpeg'
                      width={100}
                      height={100}
                      style={{ objectFit: "cover" }}
                    />
                  }
                />
              </div>
            ))}
          </Space>

          <DetailItem label='Name' content={menu.name} />

          <DetailItem
            label='Description'
            content={<HTMLPreview html={menu.description} />}
          />

          {menu.itemIds && menu.itemIds.length > 0 ? (
            <>
              <DetailItem label='Price' content={`$${menu.price}`} />
              <DetailItem label='Max Order Qty.' content={menu.maxQty} />
            </>
          ) : (
            <DetailItem
              label='Menu Variants'
              content={menu.variants.map((item) => (
                <>
                  <Row>
                    <Col span={8}>{item.label}</Col>
                    <Col span={16}>${item.price}</Col>
                  </Row>
                  <Divider />
                </>
              ))}
            />
          )}

          <DetailItem
            label='Category'
            content={
              <span>
                {menu.category?.categoryType === "MEALPACK" && (
                  <TableOutlined
                    style={{ marginRight: 10, color: "#FF4A50" }}
                  />
                )}{" "}
                {menu.category?.categoryName}
              </span>
            }
          />

          <DetailItem
            label='Dietaries'
            content={
              menu.allergens && menu.allergens.length > 0
                ? sortTags(menu.allergens)
                    .map((item) => item.name)
                    .join(", ")
                : "Not Set"
            }
          />

          <DetailItem
            label='Status'
            content={menu.isPublished ? "Published" : "Unpublished"}
          />

          <DetailItem
            label='Is Best Seller?'
            content={menu.isTopPick ? "Yes" : "No"}
          />
        </SectionContent>
      </AppCard>
    </AppLayout>
  );
};

interface VariantInputProps {
  key: number;
  data: VariantProps;
  dataLength: number;
}

const VariantInput: React.FC<VariantInputProps> = ({ data, dataLength }) => {
  return (
    <VariantInputContainer>
      <Input prefix='$' value={data.price} type='number' />
      <Input disabled={dataLength === 1} value={data.label} />
      <AppButton
        disabled={dataLength === 1}
        type='primary'
        icon={<DeleteOutlined />}
      />
      <AppButton disabled={data.isDefaultPrice} type='primary'>
        Set default
      </AppButton>
    </VariantInputContainer>
  );
};

interface MenuVariantInputProps {
  variants: Array<VariantProps>;
}

const VariantInputContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 32px 120px;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export default MenuDetail;

function sortTags(tags: TagProps[]) {
  tags.sort((a, b) => a.name.localeCompare(b.name));
  return tags;
}
