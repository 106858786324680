import { Form, FormInstance, Input, message, Space } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import AppLayout from '../../layout/AppLayout';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import {
  AppConfigProps,
  FetchAllAppConfigResponse,
  FetchOneAppConfigResponse,
  initialAppConfig,
} from '../../../types/appConfig.type';
import Text from 'antd/lib/typography/Text';

interface IParams {
  appConfigId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<AppConfigProps, 'createdAt' | 'updatedAt'>;
}

const AppConfigsEdit: React.FC = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { appConfigId } = useParams<IParams>();
  const formRef =
    React.useRef<FormInstance<Omit<AppConfigProps, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const [isAllowed, setIsAllowed] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [appconfig, setAppConfig] =
    React.useState<AppConfigProps>(initialAppConfig);

  const createAppConfig = async (
    props: Omit<
      AppConfigProps,
      'createdAt' | 'updatedAt' | 'appConfigId' | 'statusLoading'
    >
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = new FormData();

      console.info('props', props);
      console.info('Object.keys(props)', Object.keys(props));
      Object.keys(props).forEach((key) => {
        if ((props as any)[key]) {
          formData.append(key, (props as any)[key]);
        }
      });

      // console.info('formData', JSON.stringify(formData))
      await httpRequest.post('/appconfigs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Success create ');

      history.push('/app-config');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateAppConfig = async (
    props: Omit<AppConfigProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    try {
      setIsLoadingAction(true);

      let formDatas = new FormData();
      Object.keys(props).forEach((key) => {
        console.log('====');
        console.log(key);
        console.log((props as any)[key]);
        if (key == 'value' && (props as any)[key]) {
          formDatas.append('value', (props as any)[key]);
          console.log(formDatas);
        }
      });

      console.log(formDatas);

      await httpRequest.patch('/app-configs/' + appconfig.key, {
        value: props.value,
      });
      message.success('Success update ');
      history.push('/app-config');
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<AppConfigProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    console.info('values', values);
    console.info(appConfigId);
    if (appConfigId) {
      updateAppConfig(values);
    } else {
      createAppConfig(values);
    }
  };

  React.useEffect(() => {
    const fetchHowItWork = async () => {
      try {
        setIsLoading(true);
          const res = await httpRequest.get<FetchOneAppConfigResponse>('/app-configs/id/' + appConfigId)
          if(parseInt(res.data.payload.value) < 0){
            setIsAllowed(false)
          } else {
            setIsAllowed(true)
          }
          setAppConfig(res.data.payload)
          setIsLoading(false)

          const bcDetails = [
            {
              field: 'appConfigId',
              value: appConfigId,
              label: res.data.payload.key,
            },
          ];
          setBreadcrumbDetails(bcDetails);

        } catch (error) {
          setIsLoading(false)
        }
    };
    fetchHowItWork();
  }, []);

  const checkAllowed = (e: any) => {
    if(parseInt(e) < 0){
      setIsAllowed(false)
    } else {
      setIsAllowed(true)
    }
  }

  // React.useEffect(()=>{
  // },[appconfig])

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title={(appConfigId ? 'Edit' : 'Add') + ' App Config'}
        subtitle='Manage your App Config data'
        rightAction={
          <Space>
            <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
            <AppButton
              loading={isLoadingAction}
              type="primary"
              disabled={isAllowed ? false: true}
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <Form
          ref={formRef}
          name='appconfigsForm'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={appConfigId ? appconfig : initialAppConfig}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Data'
            helpers={[
              {
                title: 'Information',
                content:
                  'The position of App Configs can be changed on the App Config list by dragging the row',
              },
            ]}
          >
            <Form.Item label='Key' name='key'>
              <Input disabled={true} value={appconfig.key} />
            </Form.Item>
            <Form.Item
              label='Value'
              name='value'
              rules={[
                {
                  required: true,
                  message: 'Value is required.',
                  min: 0,
                },
              ]}
            >
              <Input
                  value={appconfig.value}
                  onChange={(e) => checkAllowed(e.target.value)}
                  type='number'
                  step='0.1'
                  min={0}
                />
            </Form.Item>
            <Text type='danger' style={{display: isAllowed ? "none": "block"}}>Minimum value is 0</Text>
          </SectionContent>
        </Form>
      </AppCard>
    </AppLayout>
  );
};

export default AppConfigsEdit;
