import React from "react";
import AppLayout from "../layout/AppLayout";
import { useHistory } from "react-router-dom";
import { MoreOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Tag,
  Switch,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
  message,
  Input,
  Select,
} from "antd";
import AppButton from "../../components/AppButton";
import HeaderSection from "../../components/HeaderSection";
import { IUser, initialUser } from "../../types/user.type";
import { httpRequest } from "../../helpers/api";
import { formatDate } from "../../helpers/constant";
import { BaseResponseProps } from "../../types/config.type";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
import useFetchList from "../../hooks/useFetchList";
import { ROLES } from "../../helpers/auth";

interface ResponseProps extends BaseResponseProps {
  payload: Omit<IUser, "createdAt" | "updatedAt">;
}

const User = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<IUser>(initialUser);
  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<IUser>({
    endpoint: "admins",
  });

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdate(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.adminId === tmpData.adminId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });

      const newStatus = tmpData.status === "active" ? "inactive" : "active";

      const res = await httpRequest.patch<ResponseProps>(
        "/admins/" + tmpData.adminId,
        {
          adminId: tmpData.adminId,
          status: newStatus,
        }
      );

      newData = data.map((item) => {
        if (item.adminId === res.data.payload.adminId) {
          return {
            ...item,
            status: res.data.payload.status,
            statusLoading: false,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.name + " status.");
      setIsModalVisible(false);
      setTmpData(initialUser);
      setIsLoadingUpdate(false);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialUser);
      setIsLoadingUpdate(false);
    }
  };

  const handleCreateUser = () => {
    history.push({ pathname: "/user/add" });
  };

  const handleClickDetail = (e: IUser) => {
    history.push(`/user/${e.adminId}`);
  };

  const { Option } = Select;

  const handleChangeStatus = (status: string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, status: "" }));
    }
  };

  const handleChangeRole = (role: string) => {
    if (role !== "all") {
      setQuery((oldVal) => ({ ...oldVal, role }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, role: "" }));
    }
  };

  const handleClick = (key: string, record: IUser) => {
    if (key === "edit") {
      history.push(`/user/${record.adminId}/edit`);
    } else if (key === "change-password") {
      history.push(`/user/${record.adminId}/change-password`);
    } else if (key === "detail") {
      history.push(`/user/${record.adminId}`);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: IUser) => {
        return (
          <div className='table-link' onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      render: (role: any) => <Tag color={"volcano"}>{ROLES[role]}</Tag>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: any, record: IUser) => (
        <>
          {record.adminId === user.adminId ? (
            <Tooltip title="Can't change status while login">
              <Switch checked={status === "active"} disabled />
            </Tooltip>
          ) : (
            <Switch
              loading={false}
              checked={status === "active"}
              disabled={record.adminId === user.adminId}
              onChange={() => {
                setIsModalVisible(true);
                setTmpData(record);
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IUser) => (
        <Dropdown overlay={() => menu(record)} placement='bottomRight'>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: IUser) => (
    <Menu onClick={(e) => handleClick(e.key, record)}>
      {user.adminId === record.adminId && (
        <Menu.Item key='edit'>Edit User</Menu.Item>
      )}
      <Menu.Item key='detail'>Detail User</Menu.Item>
      {user.adminId === record.adminId && (
        <Menu.Item key='change-password'>Change Password</Menu.Item>
      )}
    </Menu>
  );

  return (
    <AppLayout>
      <HeaderSection
        icon={<UserOutlined />}
        title='User'
        subtitle='Manage your users'
        rightAction={
          <Space>
            <AppButton type='primary' onClick={handleCreateUser}>
              Add User
            </AppButton>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          size='large'
          placeholder='Search by Name, Phone number or Email'
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder='Status'
        >
          <Option value='all'>All</Option>
          <Option value='active'>Active</Option>
          <Option value='inactive'>Inactive</Option>
        </Select>
        <Select
          size='large'
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeRole}
          placeholder='Role'
        >
          <Option value='all'>All</Option>
          {Object.keys(ROLES).map((roleKey) => (
            <Option key={roleKey} value={roleKey}>
              {ROLES[roleKey]}
            </Option>
          ))}
        </Select>
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={["25", "50", "100"]}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      <Modal
        title='Update status confirmation'
        visible={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialUser);
        }}
        okText='Yes'
        confirmLoading={isLoadingUpdate}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.name}"</b> status to{" "}
          <b>"{tmpData.status === "active" ? "Inactive" : "Active"}"</b>?.{" "}
          {tmpData.status === "active"
            ? 'If this user status is changed to "Inactive", then this user cannot login to the Spoonful Kitchen CMS'
            : 'If this user status is changed to "Active", then this user can Sign In to Spoonful Kitchen'}
        </p>
      </Modal>
    </AppLayout>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default User;
