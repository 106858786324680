import {
  Divider,
  Space,
  Image,
  Row,
  Col,
  Modal,
  Form,
  Radio,
  Input,
  Spin,
} from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import AppLayout from "../layout/AppLayout";
import { useParams } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import AppCard from "../../components/AppCard";
import DetailItem from "../../components/DetailItem";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import { CustomerProps, initialCustomer } from "../../types/customer.type";
import AppButton from "../../components/AppButton";
import { useHistory } from "react-router-dom";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import {
  DiscountProperties,
  DiscountUserProperties,
  EAmountType,
} from "../../types/discount.type";
import TextArea from "antd/lib/input/TextArea";

interface ILocation {
  customerId: string;
}

interface ResponseProps extends BaseResponseProps {
  payload: Omit<CustomerProps, "createdAt" | "updatedAt">;
}

const CustomerDetail = () => {
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const history = useHistory();
  const { customerId } = useParams<ILocation>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [customer, setCustomer] = React.useState<CustomerProps>({
    ...initialCustomer,
  });
  const [discount, setDiscount] = React.useState<any>({});

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/customers/" + customerId + "/detail"
        );

        setCustomer(res.data.payload);

        setDiscount({
          discountId: res.data.payload.customerDiscounts![0].discountId,
          amount: res.data.payload.customerDiscounts![0].discounts?.amount,
          amountType:
            res.data.payload.customerDiscounts![0].discounts?.amountType,
          discountName:
            res.data.payload.customerDiscounts![0].discounts?.discountName,
          description:
            res.data.payload.customerDiscounts![0].discounts?.description,
          isPublished: res.data.payload.customerDiscounts![0].discounts
            ?.isPublished
            ? true
            : false,
        });

        setIsLoading(false);

        const bcDetails = [
          {
            field: "customerId",
            value: customerId,
            label: res.data.payload.fullName,
          },
        ];
        setBreadcrumbDetails(bcDetails);
      } catch (error: any) {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [customerId]);

  const handleClickEdit = () => {
    history.push(`/customer/${customerId}/edit`);
  };

  const showModalEditDiscount = () => {
    setIsModalOpen(true);
  };

  const handleModalEditDiscountSave = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.patch<{ payload: DiscountProperties }>(
        "/discounts/" + discount.discountId,
        {
          amount: discount.amount,
          amountType: discount.amountType,
          discountName: discount.discountName,
          description: discount.description,
          isPublished: discount.isPublished,
        }
      );
      setDiscount({
        discountId: res.data.payload.discountId,
        amount: res.data.payload.amount,
        amountType: res.data.payload.amountType,
        discountName: res.data.payload.discountName,
        description: res.data.payload.description,
        isPublished: res.data.payload.isPublished,
      });

      const customer = await httpRequest.get<ResponseProps>(
        "/customers/" + customerId + "/detail"
      );

      setCustomer(customer.data.payload);
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleModalEditDiscountCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <AppLayout>
      <HeaderSection
        icon='back'
        title='Customer Detail'
        subtitle='Manage your customer data'
        rightAction={
          <Space>
            <AppButton
              type='primary'
              onClick={() =>
                history.push("/customer/" + customerId + "/change-password")
              }
            >
              Change Password
            </AppButton>
            <AppButton type='primary' onClick={handleClickEdit}>
              Edit
            </AppButton>
          </Space>
        }
      />
      <AppCard loading={isLoading}>
        <SectionContent groupTitle='Profil Data'>
          <Image
            preview={false}
            width={200}
            height={200}
            src={customer.imageUrl || "/images/blur-image.jpeg"}
            fallback={"/images/blur-image.jpeg"}
            placeholder={
              <Image
                preview={false}
                src='/images/blur-image.jpeg'
                width={137}
                height={132}
              />
            }
          />
          <DetailItem label='Name' content={customer.fullName} />
          <DetailItem label='Email' content={customer.email} />
          <DetailItem label='Phone Number' content={customer.phoneNumber} />
          <DetailItem
            label='Created at'
            content={formatDate(customer.createdAt)}
          />
          <DetailItem
            label='Updated at'
            content={formatDate(customer.updatedAt)}
          />
        </SectionContent>

        {customer.customerDiscounts && customer.customerDiscounts.length > 0 ? (
          <>
            <Divider />

            <SectionContent
              groupTitle='Discount Settings'
              // helpers={[
              //   {
              //     title: "Information",
              //     content:
              //       "You can manage this customer membership discount here",
              //   },
              // ]}
            >
              <Row justify='space-between'>
                <Col>
                  <DetailItem
                    label='Discount Name'
                    content={
                      customer!.customerDiscounts![0].discounts?.discountName
                    }
                  />
                  <DetailItem
                    label='Discount Description'
                    content={
                      customer!.customerDiscounts![0].discounts?.description
                    }
                  />
                  <DetailItem
                    label='Discount Type'
                    content={
                      customer!.customerDiscounts![0].discounts?.amountType
                    }
                  />
                  <DetailItem
                    label='Discount Amount'
                    content={customer!.customerDiscounts![0].discounts?.amount}
                  />
                  <DetailItem
                    label='isPublished'
                    content={
                      customer!.customerDiscounts![0].discounts?.isPublished
                        ? "Published"
                        : "UnPublished"
                    }
                  />
                </Col>
                <Col>
                  <AppButton type='primary' onClick={showModalEditDiscount}>
                    Edit Discount
                  </AppButton>
                </Col>
              </Row>
            </SectionContent>
          </>
        ) : (
          false
        )}

        <Divider />

        <SectionContent groupTitle='Profil Settings'>
          <DetailItem
            label='Status'
            content={customer.status ? "Active" : "Inactive"}
          />
        </SectionContent>
      </AppCard>

      <Modal
        title='Edit Membership Discount'
        visible={isModalOpen}
        onOk={handleModalEditDiscountSave}
        onCancel={handleModalEditDiscountCancel}
        closable={false}
        centered
      >
        {isLoading ? (
          <Spin />
        ) : (
          <Form
            name='profileForm'
            layout='vertical'
            initialValues={customerId ? discount : {}}
            autoComplete='off'
          >
            <Form.Item
              label='Discount Name'
              name='discountName'
              rules={[
                {
                  required: true,
                  message: "The discount name is required.",
                },
              ]}
            >
              <Input
                defaultValue={discount.discountName}
                value={discount.discountName}
                onChange={(e) => {
                  setDiscount({
                    ...discount,
                    discountName: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label='Discount Description'
              name='description'
              rules={[
                {
                  required: true,
                  message: "The description is required.",
                },
              ]}
            >
              <TextArea
                rows={4}
                defaultValue={discount.description}
                value={discount.description}
                onChange={(e) => {
                  setDiscount({
                    ...discount,
                    description: e.target.value,
                  });
                }}
                placeholder={"describe description here"}
              />
            </Form.Item>
            <Form.Item
              label='Amount Type'
              name='amountType'
              rules={[
                {
                  required: true,
                  message: "The amount type is required.",
                },
              ]}
            >
              <select
                name='amountType'
                id='amountType'
                value={discount.amountType}
                style={{
                  width: "15.625rem",
                  padding: ".5rem 0",
                  borderColor: "grey",
                  borderRadius: "5px",
                }}
                onChange={function (selectObject) {
                  const value = selectObject.target.value;
                  setDiscount({
                    ...discount,
                    amountType: value,
                  });
                }}
              >
                <option value={EAmountType.NOMINAL}>Nominal</option>
                <option value={EAmountType.PERCENTAGE}>Percentage</option>
              </select>
            </Form.Item>
            <Form.Item
              label='Discount Amount'
              name='amount'
              rules={[
                {
                  required: true,
                  message: "The discount amount is required.",
                },
              ]}
            >
              {discount.amountType === EAmountType.PERCENTAGE ? (
                <Input
                  suffix={"%"}
                  defaultValue={discount.amount}
                  value={discount.amount}
                  type={"number"}
                  onChange={(e) => {
                    setDiscount({
                      ...discount,
                      amount: e.target.value,
                    });
                  }}
                  style={{
                    width: "15.625rem",
                    padding: ".5rem auto",
                    borderRadius: "5px",
                  }}
                  placeholder='5'
                />
              ) : (
                <Input
                  prefix={"$"}
                  defaultValue={discount.amount}
                  value={discount.amount}
                  type={"number"}
                  onChange={(e) => {
                    setDiscount({
                      ...discount,
                      amount: e.target.value,
                    });
                  }}
                  style={{
                    width: "15.625rem",
                    padding: ".5rem auto",
                    borderRadius: "5px",
                  }}
                  placeholder='15'
                />
              )}
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: "The Status is required.",
                },
              ]}
            >
              <Radio.Group
                value={discount.isPublished}
                defaultValue={discount.isPublished}
              >
                <Radio value={true}>Published</Radio>
                <Radio value={false}>UnPublished</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </AppLayout>
  );
};

export default CustomerDetail;
